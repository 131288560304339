<template>
  <div>
    <el-dialog :before-close="beforeClose" @close="close" custom-class="create-temp-dialog" title="提示"
      :visible.sync="tempVisible" width="1282px" top="10vh">
      <div class="temp-dialog-main">
        <div class="temp-dialog-main__left">
          <div class="nullcourse" :class="{ current: currentTemp == -1 }" @click="tabTempNav({}, -1)">
            空白课程
          </div>
          <div class="margin_left32 padding_top24 fontsize_12 color_8C">
            模板
          </div>
          <ul class="menu-ul">
            <li class="menu-li pover" :class="{ current: currentTemp === index }" v-for="(item, index) in category_list"
              :key="index" @click="tabTempNav(item, index)">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div
          class="temp-dialog-main__right prel">
          
          <div class="temp-conent" v-if="currentTemp == -1">
            <div class="header-title fontsize_16 color_26">课程名称</div>
            <el-input class="header-input" placeholder="新建课程" v-model="title">
            </el-input>
            <div class="header-title2 fontsize_16 color_26 margin_top16">
              选择主题
            </div>
            <el-scrollbar :wrapStyle="[{'overflow-x':'hidden'}]" class="theme-ul-scroll">
              <ul class="theme-ul">
                <li class="theme-li" :class="{ 'theme-li__select': item.is_default==1}" v-for="(item, index) in themes"
                  :key="item.id" @click="tabThemeItem(item, index)">
                  <div class="theme-li__top">
                    <el-image :src="item.cover" class="theme-li__top-img" fit="cover"></el-image>
                    <span class="theme-li__top-corner" v-if="item.version==1">{{item.version}}</span>
                    <img src="@/assets/theme_hg.png" class="theme-li__top-hg" v-if="item.is_vip==1">
                  </div>
                  <div class="theme-li__bottom flex flex_jcbetween flex_acenter">
                    <div class="theme-li__bottom-title pover">
                      {{ item.name }}
                    </div>
                    <div class="theme-li__bottom-btn" @click.stop="previewTheme(item, index)">
                      预览
                    </div>
                  </div>
                </li>
              </ul>
            </el-scrollbar>
           
          </div>
          <div class="temp-conent flex1" v-if="currentTemp != -1">
            <!-- <el-scrollbar :wrapStyle="[{'overflow-y':'visible'}]" class="temp-navbar">
              <ul class="temp-navbar-ul">
                <li class="temp-navbar-li" :class="{ current: index == currentTempNav }"
                  v-for="(item, index) in category_sub_list" :key="index" @click="tabTempNavSub(item, index)">
                  {{ item.title }}
                </li>
              </ul>
            </el-scrollbar> -->
           <div class="temp-navbar" >
            <ul class="temp-navbar-ul" ref="tempnavberRef" :class="{'flexwrap':showmore}">
              <li class="temp-navbar-li" :class="{ current: index == currentTempNav }"
                v-for="(item, index) in category_sub_list" :key="index" @click="tabTempNavSub(item, index)">
                {{ item.title }}
              </li>
            </ul>
            <span class="more" v-show="showmorebtn" @click="handleMore">{{showmore?'收起':'更多'}} <i class="fontsize_12" :class="{'icon-shanglajiantou':showmore,'icon-xialajiantou':!showmore}"></i></span>
           </div>
            <el-scrollbar :wrapStyle="[{'overflow-x':'hidden'}]" class="temp-list-scroll">
              <!-- v-infinite-scroll="loadData"
              infinite-scroll-distance="100" -->
              <div class="temp-list-container flex flexwrap flex_contentstart" >
                <div class="tempbox-wrapper bgc_fff pointer" v-for="(item, ind) in tempLists" :key="item.id + '_0'">
                  <div class="tempbox-image prel">
                    <div class="tempbox-image-box">
                      <el-image :src="item.cover" class="tempbox-image-img"
                        fit="cover"></el-image>
                      <div class="tempbox-mark-corner" v-if="item.version">{{item.version}}</div>
                      <img src="@/assets/theme_hg.png" class="coursebox-hg" v-if="item.is_vip==1">
                    </div>
                    <div class="tempbox-mask">
                      <div class="tempbox-mask-tools pointer">
                        <span class="fontsize_14 color_fff" @click="useTemp(item, ind)">使用</span>
                      </div>
                    </div>
                  </div>
                  <div class="tempbox-title fontsize_14 color_26 pover"
                    v-html="item.title||'-'"></div>
                  <div class="tempbox-tools fontsize_12">
                    <div class="tempbox-tools-btn" @click.stop="previewTemp(item, ind)">
                      预览
                    </div>
                  </div>
                </div>
                <div class="nodata" v-if="total == 0">
                  <img src="@/assets/nodata/temp_nodata.png" alt="" class="statu_img" />
                  <span class="margin_top20 fontsize_12 color_8C">敬请期待…</span>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="footer-btn">
            <div class="footer-btn-item footer-btn-close" @click="closeTheme">
              取消
            </div>
            <div class="footer-btn-item footer-btn-confrim" @click="confrimTheme" v-if="currentTemp==-1">
              确定
            </div>
          </div>
          <div class="nodata" v-if="themes.length == 0">
              <img src="@/assets/nodata/temp_nodata.png" alt="" class="statu_img" />
              <span class="margin_top20 fontsize_12 color_8C">敬请期待…</span>
            </div>
        </div>
      </div>
    </el-dialog>
    <fd-loading :loading="fdloading"></fd-loading>
    <el-dialog title="" custom-class="preview-dialog" :visible.sync="previewVisible" width="1076px" top="15vh">
      <div>
        <el-image :src="previewSrc" class="" fit="cover" style="width:100%;"></el-image>
      </div>
    </el-dialog>
    <hint-dialog :visible.sync="tipVisible" :hint="hintObj" @click="toUpgrade"></hint-dialog>

  </div>
</template>
<script>
import { mapState,mapActions } from 'vuex'
import {
  getTempList,
  getTempCategory,
  useTemplate,
  previewTemplate,
  getThemeList
} from "@/libs/api/template";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStyle: 0,
      title: "新建课程",
      hintObj: {
        content: '',
        version: ''
      },
      page: 1,
      total: 0,
      limit: 20,
      category_id: 0,
      category_list: [],
      category_sub_list: [],
      tempVisible: false,
      previewVisible: false,
      tipVisible: false,
      fdloading: false,
      currentTemp: -1,
      currentTheme: 0,
      currentTempNav: 0,
      tempLists: [],
      themes: [],
      previewSrc: '',
      theme_id: '',
      showmore:false,
      showmorebtn:false
    };
  },
  watch: {
    visible(nval) {
      if (nval) {
        this.tempVisible = true;
      } else {
        this.tempVisible = false;
      }
    },
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
      userInfo: state => state.user.userInfo,
    })
  },
  mounted() {
    this.getCategoryList()
    this.getThemes()
  },
  methods: {
    ...mapActions(['CreateCourse','GetCourseList','ConcatCourses']),
    beforeClose() {
      // this.$emit('close',done)
      this.$emit("update:visible", false);
    },
    close() {
      this.$emit("update:visible", false);
    },
    getCategoryList() {
      // 获取课程列表
      getTempCategory()
        .then((res) => {
          if (res.errorcode == 0) {
            this.category_list = res.data;
            if (res.data.length) {
              this.category_id = res.data[0].sub[0].id;
              this.category_sub_list = res.data[0].sub;
              this.getList();
            }
          }
        })
        .catch(() => { });
    },
    getList() {
      // 获取课程列表
      let params = {
        category_id: this.category_id,
        page: this.page,
        limit: this.limit,
        keyword:""
      };
      getTempList(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.tempLists =
              this.page > 1 ? this.tempLists.concat(res.data) : res.data || [];
            this.total = res.total;
          } else {
            this.total = -1;
          }
        })
        .catch(() => {
          this.total = -1;
        });
    },
    // 获取主题列表
    getThemes() {
      getThemeList({ limit: 20 })
        .then((res) => {
          this.themes = res.data;
        })
        .catch((err) => {

        })
    },
    // 预览主题
    previewTheme(item) {
      this.previewSrc = item.cover;
      this.previewVisible = true;
    },
    // 预览模板
    previewTemp(item) {
      this.fdloading = true;
      let pageFrame = null;
      if(this.is_web) {
        pageFrame = window.open("/#/preview/"+item.id,"preview");
      }
      previewTemplate({ id: item.id })
        .then((res) => {
          this.fdloading = false;
          if (res.errorcode == 0) {
            this.openNewWin( res, item.id, pageFrame );
          }
        })
        .catch((err) => {
          this.fdloading = false;
        })
    },
    openNewWin(res, id, pageFrame) {
      if(this.is_web) {
        pageFrame.location.replace(res.data.preview_url);
        return;
      }
      // electron端
      try {
        let { href } = this.$router.resolve({
          path: '/preview/'+id,
          query:{
            url: res.data.preview_url
          }
        });
        window.open(href, "_blank");
      } catch (error) {
        console.error( error )
      }
    },
    // 使用模板
    useTemp(item) {
      this.fdloading = true;
      useTemplate({ id: item.id })
        .then((res) => {
          this.fdloading = false;
          if (res.errorcode == 10011 || res.errorcode == 10010) {
            this.$message.closeAll()
            this.hintObj = {
              content: res.msg,
              version: res.data.version
            }
            this.tipVisible = true;
          }
          if (res.errorcode == 0) {
            this.$message.success(res.msg)
            this.$router.push("/course/edit/" + res.data.course_id + "?pageType=index");
          }
        })
        .catch((err) => {
          this.fdloading = false;

        })
    },
    showMoreBtn(){
      this.$nextTick(()=>{
        let childsWidth=0;
        let parentWidth = this.$refs.tempnavberRef.offsetWidth;
        let element =this.$refs.tempnavberRef.children;
        for (let i = 0; i < element.length; i++) {
          childsWidth+=element[i].offsetWidth;
        }
        this.showmorebtn=childsWidth > parentWidth?true:false
      })
    },
    // 切换模板分类
    tabTempNavSub(item, index) {
      this.page = 1;
      this.currentTempNav = index;
      this.category_id = item.id;
      this.getList()
    },

    tabTempNav(item, index) {
      this.currentTemp = index;
      if (index != -1) {
        this.page = 1;
        this.currentTempNav = 0;
        this.category_id = item.sub[0].id;
        this.category_sub_list = item.sub;
        this.getList()
        this.showMoreBtn()
      }
    },
    tabThemeItem(item) {
      console.log(item, this.userInfo)
      if (item.is_vip == 1 && item.is_default != 1) {
        this.hintObj = {
          content: '您当前为免费版，请升级版本后使用。',
          version: '专业版'
        }
        this.tipVisible = true;
        return false
      }
      this.themes.map((v) => {
        v.is_default = 0
      })
      item.is_default = 1;
      this.theme_id = item.id;
    },
    getAllCourseList(id) {
      // 获取课程列表
      let params = {keyword:'', sort_mode: 'default', page: 1, limit: 20};
      
      this.GetCourseList(params).then((res)=>{
        if(res.errorcode == 0) {
          this.courseList = this.page > 1 ?  this.courseList.concat(res.data.data) : (res.data.data || []);
          //合并数组后更新vuex,主题判断主要用到
          this.ConcatCourses(this.courseList);
          this.total = res.data.total;
          // 等数据更新后再跳转
          this.$router.push('/course/edit/' + id + "?pageType=index");
        }else {
          this.total = -1;
        }
      }).catch(()=>{
        this.total = -1;
      });
    },
    confrimTheme() {
      this.dialogVisible = false;
      this.CreateCourse({ title: this.title, theme_id: this.theme_id }).then(res => {
        if (res.errorcode == 10011 || res.errorcode == 10010) {
          this.$message.closeAll()
          this.hintObj = {
            content: res.msg,
            version: res.data.version
          }
          this.tipVisible = true;
          return false
        }
        if(res.errorcode==0){
          this.getAllCourseList(res.data.course_id );
        }
      }).catch((err) => {
        console.log('创建课程失败')
      });
    },
    closeTheme() {
      this.tempVisible = false;
    },
    toUpgrade() {
      this.tipVisible = false;
      this.tempVisible = false;
      this.$parent.upgradeDialogVisible = true;
    },
    handleMore(){
      this.showmore=!this.showmore;

    }
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
};
</script>
<style lang="less" scoped>
@liMarginRight: 24px;

.nodata {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -43px;

  .statu_img {
    margin-top: 0;
    width: 100px;
  }
}

.temp-navbar {
  margin-top: 16px;
  width: 100%;
  display: flex;

  .more {
    text-align: center;
    height: 32px;
    line-height: 33px;
    flex-shrink: 0;
    font-size: 14px;
    color: #262626;
    transition: all 0.3s;
    margin-left: 24px;
    cursor: pointer;
    &:hover{
      color: #e74362;
      .icon-xialajiantou::before{
        color: #e74362;
      }
    }
    .icon-gray-down-arrow{
      &::before{
      }
    }
  }

  &-ul {
    display: flex;
    width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &-li {
    flex-shrink: 0;
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    padding: 6px 16px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all 0.3s;
    margin-right: 16px;
    margin-bottom: 12px;
    border: 1px solid #fff;
    cursor: pointer;

    &:hover {
      background: #f8eff1;
      border: 1px solid #f8eff1;
    }

    &.current {
      color: #fff;
      background: #e74362;
    }

    &.current2 {
      color: #e74362;
      border: 1px solid #e74362;
    }
  }
}

/deep/.create-temp-dialog {
  max-width: 80%;
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .temp-dialog-main {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    // height: 695px;
    height: 80vh;

    &__left {
      width: 190px;
      height: 100%;
      padding: 24px 0;
      background-color: #fff;
      flex-shrink: 0;
      box-sizing: border-box;

      .nullcourse {
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        padding-left: 32px;
        background: #fff;
        border-radius: 0 4px 4px 0;
        position: relative;
        cursor: pointer;

        &.current {
          background: #f8eff1;
          color: #e74362;

          &::before {
            content: "";
            width: 4px;
            height: 100%;
            background: #e74362;
            border-radius: 0 4px 4px 0;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .menu-ul {
        height: 554px;
        overflow: hidden scroll;
      }

      .menu-li {
        height: 48px;
        line-height: 48px;
        background-color: #fff;
        border-radius: 4px;
        font-size: 16px;
        color: #595959;
        padding-left: 32px;
        box-sizing: border-box;
        margin-top: 4px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          background: #f8eff1;
        }

        &.current {
          background: #f8eff1;
          color: #e74362;
          position: relative;

          &::before {
            content: "";
            width: 4px;
            height: 100%;
            background: #e74362;
            border-radius: 0 4px 4px 0;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &__right {
      flex: auto;
      background-color: #f5f5f5;
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      box-sizing: border-box;
      height: 100%;

      .temp-conent {
        padding: 0 16px;
        height: calc(100% - 52px);
        overflow: hidden scroll;
      }

      .header-title {
        height: 48px;
        line-height: 48px;
      }

      .header-title2 {
        height: 22px;
        line-height: 22px;
        margin-top: 32px;
      }

      .header-input {
        height: 34px;
        background-color: #fff;
        font-size: 14px;
        color: #595959;
        border-radius: 4px;
        margin-top: 6px;

        .el-input__inner {
          border: none;

          &::placeholder {
            font-size: 14px;
          }
        }
      }

      .theme-ul {
        display: flex;
        max-height: 500px;
        overflow: hidden scroll;
        padding-top: 24px;
        flex-wrap: wrap;
        margin-right: -30px;
      }

      .theme-li {
        width: 247px;
        height: 240px;
        border-radius: 4px;
        margin-right: 24px;
        margin-bottom: 30px;
        transition-property: box-shadow, transform;
        transition-duration: 0.5s;
        box-sizing: content-box;
        background-color: #fff;
        justify-content: space-between;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &:first-child {
          margin-left: 0;
        }



        &__select {
          border: 2px solid #e74362;
        }

        &__top {
          width: 100%;
          height: 200px;
          position: relative;
          overflow: hidden;
          border-radius: 4px 4px 0 0;

          &-img {
            width: 100%;
            height: 100%;
            border-radius: 4px 4px 0 0;
          }

          &-corner {
            padding: 0 10px;
            height: 24px;
            line-height: 24px;
            border-radius: 4px 100px 100px 0;
            background: #fe840f;
            font-size: 14px;
            color: #ffffff;
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 0;
            transform: translateX(-130px);
            transition: all 0.5s;
          }

          &-hg {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 48px;
          }
        }

        &__bottom {
          height: 40px;
          padding: 0 10px;
          background-color: #fff;
          border-radius: 0 0 4px 4px;

          &-title {
            width: 140px;
            height: 20px;
            line-height: 20px;
          }

          &-btn {
            height: 24px;
            line-height: 24px;
            padding: 0 16px;
            font-size: 14px;
            color: #595959;
            background: #f0f0f0;
            border-radius: 4px;
            transition: all 0.3s;

            &:hover {
              background: #d9d9d9;
            }
          }
        }

        &:hover {
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
          // transform: translateY(-6px);
        }

      }

      .theme-li:hover .theme-li__top-corner {
        transform: translateX(0);
        visibility: visible;
      }

      .footer-btn {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        background-color: #fff;
        padding: 10px 16px;

        &-item {
          height: 30px;
          width: 76px;
          line-height: 30px;
          text-align: center;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.5s;
        }

        &-confrim {
          color: #fff;
          border: 1px solid #e74362;
          background-color: #e74362;
          margin-left: 24px;
          &:hover{
            background-color: #b5102f;
          }
        }

        &-close {
          color: #8c8c8c;
          border: 1px solid #d9d9d9;
          background-color: #fff;
          &:hover{
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}
.theme-ul-scroll {
  height: calc(100% - 142px);
  /deep/.el-scrollbar__bar.is-horizontal {
    display: none;
  }
  /deep/.el-scrollbar__view {
    height: 100%;
  }
}
.temp-list-scroll {
  margin-top: 2px;
  height: calc(100% - 64px); //16 +32 + 16
  /deep/.el-scrollbar__bar.is-horizontal {
    display: none;
  }
  /deep/.el-scrollbar__view {
    height: 100%;
  }
  // 滚动条占宽会导致页面对不齐
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.temp-list-container {
  height: 100%;
  margin-right: -30px; //抵消li的margin-right
  // padding-right: 24px;
}

.tempbox-wrapper {
  // overflow: hidden;
  margin-bottom: 24px;
  margin-right: @liMarginRight;
  box-sizing: border-box;
  border-radius: 4px;
  width: 247px;
  height: 254px;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
    // transform: translateY(-6px);

    .tempbox-mask {
      opacity: 1;
    }

    .tempbox-mask-tools {
      span {
        transform: translateY(0);
      }
    }

    .tempbox-mark-corner {
      transform: translateX(0);
    }
   
  }
}

.tempbox-image {
  border-radius: 4px 4px 0 0;
  width: 247px;
  height: 172px;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;

  .tempbox-image-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .tempbox-image-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.tempbox-tools {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
  padding: 0 8px;

  &-btn {
    color: #595959;
    height: 30px;
    min-width: 186px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    transition: all 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.tempbox-mask {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);

  &.show {
    opacity: 1;
  }
}

.tempbox-mask-tools {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px 4px 0px 0px;

  span {
    width: 118px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    // transform: translateY(60px);
    transition: all 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
.coursebox-hg {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 48px;
    }
.tempbox-mark-corner {
  height: 21px;
  line-height: 21px;
  font-size: 12px;
  color: #fff;
  padding: 0 6px;
  background: #fe840f;
  border-radius: 4px 0 4px 0;
  position: absolute;
  left: 0;
  top: 0;
  // z-index: 99;
  // transform: translateX(-64%);
  transition: all 0.3s;

  &:hover {
    transform: translateX(0);
  }
}

.tempbox-title {
  width: 224px;
  height: 20px;
  line-height: 20px;
  padding: 6px 8px 10px;
}

.tempbox-title /deep/p {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/.preview-dialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    right: 16px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #8C8C8C;
    font-size: 20px;
    font-weight: 700;
  }

  .el-dialog__body {
    padding: 52px 16px 16px;
  }
}

.icon-upgrade-remind:before {
  color: #faad14;
}

.p-content {
  font-size: 16px;
  color: #262626;
  line-height: 24px;
  margin-bottom: 12px;
}

.morefunction {
  font-size: 14px;
  color: #e74362;
  line-height: 22px;
  margin-top: 8px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #B5102F;
  }
}
</style>
