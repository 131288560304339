<template>
  <div>
    <el-dialog
      title=""
      custom-class="tip-dialog"
      :visible.sync="hintVisible"
      :appendToBody="appendToBody"
      width="480px"
      top="30vh"
    >
      <div class="flex">
        <div><i class="icon-upgrade-remind fontsize_24"></i></div>
        <div class="margin_left16">
          <p class="p-content" v-html="hint.content"></p>
          <p class="lineheight_22 color_59 fontsize_14" v-if="hint.version" v-html="hint.version"></p>
          <!-- <p class="morefunction" v-if="ismore">了解更多版本功能>></p> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confrim">去升级</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ismore: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: Object,
      default: () => {
        return {
          content: "",
          version: "",
        };
      },
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hintVisible: false,
    };
  },
  watch: {
    visible(nval) {
      if (nval) {
        this.hintVisible = true;
      } else {
        this.hintVisible = false;
      }
    },
  },
  mounted() {},
  methods: {
    beforeClose() {
      this.$emit("update:visible", false);
    },
    close() {
      this.$emit("update:visible", false);
    },
    confrim() {
      this.$emit("click");
    },
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
};
</script>
<style lang="less" scoped>
/deep/.tip-dialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 32px 32px 0 32px;
  }

  .el-dialog__footer {
    padding: 24px 32px;

    .el-button {
      padding: 5px 16px;
      line-height: 20px;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);

      &--default {
        color: #595959;
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.15);
          background-color: #f5f5f5;
          color: #595959;
        }
        &:focus,&:active {
          border: 1px solid #B7B7B7;
        }
      }

      &--primary {
        border: 1px solid #e74362;
        background-color: #e74362;
        color: #fff;

        &:hover {
          border: 1px solid  #E95672;
          background-color:  #E95672;
          color: #fff;
        }
        &:focus,&:active {
          background-color: #CF3C58;
          border: 1px solid  #CF3C58;
        }
      }
    }
  }

  .icon-upgrade-remind:before {
    color: #faad14;
  }

  .p-content {
    font-size: 16px;
    color: #262626;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: bolder;
  }

  .morefunction {
    font-size: 14px;
    color: #e74362;
    line-height: 22px;
    margin-top: 8px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #B5102F;
    }
  }
}
</style>
