import { getComponentList, addComponent, getComponentListByGroup, createComponent } from '@/libs/api/component'

const component = {
  state: {
    groupList:[],
    totalGroupDetail:{
      list:[],
      group:[],
    },
    copying: false,
    copyData: {},
    copyTips: false,
  },
    
  mutations: {
    SET_GROUPLIST(state, data) {
      state.groupList = data;
    },
    SET_GROUPDETAIL(state, data) {
      state.totalGroupDetail.list = data.component_data;
      state.totalGroupDetail.group = data.group_list;
    },
    SET_COPYING(state, data) {
      state.copying = data.copying;
      state.copyData = data.data;
    },
    SET_COPYTIPS(state, data) {
      state.copyTips = data;
    },
  },
    
  actions: {
    // 获取component列表
    GetComponentList({commit},{keyword, group_id} = {}) {
      return new Promise((resolve,reject)=>{
        getComponentList({keyword, group_id}).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            commit("SET_GROUPDETAIL", res.data);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
    // 创建Component
    CreateComponent({commit, state, dispatch}, {course_id, _parentId, _layout, _component, component_mode_id}) { //_parentId: block的ID
      return new Promise((resolve,reject)=>{
        createComponent({
          course_id: course_id,
          _parentId:_parentId,
          _layout,
          _component,
          component_mode_id
        }).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 创建组件
    AddComponent({},{course_id,block_id, _layout}) {
      return new Promise((resolve,reject)=>{
        addComponent(course_id, block_id, _layout).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
    // 获取component类型分组列表
    GetComponentListByGroup({commit}) {
      return new Promise((resolve,reject)=>{
        getComponentListByGroup().then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            commit("SET_GROUPLIST", res.data.group_data || []);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
  }
}
    
export default component