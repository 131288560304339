import { getMediaList, getMediaTagList } from '@/libs/api/media'

const media = {
  state: {
    
  },
    
  mutations: {

  },
    
  actions: {
    // 获取媒体列表
    GetMediaList({commit},{type,keyword,tag_name_list,limit,page, exts}) {
      let params = {
        type: type || 'all',
        limit: limit || 20,
        page: page || 1,
        keyword: keyword || "",
        tag_name_list: tag_name_list || "",
      };
      if(exts) {
        params.exts = exts;
      }
      return new Promise((resolve,reject)=>{
        getMediaList(params).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
    // 获取媒体 标签列表
    GetMediaTagList({commit},tag_name) {
      return new Promise((resolve, reject) => {
        getMediaTagList(tag_name).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
  }
}
    
export default media