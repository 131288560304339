import {createBlock, getBlockList, getBlockOne, delBlockOne, moveBlock, copyBlock } from '@/libs/api/block'

const block = {
  state: {
      
  },
    
  mutations: {
      
  },
    
  actions: {
    // 创建block
    CreateBlock({commit, state, dispatch}, {course_id,_parentId,title,_layout, below_block_id,addComponent=false}) { //_parentId: article的ID
      return new Promise((resolve,reject)=>{
        createBlock({
          course_id: course_id,
          _parentId:_parentId,
          title:title,
          _layout,
          below_block_id
        }).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            !!addComponent && dispatch('CreateComponent',{
              course_id, 
              _parentId:res.data.block["block-_id"], 
              _layout:"full", 
              _component: "text", 
              component_mode_id: 12
            });
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 获取block列表
    GetBlockList({},{course_id,article_id}) {
      return new Promise((resolve,reject)=>{
        getBlockList(course_id, article_id).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
    // 获取block详情
    GetBlockOne({},{course_id,_id}) {
      // getBlockOne
      getBlockOne(course_id, _id).then((res)=>{
        if(res.errorcode == 0) {
           
        }
      }).catch(()=>{
  
      });
    },
    // 删除单个block
    DelBlockOne({commit, state, dispatch}, {course_id,_id}) {
      return new Promise((resolve,reject)=>{
        delBlockOne({course_id,_id}).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 移动block
    MoveBlock({commit, state, dispatch}, {course_id, block_id, move_way}) {
      return new Promise((resolve,reject)=>{
        moveBlock({
          course_id,
          block_id,
          move_way
        }).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 复制block
    CopyBlock({commit, state, dispatch}, {course_id, block_id}) {
      return new Promise((resolve,reject)=>{
        copyBlock({
          course_id,
          block_id,
        }).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
  }
}
    
export default block