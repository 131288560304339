import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function getMediaList({type,keyword,tag_name_list,limit,page,exts}) { 
  tag_name_list = tag_name_list || []
  let url = "/api/"+ Config.apiversion +"/upload/file/list?type="+type + '&keyword='+keyword  + '&limit='+limit + '&page='+page;
  
  if(tag_name_list.length > 0) {
    tag_name_list.forEach( (v,i) => {
      url = url + `&tag_name_list[${i}]=`+v
    })
  }
  if(exts) {
    url = url + '&exts='+exts
  }
  return request.get({
    url: url,
  })
}

export function getMediaTagList(tag_name) {
  let url = "/api/"+ Config.apiversion +"/upload/tag/list";
  if(tag_name && tag_name.trim()) {
    url = url + '?tag_name=' + tag_name;
  }
  return request.get({
    url: url
  })
}

export function updateMediaFile({id, name, description, tag_name, tag_name_list,file}) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('id', id);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('tag_name', tag_name);
  for(let i in tag_name_list) {
    formData.append(`tag_name_list[${i}]`, tag_name_list[i]);
  }
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/file/update",
    data: formData
  })
}

// 上传单张网络图片
export function uploadWebImage({image_url, name, description, tag_name_list}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/web_image",
    data: {
      image_url,
      name,
      description,
      tag_name_list
    }
  })
}

export function delMediaFile(id) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/file/delete",
    data: {
      id
    }
  })
}

// 获取单个媒体文件 详情
export function getMediaDetail(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/upload/file/get?id="+id
  })
}

// 上传单个文件
export function uploadSingleFile({file, name, description, tag_name_list}) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('name', name);
  formData.append('description', description);
  for(let i in tag_name_list) {
    formData.append(`tag_name_list[${i}]`, tag_name_list[i]);
  }
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/file",
    data: formData
  })
}

// 新增 媒体标签
export function addMediaTag(tag_name) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/tag/add",
    data: {
      tag_name
    }
  })
}

// 删除 媒体标签
export function delMediaTag(id) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/tag/delete",
    data: {
      id
    }
  })
}

// 更新 媒体标签
export function updateMediaTag(id,tag_name) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/upload/tag/update",
    data: {
      id,
      tag_name
    }
  })
}

// 媒体标签-获取文件类型与对应后缀
export function getMediaTypeList(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/upload/typelist"
  })
}