import {
  setShare, getShareCourse, confirmShare, shareDetail, previewShareCourse,
  getShareCourseList,deleteShare,shareCourseDetail,shareCourseSet
} from '@/libs/api/share';
const share = {
  state: {
    token: null,
    couseListTotal: 0,
    courseList: [],
    defaultVal: {},
    max_course_num:5,
    use_course_num:0
  },

  getters: {
    // 获取最大课程数
    getMaxCourseNum: (state) => () => {
      return state.max_course_num
    },
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_LIST(state, data) { //课程列表
      state.couseListTotal = data.length;
      state.courseList = data;
    },

    SET_TOTAL(state, data) { //课程列表 总数
      state.couseListTotal = data;
    },
    SET_MAX_COURSE_NUM(state,{max_course_num}){ //当前用户模板、主题,可创建课程的最大数
      state.max_course_num = max_course_num;
    },
    SET_USE_COURSE_NUM(state,{use_course_num}){ //当前用户模板、主题,已使用课程数
      state.use_course_num = use_course_num;
    }
  },

  actions: {
    // 获取分享课程
    GetShareCourse({ commit },{title}) {
      return new Promise((resolve, reject) => {
        getShareCourse({title}).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 设置详情
    ShareDetail({ commit },id) {
      return new Promise((resolve, reject) => {
        shareDetail(id).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 课程内页-设置详情
    ShareCourseDetail({ commit },course_id) {
      return new Promise((resolve, reject) => {
        shareCourseDetail(course_id).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 设置详情
    DeleteShare({ commit },id) {
      return new Promise((resolve, reject) => {
        deleteShare(id).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 预览课程（分享)
    PreviewShareCourse({ commit },{p}) {
      return new Promise((resolve, reject) => {
        previewShareCourse(p).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 获取分享课程列表
    GetShareCourseList({ commit }, { keyword, sort_mode, page, limit, ...args }) {
      return new Promise((resolve, reject) => {
        getShareCourseList({ keyword, sort_mode, page, limit, ...args }).then(res => {
          // console.log('res :>> ', res);
          resolve(res);
          if (res.errorcode == 0) {
            commit("SET_LIST", res.data);
            // commit("INIT_COLLECTION", res.data.data); //暂不用
          } else {
            commit("SET_TOTAL", -1);
          }
        }).catch(err => {
          commit("SET_TOTAL", -1);
          reject(err)
        });
      });
    },
    // 设置分享
    SetShare({ commit, state, dispatch }, {if_reset_url,id,set_type,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
      return new Promise((resolve, reject) => {
        setShare({if_reset_url,set_type,id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      })
    },
    // 设置分享
    SetCourseShare({ commit, state, dispatch }, {if_reset_url,course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
      return new Promise((resolve, reject) => {
        shareCourseSet({if_reset_url,course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      })
    },
    // 确认分享
    ConfirmShare({ commit, state, dispatch }, {course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
      return new Promise((resolve, reject) => {
        confirmShare({course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}).then((res) => {
          if (res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err) => {
          reject(err);
        });
      })
    },
    
  }
}

export default share
