<template>
  <fd-dialog
    :title="title"
    :visible="visible"
    width="1036px"
    center
    :modal-append-to-body="true"
    :append-to-body="appendToBody"
    :destroy-on-close="true"
    :before-close="beforeCloseDialog"
    custom-class="media-select-dialog">
    <el-container>
      <el-aside 
        v-if="mediaType === 'all' && (!exts || exts && exts.length==0)"
        :style="{'width': mediaType === 'all' ? '100px' : '0px',
          '--line-translateY': `${ 68 * Number(currentTypeIndex) + 1 }px`}" class="media-aside">
        <div class="active-right-line"></div>
        <div v-for="(citem, cind) in categories" :key="citem.title" 
          class="tabs_item pointer" 
          :class="{'isFirst':  cind === 0, 'active': currentTypeIndex == cind }"
          @click="changeCate(citem,cind)" >
          <span>{{citem.title}}</span>
        </div>
      </el-aside>
      <el-main class="media-main" :style="{'--media-main-padding': contentContainerPadding, '--media-upload-btn-margin': uploadBtnMargin }">
        <el-row class="flex flex_acenter">
          <el-col :span="12" class="flex flex_acenter">
            <el-upload
              class="upload-btn"
              ref="uploadFile"
              name="file"
              :accept="fileType"
              :headers="headers"
              :action="https+'/api/V1/upload/file'"
              :multiple="false"
              :auto-upload="true"
              :show-file-list="false"
              :on-progress="uploadProgress"
              :on-change="uploadChange"
              :on-success="uploadSuccess"
              :file-list="uploadFileList">
              <button size="small" type="primary" class="dialog-form-upload flex flex_acenter flex_center fontsize_12 color_222 pointer">
                <i class="icon-course-add"></i><span class="padding_left8">上传{{currentTypeIndex == 0 ? '文件' : categories[currentTypeIndex].title}}</span>
              </button>
            </el-upload>
          </el-col>
          <el-col :span="12" class="flex flex_acenter flex_end padding_right16">
            <el-input
              style="width:186px;height:30px;line-height:30px;"
              @input="searchMedia"
              :placeholder="'根据文件名称搜索'"
              v-model="keyword" class="margin_right16">
              <i slot="prefix" class="input__icon icon-search color_222"></i>
            </el-input>
            <div class="prel">
              <el-select 
                v-model="tagname"  
                @change="changeTags"
                collapse-tags
                multiple 
                :popper-append-to-body="false" 
                :placeholder="'根据标签搜索'" 
                @visible-change="tagsVisibleChange"
                :style="{'--arrow-down': tagname.length>0 ? 'none' : 'block' }"
                class="media-tags prel">
                <!-- <div class="media-tags-input-wrapper">
                  <el-input  :placeholder="$t('lang.searchoftag')" @input="filterTags" :value="tagname2"  class="media-tags-input noborder">
                    <i
                      class="icon-search padding_right4 fontsize_12 color_999"
                      slot="suffix">
                    </i>
                  </el-input>
                </div> -->
                <el-option
                  v-for="item in fileTagList"
                  :key="item.id"
                  :label="item.tag_name"
                  :value="item.tag_name" >
                </el-option>
              </el-select>
              <span  v-if="tagname.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close" @click.stop="clearTags('search')"></span>
            </div>
          </el-col>
        </el-row>
        <el-row class="margin_top16 flex">
          <el-col v-if="listLen > 0" style="width:calc(100% - 245px);height:500px;" class="flex1">
            <div class="media-list flex flexwrap scroll-box flex_contentstart" v-on:scroll.passive="onScroll" ref="scrollContainer" >
              <div ref="scrollInner">
                <el-checkbox-group class="flex flexwrap" v-model="checkList" @change="changeSelect">
                  <div v-if="uploadList.status === 'loading'" class="media-list-item upload-progress-item">
                    <div class="media-list-card" :style="{'width': `${cardWidth}px`}">
                      <div class="media-image">
                        <div class="media-progress">
                          <div class="media-progress-inner" :style="{'width':uploadList.percent}"></div>
                        </div>
                      </div>
                      <div class="margin_top5">
                        <div class="tips-wrap">
                          <div class="tips-txt fontsize_14 color_222 pover" style="width:90px;">{{uploadList.name}}</div>
                          <div :title="uploadList.name" class="tips-title fontsize_14" style="width:90px;">{{uploadList.name}}</div>
                        </div>
                        <div class="flex flex_acenter padding_top6 lineheight_18 fontsize_12 color_A1A1A1">
                          <i :class="[ listIconClass[uploadList.type]  ]" class="fontsize_12 color_A1A1A1"></i>
                          <span class="padding_left8">{{uploadList.typeName}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-checkbox v-for="(litem, index) in list" :key="litem.id" class="media-select-checkbox" :label="litem.id" :style="{'--card-width': `${cardWidth}px` }">
                    <div  class="media-list-item" @click="tapMedia(index,litem)">
                      <div class="media-list-card" :style="{'width': `${cardWidth}px`}">
                        <el-image :src="litem.cover" fit="cover" lazy class="media-image"></el-image>
                        <div class="margin_top5">
                          <!-- <div :title="litem.name+'.'+litem.ext" class="padding_top5 lineheight_20 fontsize_14 color_222 pover" style="width:90px;">{{litem.name}}</div> -->
                          <div class="tips-wrap">
                            <div class="tips-txt fontsize_14 color_222 pover" style="width:90px;">{{litem.name+'.'+litem.ext}}</div>
                            <div :title="litem.name+'.'+litem.ext" class="tips-title fontsize_14" style="width:90px;">{{litem.name+'.'+litem.ext}}</div>
                          </div>
                          <div class="flex flex_acenter padding_top6 lineheight_18 fontsize_12 color_A1A1A1">
                            <i :class="[ listIconClass[litem.type]  ]" class="fontsize_12 color_A1A1A1"></i>
                            <span class="padding_left8">{{litem.typeName}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-col>
          <el-col v-if="listLen > 0" style="width:245px;margin-right:-10px;">
            <div class="media-preview" v-if="checkListLen > 0 && previewDetail.id">
              <div class="media-preview-inner flex flexcolumn flex_acenter flex_jcbetween height_100p">
                <div class="width_100p">
                  <!-- <el-image v-if="previewDetail.type == 'picture'" class="media-preview-cover margin_bot16" :src="(previewDetail.url) + '?x-oss-process=style/m'" fit="cover"></el-image> -->
                  <div v-if="previewDetail.type == 'picture'" class="media-preview-cover-outer prel margin_bot16">
                    <el-image class="media-preview-cover" :src="previewDetail.url" fit="cover" lazy></el-image>
                    <div class="media-preview-cover-mask flex flex_acenter flex_center pointer" @click="handlePicturePreview">
                      <div class="padding_10">
                        <i class="fontsize_32" :class="{
                          'icon-picture-enlarge':previewDetail.type === 'picture' || previewDetail.type === 'other',
                          'icon-media-paly':previewDetail.type === 'video' || previewDetail.type === 'audio',
                        }"></i>
                      </div>
                    </div>
                  </div>
                  <el-image v-else class="media-preview-cover margin_bot16" :src="previewDetail.cover" fit="cover" lazy></el-image>
                  <p class="fontsize_16 color_222 breakword lineheight_24 pover2 textcenter">{{previewDetail.name}}</p>
                  <p class="fontsize_12 color_A1A1A1 margin_top26 textcenter">大小：{{previewDetail.filesize_string}}</p>
                  <p class="fontsize_12 color_A1A1A1 margin_top13 textcenter" v-if="previewDetail.type === 'picture' && previewDetail.width">尺寸：{{previewDetail.width}}*{{previewDetail.height}}</p>
                </div>
                <div class="flex flexcolumn flex_acenter margin_top40 width_100p" :class="{'margin_bot32':previewDetail.is_common == 1}">
                  <button @click="sureSelect(previewDetail)" class="media-sure pointer">确认选择</button>
                  <button @click="showDialogEdit(previewDetail)" class="media-edit margin_top16 pointer">{{$t('lang.edit')}}</button>
                  <button v-if="previewDetail.is_common != 1" @click="showDialogDel(previewDetail)" class="media-del margin_top16 pointer">{{$t('lang.delete')}}</button>
                </div>
              </div>
            </div>
          </el-col>
          <div v-if="listLen <= 0" class="nodata" style="height:500px;">
            <img v-if="keyword != ''" src="@/assets/nodata/course_search_nodata.svg" alt="" class="statu_img" loading="lazy">
            <img v-else src="@/assets/nodata/couse_nodata.svg" alt="" class="statu_img" loading="lazy">
            <span class="margin_top16 fontsize_12 color_A1A1A1">{{ keyword != '' ? '无搜索内容' : '暂无媒体'}}</span>
          </div>
        </el-row>
      </el-main>
    </el-container>
    <fd-dialog
      append-to-body
      :title="'删除'"
      :visible.sync="delVisible"
      width="600px"
      :before-close="(arg)=>beforeCloseDialog(arg,'del')"
      custom-class="course-dialog">
      <p class="fontsize_16 color_222 textcenter margin_bot16">请确认是否删除这个文件</p>
      <p class="fontsize_14 color_E74362 textcenter">您确认要删除文件吗？删除之后将无法找回</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="delMedia(previewDetail)" class="course-save pointer">确定删除</button>
        <button @click="delVisible = false" class="course-close pointer">不删了</button>
      </span>
    </fd-dialog>
    <fd-dialog
      destroy-on-close
      :title="dialogSet.title"
      :visible.sync="editVisible"
      :before-close="(arg)=>beforeCloseDialog(arg,'edit')"
      append-to-body
      width="1036px"
      custom-class="course-dialog full">
      <el-form @submit.native.prevent class="flex">
        <photoEditor v-if="editDetail.type === 'picture'" ref="photo-editor" @cropChange="cropChange" :src="editDetail.url" class="flexshrink" style="width:57%;"></photoEditor>
        <div class="flex1 flexshrink padding_left20">
          <div v-for="ditem in mediaResourceSetFieldList" :key="ditem.id" 
            class="dialog-form-item" >
            <p class="margin_bot7 fontsize_14 color_222"><span>{{ditem.label}}</span><span class="padding_left4 color_D0021B" v-if="ditem.type === 'uploadFile'">*</span></p>
            <p v-if="ditem.tips" class="margin_bot8 fontsize_12 color_A1A1A1">{{ditem.tips}}</p>
            <el-input v-if="ditem.type === 'text'"
              :disabled="editDetail.is_common == 1"
              v-model="editDetail[ditem.propsName]" autocomplete="off" :placeholder="ditem.placeholder" class="media-edit-input noborder height36"></el-input>
            <div v-else-if="ditem.type === 'selectAllowCreate'" class="prel">
              <el-select 
                :disabled="editDetail.is_common == 1"
                v-model="editDetail[ditem.propsName]"
                value-key="tag_name"
                multiple
                filterable
                remote
                allow-create
                default-first-option
                :placeholder="ditem.placeholder"
                :remote-method="remoteTags"
                @change="changeSelectTag($event,ditem.propsName)"
                class="select-allow-create noborder width_100p">
                <el-option
                  v-for="item in fileTagList"
                  :key="item.id"
                  :label="item.tag_name"
                  :value="item.tag_name">
                </el-option>
              </el-select>
              <span  v-if="oldTag.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close" @click.stop="clearTags('file')"></span>
            </div>
            <div v-else-if="ditem.type === 'uploadFile'" class="fontsize_14 color_4A90E2 lineheight_24">
              {{editDetail.original_name || editDetail.name}}
            </div>
            <div v-else-if="ditem.type === 'picture_size'" class="flex flex_acenter">
              <div v-for="(pitem, pind) in ditem.propArr" :key="pitem.propsName" class="flex flex_acenter">
                <el-input @input="changePic($event,pitem.propsName)" v-model="editDetail[pitem.propsName]" :min="0" type="number" autocomplete="off" 
                :placeholder="pitem.placeholder" class="media-edit-input height36 noborder"></el-input>
                <span v-if="pind !== ditem.propArr.length - 1" class="fontsize_24 color_A1A1A1 padding_0_10"  >:</span>
              </div>
            </div>
            <div v-else-if="ditem.type === 'picture_selectarea'"  class="flex flex_acenter">
              <div v-for="(pitem, pind) in ditem.propArr" :key="pitem.propsName" class="flex flex_acenter">
                <el-input @input="changePic($event,pitem.propsName)" v-model="editDetail[pitem.propsName]" :min="0" type="number" autocomplete="off" 
                :placeholder="pitem.placeholder" class="media-edit-input height36 noborder"></el-input>
                <span v-if="pind !== ditem.propArr.length - 1" class="fontsize_24 color_A1A1A1 padding_0_10"  >:</span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="updateMedia" class="course-save pointer">保存</button>
        <button @click="closeDialogEdit" class="course-close pointer">关闭</button>
      </span>
    </fd-dialog>
    <fd-dialog 
      :visible.sync="dialogPreview.visible" 
      :modal-append-to-body="true" 
      :append-to-body="true"
      custom-class="preview-resource-dialog">
        <el-image :src="dialogPreview.url" fit="contain" style="object-fit:contain;height:80vh;width:100%;" lazy ></el-image>
    </fd-dialog>
  </fd-dialog>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import Config from '@/libs/config';
import { getToken } from '@/libs/utils/auth';
import { mapActions } from 'vuex';
import { delMediaFile, updateMediaFile, addMediaTag, getMediaTypeList, uploadSingleFile } from '@/libs/api/media';
import photoEditor from "@/renderer/components/common/photoEditor.vue";

export default {
  props: {
    title: String,
    visible: Boolean,
    fromDetail: Object,
    mediaType: { //媒体资源类型
      type: String,
      default: "all", //
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    cardWidth: {
      type:[String, Number],
      default: "128"
    },
    contentContainerPadding: {
      type:String,
      default: "16px 10px 24px 10px"
    },
    uploadBtnMargin: {
      type:String,
      default: "0"
    },
    exts:{ //文件类型 - 后缀名列表 - 不带点
      type:Array,
      default: function() {
        return [];
      }
    },
  },
  components: {
    photoEditor
  },
  data() {
    return {
      https:process.env.VUE_APP_API,
      token: "",
      keyword:"",
      tagname:"", //选中标签搜索
      tag_name_list:[],
      limit:20,
      page:1,
      total:0,
      uploadFileList:[],
      list:[],
      listIconClass:{
        "picture": "icon-media-image",
        "video": "icon-media-video",
        "audio": "icon-media-audio",
        "other": "icon-media-others",
      },
      checkList:[], //选中媒体
      containerHeight: 0,
      innerHeight: 0,
      previewDetail: {
        url:"",
        name:"",
        filesize_string:"",
        type:""
      },
      delVisible: false, //删除确认弹窗
      editVisible: false, //编辑弹窗
      dialogSet: { 
        title:"编辑媒体",
        actionType:"edit", //edit add
        fieldList: [
          {
            id: 301,
            type: "uploadFile",
            label:"文件",
            uploadtext: "上传文件",
          },
          {
            id: 302,
            type: "text",
            label:"文件名称",
            placeholder: "请输入文件名称",
            propsName:"name"
          },
          {
            id: 303,
            type: "text",
            label:"文件描述",
            placeholder: "请输入文件描述",
            propsName:"description"
          },
          {
            id: 304,
            type: "selectAllowCreate",
            label:"文件标签",
            placeholder: "请输入标签，回车建立标签",
            propsName:"tag_name_list"
          },
          {
            id: 305,
            type: "picture_size",
            label:"尺寸",
            tips: "成比例修改图片大小",
            placeholder: "请输入数值",
            propsName:"size",
            propArr:[
              {
                propsName:"ratioW",
                placeholder: "请输入数值",
              },
              {
                propsName:"ratioH",
                placeholder: "请输入数值",
              }
            ],
          },
          {
            id: 306,
            type: "picture_selectarea",
            label:"选区",
            tips:"输入像素来调整裁剪的选区大小",
            placeholder: "请输入数值",
            propsName:"selectarea",
            propArr:[
              {
                propsName:"width",
                placeholder: "请输入数值",
              },
              {
                propsName:"height",
                placeholder: "请输入数值",
              }
            ],
          }
        ],
      },
      editDetail: {
        url:"",
        name:"",
        description:"",
        tag_name_list:[], //标签数组
      },
      tagList:[], //标签列表 - 原始
      fileTagList:[],
      oldTag:[], //标签选中
      categories: [
        {
          title:'全部',
          type_key:'all'
        },
        {
          title:'图像',
          type_key:'picture'
        },
        {
          title:'音频',
          type_key:'audio'
        },
        {
          title:'视频',
          type_key:'video'
        },
        {
          title:'其他',
          type_key:'other'
        }
      ],
      currentTypeIndex: 0, //当前激活索引
      dialogPreview: {//图片预览
        visible: false,
        url: ""
      },
      uploadList: {
        0:{
          percent:0,
          name:"",
          type:"",
        },
        status: -1, //"loading"、"success" 、"fail"
        percent:0,
        name:"",
        type:"",
        length:0
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization : 'Bearer ' + this.token
      }
    },
    checkListLen() {
      return this.checkList.length || 0;
    },
    currentTypeName() { //当前分类 -
      return this.categories[this.currentTypeIndex].type_key
    },
    fileType() { //可上传文件限制
      // let currentTypeName = this.currentTypeName; //type：all、picture、audio、video、other
      // let acceptObj = {
      //   all : ".doc,.docx,.bmp,.jpg,.png,.jpeg,.mp3,.wma,.rm,.wav,.mid,.ape,.flac,.mp4,.mpg,.mpeg,.avi,.rm,.rmvb,.mov,.wmv,.asf,.dat",
      //   picture : ".bmp,.jpg,.png,.jpeg",
      //   audio : ".mp3,.wma,.rm,.wav,.mid,.ape,.flac",
      //   video : ".mp4,.mpg,.mpeg,.avi,.rm,.rmvb,.mov,.wmv,.asf,.dat",
      //   other : ".doc,.docx",
      // };
      let arr = [];
      if(this.exts && this.exts.length > 0) { //如果参数里有后缀名限定就优先 这个后缀名
        arr = this.exts;
      }else {
        arr = this.categories[this.currentTypeIndex] && this.categories[this.currentTypeIndex].exts || [];
      }
      arr = JSON.parse( JSON.stringify(arr) );
      // 返回数据后缀名+点. 
      arr.forEach((v,i) => arr[i] = "."+v);
      return arr.join(",")
    },
    mediaResourceSetFieldList() {
      if(this.editDetail.type === 'picture') {
        return this.dialogSet.fieldList.filter(v => v.type !== 'uploadFile');
      }else {
        return this.dialogSet.fieldList.filter(v => v.type.indexOf('picture_') === -1)
      }
    },
    listLen() {
      return this.list && this.list.length || 0;
    },
  },
  watch: {
    mediaType: {
      handler(n) {
        if(n) {
          // 匹配默认选中分类
          let targetIndex = this.categories.findIndex(v => v.type_key === n);
          if(targetIndex !== -1) this.currentTypeIndex = targetIndex;
        }
      },
      immediate: true
    }
  },
  async created() {
    this.$nextTick(()=>{
      this.containerHeight = this.$refs.scrollContainer && this.$refs.scrollContainer.clientHeight || 0;
      this.innerHeight = this.$refs.scrollInner && this.$refs.scrollInner.offsetHeight || 0;
    });

    const token = await getToken();
    this.token = token;

    this.keyword="";
    this.tag_name_list=[];
    this.page=1;
    this.list=[];
    this.getList();
    this.getTagList();
    this.getCateList();
  },
  mounted() {
    this.$el.style.backgroundColor = "rgba(0,0,0,0.5)";
  },
  destroyed() {
    // console.log('component destroyed')
  },
  methods: {
    ...mapActions(['GetMediaList','GetMediaTagList']),
    getCateList() { //获取媒体分类
      getMediaTypeList().then(res => {
        if(res.errorcode == 0) {
          this.categories = res.data;
        }
      });
    },
    changeCate(item, index) {
      // 切换媒体分类
      if(this.currentTypeIndex == index) return;
      this.currentTypeIndex = index;
      this.keyword="";
      this.tag_name_list=[];
      this.page=1;
      this.total=0;
      this.getList();
    },
    sureSelect(item) {
      // 确认选中
      this.$emit('select',{item:item,fromDetail:this.fromDetail});
    },
    showDialogEdit(item) {
      // 编辑图片
      // this.$emit('edit', item);
      if(item.tag_name_list) {
        item.tag_name_list = item.tag_list.map(v => v.tag_name);
      }
      this.editDetail = item;
      this.editVisible = true;
    },
    showDialogDel(item) {
      // 删除媒体 图片
      // this.$emit('del', item);
      this.delVisible = true;
    },
    changeSelect(val) {
      this.checkList = val;
    },
    onScroll(event) {
      // 滚动监听
      this.innerHeight = this.$refs.scrollInner.offsetHeight;
      this.containerHeight = this.$refs.scrollContainer.clientHeight;
      let scrollTop = event.target.scrollTop;
      
      // 触底了 触底10px时
      if (Math.ceil(scrollTop + this.containerHeight ) >= this.innerHeight - 10) {
        // this.$emit('loadBottom')
        this.loadData();
      }
    },
    loadData() {
      // 加载下一页
      if(this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getList() {
      // 获取媒体列表
      let params = {
        type: this.currentTypeName,
        keyword: this.keyword,
        tag_name_list: this.tagname,
        limit: this.limit,
        page: this.page
      };
      if(this.exts != undefined && this.exts && this.exts.length > 0) {
        params.exts = JSON.stringify(this.exts);
      }
      this.GetMediaList(params).then(res => {
        if(res.errorcode == 0) {
          this.total = res.data.total || 0;
          res.data.data && res.data.data.map(v => {
            if(v.url && v.url.indexOf('http') == -1) {
              v.url = Config.server + v.url;
            }
            switch(v.type) {
              case "picture":
                v.typeName = "图像";
                break;
              case "video":
                v.typeName = "视频";
                break;
              case "audio":
                v.typeName = "音频";
                break;
              case "other":
              default:
                v.typeName = "其他";
                break;
            }
            return v;
          });
          this.list = this.page == 1 ? (res.data.data || []) : this.list.concat(res.data.data);
          
        } else {
          this.total = -1;
        }
      }).catch(err => {
        this.total = -1;
      });
    },
    getTagList(tag_name) {
      // 获取标签列表
      this.GetMediaTagList(tag_name ? tag_name : '').then(res => {
        if(res.errorcode == 0) {
          this.tagList = res.data.data;
          this.fileTagList = res.data.data;
        }
      }).catch();
    },
    searchMedia() {
      // 通过关键词搜索
      this.page = 1;
      this.getList();
    },
    changeTags() {
      // 标签选中改变
      this.getList();
    },
    tagsVisibleChange() {
      // 标签列表显示隐藏切换
    },
    delMedia(item) {
      // 删除媒体文件
      delMediaFile(item.id).then(res => {
        if(res.errorcode == 0) {
          // 刷新列表
          this.keyword="";
          this.tag_name_list=[];
          this.page=1;
          // this.list=[];
          if( this.checkList.includes(Number(item.id)) || this.checkList.includes(String(item.id)) ) {
            this.checkList = [];
          }
          this.total=0;
          this.getList();
        }
        this.delVisible = false;
        // 关闭右侧预览
        this.previewDetail = {};
      }).catch(()=>{
        this.delVisible = false;
      });
    },
    tapMedia(index, item) {
      // 选中点击
      this.checkList = this.checkList.filter(v => v.id == item.id); //单选控制
      this.previewDetail = item;
    },
    beforeCloseDialog(done, type) {
      if(type === 'del') {
        this.delVisible = false;
      }else if(type === 'edit') {
        this.editVisible = false;
        this.editDetail = {};
        if(this.editDetail.type === 'picture') {
          this.$refs['photo-editor'] && this.$refs['photo-editor'].destroyPhoto();
        }
      }else{
        this.$emit('close');
      }
      done();
    },
    uploadChange(file,fileList) {
      // 文件选择改变
      let { name, uid, percentage, raw:{ type } } = file;
      
      const fileTypeSet = {
        picture: ["png", "jpg", "jpeg", "gif"],
        audio: ["mp3","wav","mpga"],
        video: ["mp4","ogg"],
      };
      const fileTypeName = {
        picture:"图片",
        audio:"音频",
        video:"视频",
      };
      let ext = name.indexOf(".") && name.split(".")[1] || "";
      let fileTypeArr = Object.keys(fileTypeSet);
      let typeIndex = fileTypeArr.findIndex( v => {
        return fileTypeSet[v].indexOf(ext);
      });
      this.uploadList.type = typeIndex != -1 ? fileTypeArr[typeIndex] : "other";
      if( typeIndex != -1 ) {
        this.uploadList.type = fileTypeArr[typeIndex];
        this.uploadList.typeName = fileTypeName[ fileTypeArr[typeIndex] ];
      }else {
        this.uploadList.type = "other";
        this.uploadList.typeName = "其他";
      }

      this.uploadList.name = name;
    },
    uploadSuccess(response, file, fileList) {
      // 上传成功
      if(response.errorcode == 0) {
        // response.data
        this.uploadList.status = "success";
        this.keyword="";
        this.tag_name_list=[];
        this.page=1;
        this.total=0;
        //将当前上传图片选中
        this.checkList = [response.data.id];
        this.previewDetail = response.data;
        this.previewDetail.url = this.previewDetail.url ? this.previewDetail.url : response.data.path; //初始化url,upload返回是path
        this.getList();
      }else{
        this.$message.warning(response.msg);
      }
    },
    uploadProgress(event, file, fileList) {
      let { percent } = event;
      this.uploadList.status = "loading";
      this.uploadList.percent = percent + "%";
    },
    updateMedia() {
      // 编辑媒体文件
      let { id, name, description, tag_name_list } = this.editDetail;
      let params = {
        id,
        name,
        description,
        tag_name_list
      };
      
      if(this.editDetail.type === 'picture') {
        let {isEdit, file} = this.$refs['photo-editor'] && this.$refs['photo-editor'].getPhotoFile();
        if(isEdit && file) {
          //图片编辑时，上传新的
          params.file = file;
          uploadSingleFile(params).then(res => {
            if(res.errorcode == 0) {
              this.editVisible = false;
              this.editDetail = {};
              this.checkList = [res.data.id];
              this.previewDetail = res.data;
              this.previewDetail.url = res.data.url ? res.data.url : res.data.path; //初始化url,upload返回是path
              this.page=1;
              // this.list=[];
              this.getList();
            }
          });
          return;
        }
      }
      
      updateMediaFile(params).then(res => {
        if(res.errorcode == 0) {
          this.editVisible = false;
          this.editDetail = {};
          // this.currentPreview = -1;
          // this.previewDetail = {};
          this.page=1;
          // this.list=[];
          this.getList();
        }
      });
    },
    closeDialogEdit() {
      //关闭 编辑弹窗
      this.editVisible = false;
      this.editDetail = {};
    },
    clearTags(type) { //type: search搜索框、file文件选中标签
      // 清空标签
      this.editDetail.tag_name_list = [];
      if(type === 'file') this.oldTag = [];
      if(type === 'search')  this.tagname = [];
    },
    changeSelectTag(val, prop) {
      // 选中值发生变化
      let newVal = this.editDetail[prop].filter(v => {
        return !this.oldTag.includes(v)
      });

      this.oldTag.push(...newVal);
      newVal.forEach(v => {
        addMediaTag(v).then(res => {
          if(res.errorcode == 0) {
            // this.editDetail.tag_name_list.push(res.data.tag_name);
          }
        })
      } )
    },
    remoteTags(query) {
      // 远程搜索标签
      if(query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetMediaTagList(query).then(res => {
        if(res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    changePic(val, props) {
      // 修改图片选区、宽高比例
      this.editDetail[props] = val;
      this.$forceUpdate();
      if(val < 0) this.editDetail[props] = Math.abs(val);
      if(props === 'ratioW' && this.editDetail['ratioH'] || props === 'ratioH' && this.editDetail['ratioW']) {
        // 修改宽高比
        this.$refs['photo-editor'] && this.$refs['photo-editor'].changePhotoWH({
          ratioW:this.editDetail['ratioW'],
          ratioH:this.editDetail['ratioH'],
        })
      }
      if(props === 'width' || props === 'height' ) {
        // 修改选取宽高
        this.$refs['photo-editor'] && this.$refs['photo-editor'].changePhotoWH({
          width:this.editDetail['width'],
          height:this.editDetail['height'],
        })
      }
    },
    handlePicturePreview() {
      // 预览图片
      if(this.previewDetail.type !== "picture") {
        window.open(this.previewDetail.url,"_blank");
        return;
      }
      this.dialogPreview.url = this.previewDetail.url;
      this.dialogPreview.visible = true;
    },
    cropChange({detail}) {
      // 裁切选区大小改变
      if(detail.width) {
        this.editDetail.width = Number(detail.width).toFixed(2);
      }
      if(detail.height) {
        this.editDetail.height = Number(detail.height).toFixed(2);
      }
    }
  }
}
</script>

<style lang="less" scoped>
  // 媒体选择弹窗
  /deep/.media-select-dialog {
    .el-dialog__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        height: 53px;
        border-bottom: 1px solid #D4D4D4;
    }
    .el-dialog__title {
        padding-left: 16px;
        font-size: 16px;
        color: #222222;
    }
    .el-dialog__headerbtn {
        position: static;
        margin-right: 10px;
        padding: 8px;
        line-height: 1;
        .el-dialog__close {
            font-family: "icomoon" !important;
            font-size: 12px !important;
            color: #999999;
        }
        .el-dialog__close::before {
            content: "\e91d";
        }
    }
    .el-dialog__body {
        padding: 0;
    }
    .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
        color: #999999;
    }
  }

  // 上传按钮
  button.dialog-form-upload {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 18px;
    width: 106px;
    height: 36px;
    line-height: 34px;
    background: #FAFAFA;
  }

  // 媒体列表
  .media-list {
    border-right: 1px solid  #D8D8D8;
    &::-webkit-scrollbar{width:0;height:0} 
  }
  .media-list-item {
    cursor: pointer;
  }
  .media-list-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: transparent;
    box-sizing: border-box;
    padding: 12px 9px 11px;
    width: 128px;
    height: 180px;
    background-color: transparent;
  }
  .media-image {
    object-fit: cover;
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    width: 106px;
    height: 106px;
    display: block;
  }

  // 复选框
  .media-select-checkbox {
    margin-right: 0;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid transparent;
    width: 128px;
    width: var(--card-width);
    height: 180px;
    &:hover {
      border-color: #D8D8D8;
      background-color: #D8D8D8;
    }
    &.is-checked {
      border-color:#E74362;
    }
    /deep/.el-checkbox__input .el-checkbox__inner {
      border-radius: 4px;
      width: 20px;
      height: 20px;
      &::after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border-width: 2px;
      }
    }
    /deep/.el-checkbox__input {
      position: absolute;
      left: 21px;
      top: 21px;
    }
    /deep/.el-checkbox__label {
      padding-left: 0;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #E74362!important;
      border-color: #E74362!important;
    }
    /deep/.el-checkbox__input .el-checkbox__inner,
    /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: transparent;
      background-color: transparent;
    }
  }

  // 右侧预览区域
  .media-preview {
    flex-shrink: 0;
    box-sizing: border-box;
    width: 245px;
    height: 100%;

    .media-preview-inner {
      padding: 0px 24px 0;
    }
    .media-preview-cover-outer {
      &:hover {
        .media-preview-cover-mask {
          opacity: 1;
        }
      }
    }
    .media-preview-cover {
      width: 197px;
      height: 195px;
    }
    .media-preview-cover-mask {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
    }
    button.media-sure,
    button.media-edit,
    button.media-del {
        border-radius: 16px;
        border: none;
        width: 100%;
        height: 32px;
        font-size: 12px;
        color: #FFFFFF;
    }
    button.media-sure {
      border: 1px solid  #E74362;
      background: #E74362;
    }
    button.media-edit {
      border: 1px solid #E74362;
      color: #E74362;
      background-color: #FFFFFF;
    }
    button.media-del {
      border: 1px solid #D4D4D4;
      color: #A1A1A1;
      background-color: #FFFFFF;
    }
  }

  // 搜索框
  .media-tags {
    width:186px;
    height:30px;
    line-height:30px;
    /deep/.el-input__inner {
      height: 30px !important;
    }
    /deep/.el-input {
      height: auto;
      background-color: #fff;
    }
    /deep/.el-icon-arrow-up {
      // display: none;
      display: var(--arrow-down); //根据是否有选中标签控制显示与否
    }
    /deep/.el-input__inner {
      background-color: #EEEEEE;
    }
    /deep/.el-select-dropdown__item {
      position: relative;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      color: #222222;
      padding-right: 0;
      padding-left: 32px;
      > span {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
      }
      .hover,
      &.selected.hover {
        background-color: #EDEDED;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 8px;
        display: block;
        content: "";
        width: calc(100% - 16px);
        height: 1px;
        background-color: #EDEDED;
      }
    }
    /deep/.el-tag {
      // border: 1px solid #E74362;
      border-radius: 4px;
      max-width: 79px;
      height: 22px;
      font-size: 12px;
      color: #fff;
      background-color: #E74362;
      overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
      &:first-child {
        margin-left: 0;
      }
    }
    /deep/.el-tag__close.el-icon-close {
      font-size: 14px;
      color:#E74362;
      background-color: #fff;
    }
    // /deep/.el-select__tags {
    //   margin-left: 36px;
    //   & + .el-input .el-input__inner {
    //     background: url(~@/assets/svg/tag-search.svg) no-repeat center center; //引入icon
    //     background-size: 16px 16px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
    //     background-position: 12px 12px; //在input中定位置  可以调整自定义icon的左右上下位置
    //     padding: 0 0 0 36px; //需要设置padding 把placeholder向右移
    //     box-sizing: border-box;
    //     font-size: 14px;
    //   }
    // }
    // 未被选中
    .el-select-dropdown.is-multiple .el-select-dropdown__item::before {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translate(0,-50%);
      content: "\e99f";
      display: block;
      font-family: 'icomoon';
      color: #d4d4d4;
      font-size: 16px;
    }
    // 被选中select项
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
      position: absolute;
      bottom: 0;
      left: 8px;
      display: block;
      content: "";
      width: calc(100% - 16px);
      height: 1px;
      background-color: #EDEDED;
    }
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::before {
      content: "\e92e";
      color: #e74362;
    }
  }
  .media-tags /deep/.el-select__tags {
    margin-left: 32px;
    & + .el-input .el-input__inner {
      background: url(~@/assets/svg/tag-search.svg) no-repeat center center; //引入icon
      background-size: 16px 16px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
      background-position: 8px center; //在input中定位置  可以调整自定义icon的左右上下位置
      padding: 0 0 0 32px; //需要设置padding 把placeholder向右移
      box-sizing: border-box;
      font-size: 14px;
    }
  }
  
  // 左侧侧边栏
  .media-aside {
    box-sizing: border-box;
    position: relative;
    background: #FAFAFA;
    border-right: 1px solid #D4D4D4;
    overflow: visible;
    .active-right-line {
        position: absolute;
        right: -1px;
        top: var(--line-translateY);
        width: 1px;
        height: 66px;
        background-color: #FFF;
        z-index: 3;
    }
    .tabs_item {
      box-sizing: border-box;
      margin-right: -1px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      width: 100%;
      font-size: 14px;
      color: #A1A1A1;
      line-height: 66px;
      text-align: center;
      &.active {
        border-top-color:#D4D4D4;
        border-bottom-color:#D4D4D4;
        background-color: #FFFFFF;
        &.isFirst {
          border-top-color: transparent;
        }
      }
    }
  }
  // 内容区
  .media-main {
    padding: 16px 0 24px 10px;
    padding: var(--media-main-padding);
    /deep/input::input-placeholder {
      font-size: 12px !important;
    }

    /deep/input::-webkit-input-placeholder {
      font-size: 12px !important;
    }

    /deep/input:-moz-placeholder {
      font-size: 12px !important;
    }

    /deep/input::-moz-placeholder {
      font-size: 12px !important;
    }

    /deep/input:-ms-input-placeholder {
      font-size: 12px !important;
    }
  }
  // 上传按钮
  .upload-btn {
    margin: 0;
    margin: var(--media-upload-btn-margin);
  }
  // 输入框
  /deep/.el-input.is-active .el-input__inner, /deep/.el-input__inner:focus {
    border-color: #D4D4D4;
  }
  // 标签搜索框
  /deep/.el-select .el-input.is-focus .el-input__inner {
    border-color: #D4D4D4;
  }
  .media-edit-input.height36 {
    height: 36px;
    line-height: 36px;
  }
  .media-edit-input.is-disabled {
    line-height: 34px;
    /deep/input {
      height: 34px;
      line-height: 34px;
    }
  }

  .upload-progress-item {
    .media-image {
      background-color: #EDEDED;
      border: 1px solid #D4D4D4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .media-progress {
      display: flex;
      align-items: center;
      position: relative;
      width: 70px;
      height: 6px;
      background-color: #D8D8D8;
      border-radius: 3px;
      .media-progress-inner {
        margin-left: 1px;
        background: #E74362;
        border-radius: 4px;
        height: 4px;
        width: 20%;
      }
    }
  }

  /deep/.cropper-wrap-box {
    background-color: #FFFFFF;
  }
</style>