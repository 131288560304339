export default {
  server: process.env.VUE_APP_API,
  apiversion: process.env.VUE_APP_API_VERSION, //系统api版本
  sidebarSearchDebounceDuration: 550, //课程列表页、媒体列表页，侧边栏搜索防抖时间间隔，单位ms
  realTimeInput: 300, //所有用来实时预览的输入框，节流时间间隔，单位ms
  titleCkeditorCanKeyEnter: true, //标题富文本编辑器能够换行
  apimethod: { //接口method集合
    login: "/api/V1/login",
    getproperties:"/api/V1/properties"
  }
};