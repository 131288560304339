export const lang = {
  home: 'home',
  course: 'Course',
  media: 'Media',
  share: 'Share',
  help: 'Help',
  adduser: 'Add users',
  logout: 'Logout',
  mycourse: 'My Courses',
  createcourse: 'Create a new courses',
  importcourse: 'Import course',
  searchofcoursename: 'Search by course name',
  searchoftag: 'Search by tag',
  communityedition: 'Community Edition',
  upgradenow: 'Upgrade Now',
  updatetime: 'UpdateTime',
  edit: 'Edit',
  changecover: 'Cover',
  rename: 'Rename',
  previewcourseware: 'Preview',
  addlabel: 'Add tags',
  copying: 'Copy',
  delete: 'Delete',
  backhome:'Back homepage',
  globalsetting:'Global setting',
  publish:'Publish',
  preview:'Preview',
  page:'Page',
  theme:'Theme',
  addcontentpage:'Add content',
  addthemepage:'Add submenu',
  style:'Styles',
  completeconditions:'Complete the conditions',
  pickup:'Pick up',
  expand:'Expand',
  medialibrary:'Media library',
  mediaupload:'Upload media',
  image:'Picture',
  audio:'Audio',
  video:'Video',
  others:'Others',
  searchofname: 'Search by name',
  onlinePhotoGallery: 'Online photo library',
  purchase: 'Purchase',
  setstyle: 'Set the style',
  editbookmarks: 'Edit bookmarks',
  setashomepage: 'Set as homepage',
  contentpage: 'Content Pages',
  themepage: 'Sub Menu',
  paymentandorder:"Payment and Order",
  personaldata:"Personal Profile",
  accountsecurity:"Account Security",
  all:"All",
  remind:"Remind",
  notice:"Announcement",
  community:"Community",
  reset:"reset",
  previewing:"Preview",
  move:"move",
  moveup:"move up",
  movedown:"move down",
  managementpage:"Management page",
  numberofcourse:"Number of course",
  spacecapacity:"space capacity",
  upgrade:"upgrade",
  use:"use"

}