import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function getBlockList(course_id, _parentId) { //_parentId : article的ID
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/block/getList?course_id=" + course_id + "&_parentId=" +_parentId
  })
}
  
export function getBlockOne(course_id, _id) { //_parentId : block的ID
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/block/getOne?course_id=" + course_id + "&_id=" +_id
  })
}
  
export function createBlock({course_id, _parentId, title = "组块标题", _layout = "full", below_block_id}) { //_parentId: 父节点，article的ID
  let data = {
    course_id,
    _parentId,
    title,
    _layout,
    below_block_id
  };
  if(!title) {
    delete data.title;
  }
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/block/create",
    data: data
  })
}

export function delBlockOne({course_id, _id}) { //_id: block的ID
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/block/delete",
    data: {
      course_id,
      _id
    }
  })
}

export function moveBlock({course_id, block_id, move_way}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/block/moveBlock?course_id=" + course_id + "&block_id=" +block_id + "&move_way=" +move_way,
  })
}

export function copyBlock({course_id, block_id}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/block/copyBlock?course_id=" + course_id + "&block_id=" +block_id,
  })
}