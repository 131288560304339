<template>
    <el-checkbox v-model="isCheck" @change="changeHandle" 
      class="checkbox-wrapper"
      :style="{ '--checkbox-inner-width':checkboxWidth+'px','--checkbox-inner-height':checkboxHeight+'px', '--scale':scale, '--label-paddingleft':labelPaddingLeft+'px' }">
      <span class="fontsize_14 color_26" :style="{'font-size': labelFontSize + 'px', 'font-weight':'normal'}">{{label}}</span>
    </el-checkbox>
</template>

<script>
export default {
  props: {
    // 绑定key值
    value: {
      type: [Boolean,String],
      default: false
    },
    //显示的label
    label: {
      type: String,
      default: ""
    },
    labelFontSize: {
      type: [Number, String],
      default:14,
      // validator(val) {
      //   return !/\d/.test(val)
      // }
    },
    checkboxWidth:{ //checkbox-inner 宽度
      type: [Number, String],
      default:20,
    },
    checkboxHeight:{ //checkbox-inner 高度
      type: [Number, String],
      default:20,
    },
    scale: {
      type: Number,
      default: 1
    },
    labelPaddingLeft: {
      type: Number,
      default: 8
    },
  },
  data() {
    return {
      isCheck: false,
    };
  },
  watch: {
    value: {
      handler(n,o) {
        this.isCheck = !!n;
      },
      immediate: true
    }
  },
  methods: {
    changeHandle(e) {
      this.$emit('change',e);
    }
  },
}
</script>

<style lang="less" scoped>
  .checkbox-wrapper {
    /deep/.el-checkbox__inner {
      transform: scale( var(--scale) );
      border-radius: 4px;
      border-color: #D4D4D4;
      width: var(--checkbox-inner-width);
      height: var(--checkbox-inner-height);
      background-color: #ffffff;
      &:hover {
        border-color: #D4D4D4;
      }
      &::after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border-width: 2px;
      }
    }

    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #E74362!important;
      border-color: #E74362!important;
    }
    /deep/.el-checkbox__label {
      padding-left: var(--label-paddingleft);
    }
  }
</style>