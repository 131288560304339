<template>
  <div id="app" ref="appcontainer" :style="style">
	<!-- <div id="app-bar" v-if="!is_web">
	  <div id="app-title"  class="flex flex_acenter">
		<img src="@/assets/logo@3x.svg" alt="" @click="$router.push('/home')">
		<span>飞稻设计平台</span>
	  </div>
	  <div id="app-action">
		<div
		  class="app-action-button"
		  @click="win.minimize()"
		>
		  <span class="color_000 fontsize_10">—</span>
		</div>
		<div
		  class="app-action-button"
		  @click="autoMaximize()"
		>
		  <span class="togglefullscreen" :class="{ 'full': !originSize }"></span>
		</div>
		<div
		  class="app-action-button button-red close-app-button"
		  @click="win.close()"
		>
		  <i class="el-icon-close color_000 fontsize_18"></i>
		</div>
	  </div>
	</div> -->
	<!-- <keep-alive :key="$route.fullPath"> -->
	  <router-view></router-view>
	<!-- </keep-alive> -->
	<!-- <keep-alive :key="$route.fullPath" v-if="isCourseEdit">
	  <router-view></router-view>
	</keep-alive>
	<router-view v-else></router-view> -->
	<update-dialog :visible.sync="tipVisible" :hint="hintObj" @action="updateAction"></update-dialog>
  </div>
</template>
<script>
import { appUpdate } from "@/libs/api/others";
import updateDialog from "@/renderer/components/Updater/updateDialog.vue";
const isWeb = !process.env.IS_ELECTRON;
const { remote } = !isWeb ? window.require('electron') : {};
const channelConfig = require('../../channel/channelConfig');
import { mapState } from 'vuex';
export default {
  components:{
	updateDialog
  },
  data() {
	return {
	  style: { 
		// height: '100%',
	  },
	  win: remote && remote.getCurrentWindow() || null,
	  originSize: true,
	  channelConfig:channelConfig,
	  tipVisible: false,
	  hintObj: {
		canDownload:true,
		process:0,
		downloaded:true,
		version:"1.0.0",
		content:"",
	  }
	};
  },
  computed: {
	...mapState({
	  isLoading: state => state.app.loading,
	  is_web: state => state.app.isWebSide,
	  feidaoLanguage: state => state.others.feidaoLanguage
	}),
	isCourseEdit() {
	  return this.$route.fullPath.indexOf("course/edit") > -1;
	}
  },
  watch: {
	$route() {
	  this.$i18n.locale = this.feidaoLanguage == 'zh-CN' ? 'zh' : this.feidaoLanguage;
	}
  },
  created() {
	let body = document.body
	if(!this.is_web && !body.classList.contains('electron_app')) {
	  body.classList.add('electron_app');
	}
  },
  mounted() {
	// this.style.height = document.documentElement.clientHeight + 'px';
	window.onresize = () => {
	  //  this.style.height  = document.documentElement.clientHeight + 'px';

	  // electron:是否最大化
	  if( !this.is_web ) this.listenMaximize();
	};

	// document.addEventListener("click",function( e ){
	//   console.log('document click',e)
	//   // 判断被点击的元素是不是aaa元素，不是的话，就隐藏之
	//   if( e.target !== 'aaa' ){
	//     // aaa.style.display = "none";
	//   }
	// },false)

	this.$electron && this.isUpdate();
	// this.$message.info( "安装包来源："+ this.channelConfig.channel_name+ " 设备唯一id" + this.$VERSION );
   
  },
  methods: {
	updateAction(type) { //仅用于electron
	  // 更新操作
	  let ipcRenderer = this.$electron && this.$electron.ipcRenderer || {};
	  switch(type) {
		case "install":
		  ipcRenderer.send("confirm-update");
		  break;
		case "cancelDownload":
		  ipcRenderer.send("cancel-downloadUpdate");
		  break;
		case "downloadNew":
		  ipcRenderer.send("confirm-downloadUpdate");
		default:
		  break;
	  }
	},
	listenMaximize() {
	  // 是否最大化
	  let isMaximized = this.win.isMaximized();
	  this.originSize = !isMaximized;
	},
	autoMaximize () {
	  if (this.originSize) this.win.maximize()
	  else this.win.restore()
	  this.originSize = !this.originSize
	},
	checkUpdate({content, url, size, version}) {
	  let ipcRenderer = this.$electron && this.$electron.ipcRenderer || {};
	  
	  if(!this.$electron) return;

	  ipcRenderer.on("UpdateMsg", (event, {state, msg}) => {
		// -1:检查更新失败  0:正在检查更新  1:检测到新版本，准备下载  2:未检测到新版本  3:下载中  4:下载完成
		//  state: type,
		//   msg: data || ''
		switch(state) {
		  case -1:
			// 检查更新失败
			break;
		  case 0:
			// 正在检查更新
			break;
		  case 1:
			// 检测到新版本，准备下载
			this.tipVisible = true;
			this.hintObj = {
			  canDownload:true,
			  process:0,
			  downloaded:false,
			  version: msg.version,
			  content: content || "",
			  size: size,
			  url: url
			};
			break;
		  case 2:
			// 现在使用的已经是最新版本
			break;
		  case 3:
			// 注意：“downloadProgress”事件可能存在无法触发的问题，
			// 因为如果安装文件过小的话，很快就下载完成，导致没能达到触发条件。
			this.hintObj.process = (msg.percent).toFixed(2);
			break;
		  case 4:
			// 下载完成
			this.hintObj.downloaded = true;
			break;
		  default:
			break;
		}
	  });

	  // 接收到主进程有新的版本已经下载完成，询问是否更新。
	  ipcRenderer.on("isUpdateNow", () => {
	  });

	  setTimeout(() => {
		// 开始检查是否更新
		ipcRenderer.send("check-update", {feedUrl: url});
	  }, 2000);
	},
	isUpdate() {
	  // 是否检查更新
	  let ipcRenderer = this.$electron && this.$electron.ipcRenderer || {};
	  ipcRenderer.on("return-updateInfo", (event, { type, version }) => {
		appUpdate({type, version }).then(res => {
		  if(res.errorcode == 0) { //0有新版本更新
			let { vision, content, url, size } = res.data;
			this.checkUpdate({
			  content, url, size,
			  version: vision
			});
		  }
		});
	  });
	  ipcRenderer.send("get-updateInfo");
	},
  },
}
</script>
<style>
@import '../assets/css/normalize.css'; /*引入样式*/
@import '../assets/css/iconfont.css'; /* 字体图标 */
</style>
<style lang="less">
@import '../assets/css/root.less'; /*引入公共样式*/
@import '../assets/css/common.less'; /*引入公共样式*/
body,html {
  height: 100%;
  //阻止外部滚动
  // overflow: auto hidden; //由于#app 设置了min-width: 1190px;所以x轴需要滚动条
  overflow: hidden; //由于#app 设置了min-width: 1190px;所以x轴需要滚动条
}
body {
  font-size: 16px;
}
#app {
  box-sizing: border-box;
  height: 100%;
  // min-width: 1190px;
  // min-height: 743px;
}

/deep/.el-popup-parent--hidden {
  padding-right: 0 !important; //electron端重置
}

@courseNavbar: 50px; //.course-navbar
.el-drawer__wrapper {
  top: calc( @courseNavbar );
}
</style>

