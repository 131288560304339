import * as Vue from 'vue'
import axios from 'axios';
import Config from '../config.js';
import { getToken, setToken, removeToken } from '@/libs/utils/auth'
import store from '@/renderer/store';

import { Message } from 'element-ui';
import router from '@/renderer/router'
// Message('这是一条消息提示');

// 登录失败计数
let LoginFailCount = 0;

const _baseUrl = Config.server; //请求域名
// 允许无token的请求url前缀
const permissionUrl = ["/login", "/register","/forget","/lang","/homeapi/sharecourse/preview2"];

//不需要携带token
const notTokenArr = ["/homeapi/sharecourse/preview2"];

// 默认超时设置
axios.defaults.timeout = 50000;

// 相对路径设置
axios.defaults.baseURL ='';
//设置默认请求头
// axios.defaults.headers = {
//     'X-Requested-With': 'XMLHttpRequest'
// }

//http request 拦截器
axios.interceptors.request.use(
  async config => {
    // 获取token
    const token = await getToken();
    let language = store.state.others.feidaoLanguage;

    // url
    if(!config.url.startsWith('http') && (config.url.startsWith('/api/') || config.url.startsWith('/homeapi/'))) {
      if(process.env.IS_ELECTRON) {
        config.url = _baseUrl + config.url;  //跨域代理了
      }else if(process.env.NODE_ENV === 'production'){
        config.url = _baseUrl + config.url;
      }
    }
    // 设置参数格式
    if(!config.headers['Content-Type']){
      config.headers = {
        'Content-Type':'application/json',
      };
    }
    
    let isPermission = permissionUrl.filter(v => config.url.indexOf(v) > -1);
    if(isPermission.length == 0 && !token) {
      router.replace({
        path: '/login'
      })
    }

    // 添加token到headers
    let not_token = notTokenArr.findIndex(v => {
      return config.url && config.url.split("?")[0] == v;
    });
    
    if(token && not_token < 0){
    //   config.headers.Authorization = 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9taW5lLmZlaWRhby1hcGkuY29tXC9hcGlcL1YxXC9sb2dpbiIsImlhdCI6MTYzNzY1MDc2NiwiZXhwIjoxNjQwMjQyNzY2LCJuYmYiOjE2Mzc2NTA3NjYsImp0aSI6IlUxMVhCcHdmN1NvYlBQM28iLCJzdWIiOjEsInBydiI6ImY2NGQ0OGE2Y2VjN2JkZmE3ZmJmODk5NDU0YjQ4OGIzZTQ2MjUyMGEifQ.Nsh4hHZpaGu_1IzNnGtXxrLlPbJRGw6zBPojTwnLY3w' //Bearer 
      config.headers.Authorization = 'Bearer ' + token;
    }
    if(language){
      config.headers['feidao-language'] = language;
    }
    // 鉴权参数设置
    if(config.method === 'get'){
      //get请求下 参数在params中，其他请求在data中
      config.params = config.params || {};
      let json = JSON.parse(JSON.stringify(config.params));
      //一些参数处理
    }else{
      config.data = config.data || {};
      //一些参数处理
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    //一些统一code的返回处理
    if(response.data.errorcode === 501){
      // 登录验证
      //做了个示例跳转项目中登录，并记录下相对路径
      // router.push({
      //     name:'login',//从哪个页面跳转
      //     query:{
      //         retUrl:window.location.href.split('#')[1] || '',
      //         is_new_user_url:1
      //     }
      // })
    }else if( response.data.errorcode >= 100000 ) {
      LoginFailCount ++
      removeToken();
      router.replace({
        path: '/login'
      })
      location.reload()
    }
    if( response.data.errorcode >= 0 && response.data.errorcode < 100000) {
      LoginFailCount = 0;
    }
    return response;
  },
  error => {
    switch (error.response && error.response.status) {
      case 401:
        // 返回 401 清除token信息并跳转到登录页面
        // Message("Unauthorized")
        removeToken();
        router.replace({
          path: '/login'
        })
        location.reload()
    }
    return Promise.reject(error)
  }
);


/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get({url,params={},showMsg = true}){ //showMsg：显示提示信息response.data.msg
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
      .then(response => {
        // console.log('errorcode',response)
        if(response.data.errorcode === 0){
          //返回成功处理  这里传的啥 后续调用的时候 res就是啥
          resolve(response.data);//我们后台所有数据都是放在返回的data里所以这里统一处理了
        }else{
          //错误处理
          resolve(response.data);
          commonShowMsg({showMsg, msg: response.data.msg , LoginFailCount})
        }
      })
      .catch(err => {
        reject(err);
        let message = '请求失败！请检查网络';
        //错误返回
        if(err.response && err.response.data) message=err.response.data.message;
        if(showMsg) {
          Message.closeAll();
          Message(message)
        }
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post({url,data = {}, showMsg = true}){ //showMsg：显示提示信息response.data.msg
  return new Promise((resolve,reject) => {
    axios.post(url,data)
      .then(response => {
        // console.log(response)
        if(response.data.errorcode === 0){
          resolve(response.data);
        }else{
          resolve(response.data);
          commonShowMsg({showMsg, msg: response.data.msg , LoginFailCount})
        }
      },err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if(err.response) message=err.response.message;
        if(showMsg) {
          Message.closeAll();
          Message(message)
        }
      })
  })
}
  
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch({url,data = {}, showMsg = true}){ //showMsg：显示提示信息response.data.msg
  return new Promise((resolve,reject) => {
    axios.patch(url,data)
      .then(response => {
        if(response.data.errorcode === 0){
          resolve(response.data);
        }else{
          resolve(response.data);
          commonShowMsg({showMsg, msg: response.data.msg , LoginFailCount})
        }
      },err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if(err.response) message=err.response.data.message;
        if(showMsg) {
          Message.closeAll();
          Message(message)
        }
      })
  })
}
  
/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put({url,data = {}, showMsg = true}){ //showMsg：显示提示信息response.data.msg
  return new Promise((resolve,reject) => {
    axios.put(url,data)
      .then(response => {
        if(response.data.errorcode === 0){
          resolve(response.data);
        }else{
          resolve(response.data);
          commonShowMsg({showMsg, msg: response.data.msg , LoginFailCount})
        }
      },err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if(err.response) message=err.response.data.message;
        if(showMsg) {
          Message.closeAll();
          Message(message)
        }
      })
  })
}
  
export function del({url,data = {}, showMsg = true}){ //showMsg：显示提示信息response.data.msg
  return new Promise((resolve,reject) => {
    axios.delete(url,data)
      .then(response => {
        if(response.data.errorcode === 0){
          resolve(response.data);
        }else{
          resolve(response.data);
          commonShowMsg({showMsg, msg: response.data.msg , LoginFailCount})
        }
      },err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if(err.response) message=err.response.data.message;
        if(showMsg) {
          Message.closeAll();
          Message(message)
        }
      })
  })
}

function commonShowMsg({ showMsg, msg, LoginFailCount }) {
  if(LoginFailCount <= 1 && showMsg) {
    Message.closeAll();
    Message(msg);
  }
}
  

