import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

//  更新课程标签
export function updateCourseTag({course_id, tag_name_list}) { //tag_name_list: 标签ID数组,传空值则等于清空标签
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/updateCourseTag",
    data: {
      course_id,
      tag_name_list
    }
  })
}

// 分享中心课程列表
export function getShareCourseList({keyword, sort_mode, page, limit, ...args}) {
  let url = "/api/"+ Config.apiversion +"/user/sharecourse/list";
  if(keyword && keyword != undefined && keyword.trim().length > 0) {
    url = url + "?keyword=" + keyword
  }
  if(sort_mode && sort_mode != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&sort_mode=' + sort_mode) : (url + '?sort_mode=' + sort_mode)
  }
  if(page && page != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&page=' + page) : (url + '?page=' + page)
  }
  if(limit && limit != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&limit=' + limit) : (url + '?limit=' + limit)
  }
  if(args) {
    for(let key in args) {
      if(!key || key.indexOf('tag_name_list') == -1) return;
      url = url.indexOf('?') > -1 ? (url + `&${key}=` + args[key]) : (url + `?${key}=` + args[key])
    }
  }
  return request.get({
    url: url
  })
}

// 选择课程
export function getShareCourse({title}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/choose?title=" + title,
  })
}

// 分享设置
export function setShare({if_reset_url,set_type,id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/set",
    data:{
      if_reset_url,
      set_type,
      id,
      open_way,
      code_type,
      code,
      teloremail,
      valid_type,
      valid_time,
      valid_days
    }
  })
}
// 确定分享
export function confirmShare({course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/share",
    data:{
      course_id,
      open_way,
      code_type,
      code,
      teloremail,
      valid_type,
      valid_time,
      valid_days
    }
  })
}
// 设置详情
export function shareDetail(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/detail?id="+id,
  })
}
// 课程内页-设置详情
export function shareCourseDetail(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/shareDetail?course_id="+course_id,
  })
}
// 课程内页-分享设置
export function shareCourseSet({if_reset_url,course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/sharecourse/set",
    data:{
      if_reset_url,
      course_id,
      open_way,
      code_type,
      code,
      teloremail,
      valid_type,
      valid_time,
      valid_days
    }
  })
}
// 导入手机号
export function importMobile({file}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/importMobile",
    data:{
      file
    }
  })
}
// 导入邮箱
export function importEmail({file}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/importEmail",
    data:{
      file
    }
  })
}
// 删除分享
export function deleteShare(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/delete?id="+id,
  })
}
// 预览课程（分享)
export function previewShareCourse(p) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/preview?p="+p,
  })
}

export function getShareCourseOfPreview({ p }) { //code访问码，teloremail手机号或邮箱
  return request.get({
    url: "/homeapi/sharecourse/preview2?p="+p,
    showMsg: false
  })
}

export function putShareCourseOfPreview({p, type, code, teloremail}) { //code访问码，teloremail手机号或邮箱
  return request.post({
    url: "/homeapi/sharecourse/preview2",
    data: {
      p, type, code, teloremail
    },
    showMsg: false
  })
}