

export function getSchemaDefaul(schemaJson) {
  let mapList = {
    "index-index": "course",
    "global-setting": "global",
    "global-left": "global",
    "contentobject-title": "contentobject",
    "content": "content",
    "component-base": "component",
    "block": "block",
    "article": "article",
  };
  let defaultObj = {};
  for(let key in schemaJson) {
    let settting = schemaJson[key].tabgroup_list[0].setting_list;
    // schemaJson[key].tabgroup_list[0]
    // defaultObj[mapList[key]] = schemaJson[key].tabgroup_list[0]
    defaultObj[mapList[key]] = {};
    settting.forEach(v=>{
      if(v['display'] == 1) {
        defaultObj[mapList[key]][v["key_name"]] = v["default"]
      }
    })
  }
  return defaultObj;
}

export function translateDataToTree(data, parentIdName, childIdName) {
  data.forEach(v => {
    v['isFolder'] = v._type == 'menu' ? 'folder' : 'file';
  })
  //没有父节点的数据
  let parents = data.filter(value => value[parentIdName] == 'undefined' || value[parentIdName] == null || value[parentIdName] == 0) ;
    
  //有父节点的数据
  let children = data.filter(value => value[parentIdName] !== 'undefined' && value[parentIdName] != null && value[parentIdName] != 0);
    
  //定义转换方法的具体实现
  let translator = (parents, children) => {
    
    //遍历父节点数据
    parents.forEach((parent) => {
      
      //遍历子节点数据
      children.forEach((current, index) => {
        
        //此时找到父节点对应的一个子节点
        if (current[parentIdName] === parent[childIdName]) {
          
          //对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
          let temp = JSON.parse(JSON.stringify(children))
            
          //让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
          temp.splice(index, 1)
            
          //让当前子节点作为唯一的父节点，去递归查找其对应的子节点
          translator([current], temp)
            
          //把找到子节点放入父节点的children属性中
          typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
          
        }
        if(current['course-_type'] == "course") {
          current['label'] = current['course-displayTitle'];
        } else {
          current['label'] = current['title'];
        }

        if((current._type === 'menu') && !current.children) {
          current.children = [];
        }
      })
      parent['label'] = parent['title'];
    })
    
  }
    
  //调用转换方法
  translator(parents, children)
  //返回最终的结果
  return parents
}

export function navAndOpenTagPage({path,query, params, pageId, isBack}) { // pageId : 可以是课程id，保证唯一性
  // 跳转并打开新的标签页 
  let routeUrl = this.$router.resolve({
    path: path,
    query: query,
    params
  });

  // 当前跳转方式，无法缓存页面; electron使用以下方式跳转回自动关闭窗口
  this.$router.push({
    path: path,
    query: query,
  });
  return;

  // strWindowName === 新窗口的名称 =》 如果已经存在以 strWindowName 为名称的窗口，则不再打开一个新窗口，而是把 strUrl 加载到这个窗口中。
  if(pageId != undefined && pageId) { //pageId：新窗口的名称，
    if(pageId == 'courseList' && !isBack) {
      let courseListWind = window.open(routeUrl.href, '_self');
      courseListWind.name = "courseList";
      return;
    } else if(pageId == 'courseList' && !!isBack) {
      //课程编辑，返回首页，关闭当前页面，显示首页（课程列表页）
      // window.open(routeUrl.href, '_parent'); //等于当前标签
      window.close(); 
      window.open(routeUrl.href, 'courseList');
      return;
    }
    window.open(routeUrl.href, pageId);
  }else {
    window.open(routeUrl.href, '_blank');
  }
}

// 防抖函数
export function debounce(fn, delay = 500, immediate) { //immediate: 是否立即执行-第一次就执行
  let timeout;
  return function() {
    // 如果 timeout == null 说明是第一次，直接执行回调，否则重新计时
    (timeout == null && immediate == true) ? fn.call(this, ...arguments) : clearTimeout(timeout);
    timeout = setTimeout(fn.bind(this), delay, ...arguments);
  }
}

// 节流函数
export function throttle (fn, threshhold = 500, immediate) { //immediate: 是否立即执行-第一次就执行
  let timeout;
  // 计算开始时间
  let start = new Date();
  return function () {
    // 触发时间
    const current = new Date() - 0;
    (timeout == null && immediate == true) ? fn.call(this, ...arguments) : clearTimeout(timeout);
    // 如果到了时间间隔点，就执行一次回调
    if (current - start >= threshhold) {
      fn.call(this, ...arguments);
      // 更新开始时间
      start = current;
    } else {
      // 保证方法在脱离事件以后还会执行一次
      timeout = setTimeout(fn.bind(this), threshhold, ...arguments);
    }
  }
}

// url转file
export function dataURLtoFile(dataurl, filename = 'file') {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {type: mime})
}

// 附加style到head
export function appendStyle({document, style, id = "customStyleOne"}) {
  let styleStr = style;
  // 附加自定义css到页面
  let hasAppend = document.getElementById(id);
  if(hasAppend) {
    // 已经添加过css
    hasAppend.innerHTML=styleStr;
    return;
  }
  
  // 未添加过css
  if(document.all){ 
    window.style=styleStr; 
    document.createStyleSheet("javascript:style"); 
  }else{ 
    let style = document.createElement('style');
    style.type = 'text/css'; 
    style.id = id;
    style.innerHTML=styleStr;
    document.getElementsByTagName('HEAD').item(0).appendChild(style);
  }
}

// 获取各页面所在目录树层级
export function getTreeLayer({data, children = "children"}) {
  const layerSet = {
    current:0,

  };
  let getChild = (parent, ) => {
    if( !parent ) return null;
    let child = parent[children];
    if( child && Array.isArray( child ) ) return child;
    return null;
  };
  let getData = ( info ) => {
    let current = getChild(info);
    if(current) {
      layerSet.current++;
      
      current.forEach( ( item ) => {
        // 记录当前页面/主题 位于第几层
        layerSet[item._id] = layerSet.current;
        getData( item );
      } );
    }
  };
  // 获取
  if(data && Array.isArray( data )) {
    data.forEach( v => {
      getData(v);
    } );
  }else if( data ){
    getData(data);
  }
  
  return layerSet;
}

export function isElectron() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
  }

  // // Main process
  // if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
  //     return true;
  // }

  // // Detect the user agent when the `nodeIntegration` option is set to true
  // if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
  //     return true;
  // }

  return false;
}


