import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function login(mobile, password) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/login",
    data: {
      mobile,
      password
    }
  })
}

export function getInfo(token) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/userinfo",
    // params: { token }
  })
}

export function logout() {
  return request.post({
    url: "/api/"+ Config.apiversion +"/logout"
  })
}

// 密码登陆
export function loginWithPwd({account, password}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/login/password",
    data: {
      account,
      password
    },
    showMsg:false
  })
}
// 短信登陆
export function loginWithShortMsg({mobile, verification_code}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/login/mobile",
    data: {
      mobile,
      verification_code
    },
    showMsg:false
  })
}
// 发送手机登录验证码
export function sendOfLoginMobileCode(mobile) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/login/sendMobileCode?mobile="+mobile,
  })
}

// 获取微信登录或注册二维码
export function getWechatQrCode(mobile) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/login/wechatQrCode",
    showMsg: false
  })
}

// 绑定微信-获取绑定二维码
export function bindWechatQrCode() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/wechat/bind/wechatQrCode",
    showMsg: false
  })
}

// 解除绑定微信
export function unbindWechatQrCode({verification_mode, emial, mobile, verification_code, token_string} = {}) {
  let url = "/api/"+ Config.apiversion +"/user/wechat/unbind";
  return request.get({
    url: url,
    showMsg: false
  })
}

// 检测微信二维码扫码结果
export function checkSceneStr(scene_str) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/login/checkSceneStr?scene_str="+scene_str,
    showMsg: false,
  })
}

// 检测绑定微信二维码扫码结果
export function checkBindSceneStr(scene_str) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/wechat/bind/checkSceneStr?scene_str="+scene_str,
    showMsg: false,
  })
}

// 手机注册
export function registerWithMobile({mobile, verification_code, password, password_confirmation, registerfrom,secretstr	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/register/mobile",
    data: {
      mobile,
      verification_code,
      password,
      password_confirmation,
      registerfrom,
      secretstr
    },
    showMsg: false,
  })
}

// 发送手机注册验证码
export function sendMobileCodeOfRegister(mobile) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/register/sendMobileCode?mobile="+mobile,
  })
}

// 邮箱注册-提交
export function registerWithEmail({email, verification_code, password, password_confirmation, registerfrom	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/register/email",
    data: {
      email,
      verification_code,
      password,
      password_confirmation,
      registerfrom
    },
    showMsg: false,
  })
}

// 发送邮箱注册验证码
export function sendEmailCodeOfRegister(email) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/register/sendEmailCode?email="+email,
  })
}

// 发送邮箱登录验证码
export function sendEmailCodeOfLogin(email) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/login/sendEmailCode?email="+email,
    showMsg: false,
  })
}

// 找回密码 - 邮箱找回
export function forgetCodeWithEmail({email, verification_code,  	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/forget/email",
    data: {
      email,
      verification_code
    },
    showMsg: false,
  })
}

// 找回密码 - 手机号找回
export function forgetCodeWithPhone({mobile, verification_code,  	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/forget/mobile",
    data: {
      mobile,
      verification_code
    },
    showMsg: false,
  })
}

// 找回密码 - 设置新密码
export function setNewPwd({forget_type, email, mobile, password, password_confirmation, forget_token 	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/forget/setNewPassword",
    data: {
      forget_type,
      email,
      mobile,
      password,
      password_confirmation,
      forget_token
    }
  })
}

// 获取用户详细信息
export function getUserInfo() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/userinfo",
  })
}

// 更新个人资料
export function updateUserInfo({username, birthday, profession, city, personal_profile, avatar 	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/userinfo/update",
    data: {
      username,
      birthday,
      profession,
      city,
      personal_profile,
      avatar
    },
    showMsg: false,
  })
}

// 获取用户账号安全信息
export function getSecurityInformation() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/securityInformation",
  })
}

// 当前账号绑定手机号码验证
export function verifyMobile({mobile, verification_code	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/mobile/verification",
    data: {
      mobile,
      verification_code
    },
    showMsg: false,
  })
}

// 更换绑定手机号码（或者绑定手机）
export function changeBindMobile({mobile, verification_code, token_string	}) { //token_string:如果当前账号已经绑定手机号码，则此值必传
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/mobile/changeBindMobile",
    data: {
      mobile,
      verification_code,
      token_string
    },
    showMsg: false,
  })
}

// 当前账号绑定邮箱验证
export function verifyEmail({email, verification_code	}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/email/verification",
    data: {
      email,
      verification_code
    },
    showMsg: false,
  })
}

// 更换绑定手机号码（或者绑定手机）
export function changeBindEmail({email, verification_code, token_string	}) { //token_string:如果当前账号已经绑定手机号码，则此值必传
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/email/changeBindEmail",
    data: {
      email,
      verification_code,
      token_string
    },
    showMsg: false,
  })
}

// 设置密码
export function changePassword({type, password, password_confirmation, token_string	}) { //token_string:如果当前账号已经绑定手机号码/邮箱，则此值必传
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/password/setPassword",
    data: {
      type,
      password,
      password_confirmation,
      token_string
    },
    showMsg: false,
  })
}
// 激活码升级vip
export function UpgradeVip(code) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/useLicenceKey?key="+code,
    showMsg: false,
  })
}
export function ApiKey() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/getApiKey",
    showMsg: false,
  })
}
// ApiKey重置
export function ResetKey() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/resetAppSecret",
    showMsg: false,
  })
}
