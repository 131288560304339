<template>
  <div class="app-wrapper" :class="classObj">
    
    <!-- <sidebar class="sidebar-container" :style="siderbarStyle"></sidebar> -->
    
    <div class="main-container" v-if="is_web">
      <!-- <navbar></navbar> -->
      <app-main></app-main>
    </div>
    <router-tab  v-if="!is_web" />
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  computed: {
    is_web() {
      return !process.env.IS_ELECTRON;
    },
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    siderbarStyle() {
      return {
        top: !process.env.IS_ELECTRON == 'true' ? 0 : '40px'
      }
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  }
}
</script>

<style  lang="less" scoped>
  @import "../../../assets/css/mixin.less";
  .app-wrapper {
    // @include clearfix;
    position: relative;
    height: calc(100% - 40px);
    width: 100%;
  }
  /deep/.router-tab__header {
    position: fixed;
    top: 0;
    margin-left: 150px;
    z-index: 99;
    -webkit-app-region: no-drag;
  }
  /deep/.router-tab__item.is-active {
    background-color: #f7f7f9;
  }
  /deep/.router-tab__container {
    // margin-left: 180px;
  }
  .hideSidebar /deep/.router-tab__container {
    // margin-left: 0;
  }
  // 不显示首页的关闭
  /deep/span[title='Home'].router-tab__item-title + .router-tab__item-close{
    display: none;
  }
</style>
