<template>
  <div class="ace-container" :style="{aceWrapStyle}">
      <div class="ace-editor" ref="ace"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: 215
    }
  },
  data() {
    return {
      aceEditor: null,
      themePath: 'ace/theme/chrome', // 不导入 webpack-resolver，该模块路径会报错
      modePath: 'ace/mode/less', // 同上
      codeValue: this.value,
    };
  },
  computed: {
    aceWrapStyle() {
      return {
        width: this.px(this.width),
        height: this.px(this.height)
      };
    }
  },
  watch: {
    value(newVal) {
      // 设置内容
      if(this.codeValue !== newVal) {
        this.aceEditor.setValue(newVal);
        this.codeValue = newVal;
      }
    },
    height:function(){
      this.$nextTick(function(){
        this.aceEditor.resize()
      });
    },
    width:function(){
      this.$nextTick(function(){
        this.aceEditor.resize()
      });
    }
  },
  created() {
    this.$nextTick(() => {
      this.initCodeEidtor();
    });
  },
  beforeDestroy() {
    this.aceEditor.destroy();
    this.aceEditor.container.remove();
  },
  methods: {
    initCodeEidtor() {
      this.aceEditor = ace.edit(this.$refs.ace, {
        maxLines: 12, // 最大行数，超过会自动出现滚动条
        minLines: 7, // 最小行数，还未到最大行数时，编辑器会自动伸缩大小
        fontSize: 12, // 编辑器内字体大小
        theme: this.themePath, // 默认设置的主题
        mode: this.modePath, // 默认设置的语言模式
        tabSize: 4, // 制表符设置为 4 个空格大小
        // readOnly: true, //只读
        highlightActiveLine: false, //不高亮当前行
        showGutter:false, //是否显示左侧行数
        value: this.codeValue
      });
      this.aceEditor.on('change',() => {
        var content = this.aceEditor.getValue();
        this.$emit('input',content);
        this.codeValue = content;
      });
      this.aceEditor.on('blur',() => {
        var content = this.aceEditor.getValue();
        this.$emit('blur',content);
      });
    },
    getVal() {
      // 获取内容
      return this.aceEditor.getValue();
    },
    px:function (n) {
      if( /^\d*$/.test(n) ){
        return n+"px";
      }
      return n;
    }
  },
}
</script>

<style lang="less" scoped>
    .ace-editor {
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        width: 100%;
        height: 100%;
        line-height: 22px;
        background: #FFFFFF;
        // /deep/.ace_gutter {
        //     display: none;
        // }
        // /deep/.ace_scroller {
        //     left: 0 !important;
        // }
        // /deep/.ace_active-line {
        //     // display: none;
        // }
    }
</style>