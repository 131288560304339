<template>
  <button type="button" class="custom-btn " :class="{'pointer': !disabled,'fd-disabled':disabled}" :style="getStyle()" @click.stop="tapButton">
    <slot />
  </button>
</template>

<script>
// type="button" 防止在表单里回车触发submit
export default {
  props: {
    // 是否朴素按钮 -朴素按钮背景色为白色
    plain: {
      type: Boolean,
      default: false
    },
    // 按钮圆角
    roundPX: {
      type: [Number,String],
      default: -1
    },
    // 背景色、边框色、字体颜色
    color: {
      type: String,
      default: '#1E9C7B'
    },
    width: {
      type:[Number,String],
      default: "64"
    },
    height: [Number,String],
    // hover时 背景色
    hoverBgColor: {
      type: String,
      default: ''
    },
    // hover时 边框色
    hoverBorderColor: {
      type: String,
      default: ''
    },
    borderWidth:{
      type:[Number,String],
      default: "1"
    },
    // hover时 边框宽度
    hoverBorderWidth:{
      type:[Number,String],
      default: "1"
    },
    padding: String,
    disabled: Boolean, //禁用状态
    fontSize:{
      type:Number,
      default: 12
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    tapButton() {
      if(this.disabled) return;
      this.$emit("click");
    },
    getStyle() {
      let styleSet = {};
      let {fontSize, plain, roundPX, color, width, height, padding, hoverBgColor, hoverBorderColor, borderWidth, hoverBorderWidth} = this;
      if(fontSize) {
        styleSet['font-size'] = fontSize + 'px';
      }
      if(color && !plain) {
        // 有背景色按钮
        styleSet['background-color'] = color;
        styleSet['border-color'] = color;
      }
      if(color && plain) {
        // 朴素按钮
        styleSet['background-color'] = '#fff';
        styleSet['border-color'] = color;
        styleSet['color'] = color;
      }
      if(roundPX >= 0 && !/\D/.test(roundPX)) {
        styleSet['border-radius'] = roundPX + 'px';
      }
      if(padding) {
        styleSet['padding'] = padding;
      }
      if(width && !/\D/.test(width)) { //数字 - px
        styleSet['width'] = width + 'px';
      }else if( width && /^\d+[.]?\d+%$/.test(width) ) { // 百分比 
        styleSet['width'] = width;
      }
      if(height && !/\D/.test(height)) {
        styleSet['height'] = height + 'px';
        styleSet['line-height'] = (Number(height)-borderWidth*2) + 'px';
        styleSet['--hover-line-height'] = styleSet['line-height'];
      }
      if(hoverBorderWidth && !/\D/.test(hoverBorderWidth)) {
        styleSet['--hover-border-width'] = hoverBorderWidth +'px';
        if(height) styleSet['--hover-line-height'] = (Number(height)-hoverBorderWidth*2) + 'px';
      }else {
        styleSet['--hover-border-width'] = borderWidth +'px';
      }

      if(hoverBgColor) {
        if(hoverBorderColor) {
          styleSet['--hover-border-color'] = hoverBorderColor;
        }else {
          color && !plain ? styleSet['--hover-border-color'] = hoverBgColor : void(0);
          color && plain ? styleSet['--hover-border-color'] = color : void(0);
        }
        
        styleSet['--hover-bg-color'] = hoverBgColor;
      }else {
        if(hoverBorderColor) {
          styleSet['--hover-border-color'] = hoverBorderColor;
        }else {
          color && !plain ? styleSet['--hover-border-color'] = styleSet['background-color'] : void(0);
          color && plain ? styleSet['--hover-border-color'] = color : void(0);
        }
        styleSet['--hover-bg-color'] = styleSet['background-color'];
      }
      return styleSet;
    },
  },
}
</script>

<style lang="less" scoped>
  .custom-btn {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    word-break: break-all;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid #1E9C7B;
    
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    padding: 0;
    background: #1E9C7B;
    transition: background 0.3s;
    &:hover {
      border-color: var(--hover-border-color)!important;
      background-color: var(--hover-bg-color)!important;
      border-width: var(--hover-border-width)!important;
      line-height: var(--hover-line-height, 30px)!important;
    }
  }
  .fd-disabled{
    background: #bfbfbf !important;
    color: #fff !important;
    &:hover{
      background: #bfbfbf !important;
      }
    span{
      color: #fff !important;
      
    }
  }
</style>