<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy" >
    <div
      class="el-color-dropdown"
      v-show="showPopper"
      @mouseenter="enterDropdown">
      <div class="el-color-dropdown__main-wrapper">
        <hue-slider ref="hue" :color="color" vertical style="float: right;"></hue-slider>
        <sv-panel ref="sl" :color="color"></sv-panel>
      </div>
      <alpha-slider v-if="showAlpha" ref="alpha" :color="color"></alpha-slider>
      <predefine v-if="predefine" :color="color" :colors="predefine"></predefine>
      <div class="el-color-dropdown__btns">
        <span class="el-color-dropdown__value" :class="{'notHex':!isHex}">
          <el-input
            v-model="customInput"
            @keyup.native.enter="handleConfirm"
            @blur="handleConfirm"
            :validate-event="false"
            size="mini">
            <span v-if="isHex" slot="prefix" style="font-style:normal;color:#333;">#</span>
          </el-input>
        </span>
        <el-button
          size="mini"
          type="text"
          class="el-color-dropdown__link-btn"
          @click="$emit('clear')">
          {{ t('el.colorpicker.clear') }}
        </el-button>
        <!-- <el-button
          size="mini"
          type="text"
          class="el-color-dropdown__link-btn"
          @click="$emit('initDefault')">
          {{ $t('lang.reset') }}
        </el-button> -->
        <el-button
          plain
          size="mini"
          class="el-color-dropdown__btn"
          @click="confirmValue">
          {{ t('el.colorpicker.confirm') }}
        </el-button>
      </div>
    </div>
  </transition>
</template>

<script>
  // 当前默认固定#，如果输入#失去焦点就去掉
  import SvPanel from './sv-panel';
  import HueSlider from './hue-slider';
  import AlphaSlider from './alpha-slider';
  import Predefine from './predefine';
  import Popper from 'element-ui/src/utils/vue-popper';
  import Locale from 'element-ui/src/mixins/locale';
  import ElInput from 'element-ui/packages/input';
  import ElButton from 'element-ui/packages/button';

  export default {
    name: 'el-color-picker-dropdown',

    mixins: [Popper, Locale],

    components: {
      SvPanel,
      HueSlider,
      AlphaSlider,
      ElInput,
      ElButton,
      Predefine
    },

    props: {
      color: {
        required: true
      },
      showAlpha: Boolean,
      predefine: Array
    },

    data() {
      return {
        customInput: '',
      };
    },

    computed: {
      isHex() {
        // 是否hex值
        let customInput = this.customInput;
        let colorType = ["rgba","rgb","hsv","hsva","hsl","hsla"];

        if(!customInput) return true; //空值当做hex处理

        let isNotHex = colorType.findIndex(v => customInput.indexOf(v) != -1);
        return isNotHex === -1; //是否hex值
      },
      currentColor() {
        const parent = this.$parent;
        return !parent.value && !parent.showPanelColor ? '' : parent.color.value;
      },
      fullInput() {
        let customInput = this.customInput;
        let colorType = ["rgba","rgb","hsv","hsva","hsl","hsla"];

        if(!customInput) return customInput;

        // 非hex
        let isNotHex = colorType.findIndex(v => customInput.indexOf(v) != -1);
        if(isNotHex != -1) return customInput;

        return "#" +  customInput.replace(/#/g, '')
      },
    },

    methods: {
      enterDropdown(event) {
        this.$emit('mouseenterColorPick', event);
      },

      confirmValue() {
        this.$emit('pick');
      },

      handleConfirm() {
        this.color.fromString(this.fullInput);

        if(this.color.value !== this.fullInput) { //非法输入时
          this.customInput = this.color.value.replace('#', '');
        }else {
          this.customInput = this.customInput.replace('#', '');
        }
      }
    },

    mounted() {
      this.$parent.popperElm = this.popperElm = this.$el;
      this.referenceElm = this.$parent.$el;
    },

    watch: {
      showPopper(val) {
        if (val === true) {
          this.$nextTick(() => {
            const { sl, hue, alpha } = this.$refs;
            sl && sl.update();
            hue && hue.update();
            alpha && alpha.update();
          });
        }
      },

      currentColor: {
        immediate: true,
        handler(val) {
          this.customInput = val.replace('#', '');
        }
      },
    }
  };
</script>
