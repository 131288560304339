<template>
  <div class="login-container">
    <div class="login-inner">
      <img src="@/assets/other/login_bg_center.png" class="login-inner-bg"/>
      <img src="@/assets/logoWithText.png" class="logo"/>
      <div class="login-left">
      </div>
      <div class="login-form-wrapper">
        <p class="fontsize_24 color_222 fontweight_600 margin_bot16">{{currentData.title}}</p>
        <p class="fontsize_16 margin_bot32"><span class="color_222">{{currentData.tips1}}</span><span class="color_1373E6 pointer hoverColor_126AD4" @click="tapRegister">{{currentData.tips2}}</span></p>
        <el-form @submit.native.prevent :model="ruleForm" status-icon :rules="rules" :show-message="false" ref="ruleForm" label-width="120px" label-position="left" class="login-form">
            <el-form-item :prop="item.props" label-width="0" v-for="(item) in currentData[mode]" :key="item.name">
              <!-- :autocomplete="item.props =='pass' ? 'new-password':'on'"  -->
              <el-input :type="getInputType(item.inputType)" 
                @keyup.enter.native="keyEnter($event, item)"
                v-model="ruleForm[item.props]" 
                :placeholder="item.placeholder" class="login-input-wrapper">
                <span  v-if="item.propName === 'mobileCode' && !mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_E74362 pointer valid-code-btn hoverColor_CC3350" :class="{'is-disabled':!ruleForm.username}" @click="getMobileCode">获取验证码</span>
                <span  v-else-if="item.propName === 'mobileCode' && mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_A1A1A1 pointer valid-code-btn">重新获取(<i style="font-style: normal;">{{mobileCodeData.countdownTime}}</i>)</span>
              </el-input>
              <div class="input-error-msg" v-if="errMsgSet[item.props]">
                <i class="icon-warning fontsize_12"></i><span class="padding_left8 color_E74362 fontsize_12">{{errMsgSet[item.props]}}</span>
              </div>
            </el-form-item>
            <div v-if="mode === 'account'" class="flex flex_acenter flex_jcbetween margin_bot24">
              <checkBox key="showpass" 
                :value="isShowPass" :label="'显示密码'" :labelFontSize="12" :scale="0.6" :labelPaddingLeft="5"
                @change="changeShowPass" class="show-pwd-checkbox">
              </checkBox>
              <span class="fontsize_12 color_6E6E6E pointer hoverColor_E74362" @click="tapForget">忘记密码</span>
            </div>
            <div v-if="mode === 'wechat'" class="wechat-wrapper">
              <div class="wechat-code-wrapper">
                <img @click="tapWechatCode" :src="wechatData.img" alt="" class="wechat-code-img">
              </div>
              <p class="textcenter flex flex_acenter">
                <i class="icon-wechat fontsize_16 icon_color_0AC061"></i>
                <span class="fontsize_14 color_222 padding_left4">扫码关注公众号完成登录</span>
              </p>
            </div>
            <customButton  v-if="mode !== 'wechat'" @click="submitForm('ruleForm')" color="#E74362" hoverBgColor="#CC3350" width="100%" :roundPX="4" :height="48"><span class="fontsize_14">{{currentData.submitBtn.name}}</span></customButton>
        </el-form>
        <el-row class="other-mode-title textcenter" v-if="type === 'login'">
          <div class="fontsize_12 color_222">{{currentData.modeTitle}}</div>
        </el-row>
        <el-row class="flex" :gutter="19" v-if="type === 'login'">
          <el-col v-for="(oitem) in otherModes" :key="oitem.type" >
            <customButton @click="changeMode(oitem.type)" plain color="#D4D4D4" hoverBorderColor="#E74362" :hoverBorderWidth="1" width="100%" :roundPX="4" :height="43">
              <i :class="[oitem.iconClassName, { 'icon_color_0AC061': oitem.type === 'wechat' }]" class="fontsize_14"></i><span class="fontsize_12 color_222 margin_left8">{{oitem.title}}</span>
            </customButton>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import { loginApi } from '@/libs/request/api.js';
import { sendOfLoginMobileCode, getWechatQrCode, checkSceneStr } from '@/libs/api/login'
import { mapActions } from 'vuex'
import customButton from "@/renderer/components/common/button.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  components: {
    customButton,
    checkBox
  },
  data() {
    var validateUserName = (rule, value, callback) => {
      let errorMsgObj = {
        "account":"手机号或邮箱不正确",
        "shortMessage":"手机号格式不正确",
      };
      if (value === '') {
        callback(new Error('账号格式不正确'));
        // this.errMsgSet[rule.field] = errorMsgObj[this.mode];
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validatePass = (rule, value, callback) => {
      let errorMsgObj = {
        "account":"密码不正确",
        "shortMessage":"验证码不正确",
      };
      if (value === '') {
        callback(new Error('密码不正确'));
        // this.errMsgSet[rule.field] = errorMsgObj[this.mode];
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    return {
      type:"login",
      mode:"account", // shortMessage 、wechat
      isShowPass:false, //是否显示密码
      mobileCodeData: {
        hasCode: false,
        timer:null,
        countdownTime: 30, //倒计时s
        requesting:false, //请求中
      },
      wechatData: {
        img:"", //二维码
        codeDetail:{},
        timer: null, //定时检测是否扫码,
        duration:2000, //ms = 1s，
      },
      contentData: {
        login: {
          title: "登录",
          tips1:"新用户？",
          tips2:"创建账号",
          modeTitle:"其他登录方式",
          modeArr:[
            {
              type:"account",
              title:"账号登录",
              iconClassName:"icon-account-login"
            },
            {
              type:"shortMessage",
              title:"短信登录",
              iconClassName:"icon-short-note"
            },
            {
              type:"wechat",
              title:"微信登录",
              iconClassName:"icon-wechat"
            }
          ],
          account:[
            {
              props:"username", //绑定字段
              inputType:"text",
              propName:"account", //
              name:"手机号或邮箱",
              placeholder:"手机号或邮箱",
            },
            {
              props:"pass",
              inputType:"password",
              propName:"password", //
              name: "密码",
              placeholder:"密码"
            }
          ],
          shortMessage: [
            {
              props:"username",
              inputType:"phone",
              propName:"phone", //
              name: "手机号",
              placeholder:"手机号",
            },
            {
              props:"pass",
              inputType:"text",
              propName:"mobileCode", //
              name: "验证码",
              placeholder:"验证码",
            }
          ],
          wechat: {

          },
          validcode: {
            name:"验证码",
            btnText:"获取验证码"
          },
          password: {
            name: "密码",
            placeholder:"密码"
          },
          rePassword: {
            name: "再次确认",
          },
          showPassword: {
            name:"显示确认密码",
            form_type:"checkbox"
          },
          submitBtn: {
            name:"登录",
            type:"login"
          }
        },
      },
      ruleForm: {
        username: '',//18819435180
        pass: '',//Ld800307
      },
      errMsgSet: {
        username:"",
        pass: "",
      },
      rules: {
        username: [
          { validator: validateUserName, trigger: 'submit' }
        ],
        pass: [
          { validator: validatePass, trigger: 'submit' }
        ],
      },
      propMap: { //校验filed
        password: "pass",
        account: "username",
        mobile: "username",
        verification_code: "pass",
      },
    };
  },
  computed: {
    currentData() {
      return this.contentData[this.type];
    },
    otherModes() { //其他登录方式-注册方式
      return this.type === 'login' && this.currentData.modeArr.filter(v => v.type !== this.mode) || [];
    },
  },
  beforeDestroy() {
    this.wechatData.timer && clearInterval(this.wechatData.timer);
    this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
  },
  methods: {
    ...mapActions([
      'Login',
      'GetUserInfo',
      'LoginWithPwd',
      'LoginWithShortMsg',
      'SaveToken'
    ]),
    keyEnter(event, item) {
      let { propName } = item;
      if(propName === "password") {
        // 自动触发登录
        this.submitForm('ruleForm');
      }
    },
    tapRegister() {
      // 跳转注册
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      this.$router.push({name:"Register"});
    },
    tapForget() {
      // 跳转注册
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      this.$router.push({name:"Forget"});
    },
    submitForm(formName) {
      // 重置错误信息
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");
      
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.putLogin();
        } else {
          console.log('error submit!!');
          Object.keys(obj).forEach(v => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    putLogin(args) {
      switch(this.mode) {
        case "account":
          this.LoginWithPwd({ username:this.ruleForm.username, password: this.ruleForm.pass }).then( (res=>{
            if(res && res.errorcode == 0) {
              //获取用户信息
              this.GetUserInfo();
              this.$router.push({name:'Home'});
              //以下代码不适合 electron，会导致窗口关闭
              // window.close();
              // this.$navBlank({
              //   path: '/home',
              //   pageId: "courseList"
              // });
            }else if(res.errorcode == 1 && res.data){
              // 错误提示
              Object.keys(res.data).forEach(v => {
                this.errMsgSet[this.propMap[v]] = res.data[v][0];
              });
            }
          })).catch((err)=>{});
          break;
        case "shortMessage":
          this.LoginWithShortMsg({ username:this.ruleForm.username, password: this.ruleForm.pass }).then( (res=>{
            if(res && res.errorcode == 0) {
              //获取用户信息
              this.GetUserInfo();
              this.$router.push({name:'Home'});
              //以下代码不适合 electron，会导致窗口关闭
              // window.close();
              // this.$navBlank({
              //   path: '/home',
              //   pageId: "courseList"
              // });
            }else if(res.errorcode == 1 && res.data){
              // 错误提示
              Object.keys(res.data).forEach(v => {
                this.errMsgSet[this.propMap[v]] = res.data[v][0];
              });
            }
          })).catch((err)=>{});
          break;
      }
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");
    },
    changeShowPass(value) {
      // 切换-是否显示密码
      this.isShowPass = value;
    },
    getInputType(type) {
      // 输入框类型
      let mode = this.mode;
      // 账号模式-显示密码时，密码框改为文本框
      if(mode === "account" && this.isShowPass) return "text";
      return type;
    },
    changeMode(mode) {
      // 改变登录/注册方式
      this.mode = mode;
      // 重置表单
      this.resetForm("ruleForm");

      this.wechatData.timer && clearInterval(this.wechatData.timer);
      if(this.mode === 'wechat') {
        // 获取微信二维码
        this.tapWechatCode();
      }
    },
    getMobileCode() {
      // 获取验证码
      let mobile = this.ruleForm.username;
      // 请求中
      if(this.mobileCodeData.requesting) return;
      // 倒计时中
      if(this.mobileCodeData.hasCode) return;
      if(!mobile) {
        this.$message.warning("请输入手机号！");
        return;
      }
      this.mobileCodeData.requesting = true;
      this.mobileCodeData.hasCode = true;
      // 倒计时
      this.mobileCodeData.countdownTime --;
      this.mobileCodeData.timer = setInterval(()=>{
        if(this.mobileCodeData.countdownTime <= 1) {
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.hasCode = false;
          this.mobileCodeData.countdownTime = 30;
        }
        this.mobileCodeData.countdownTime --;
      },1000)
      // 发送验证码
      sendOfLoginMobileCode(mobile).then(res => {
        this.mobileCodeData.requesting = false;
        if(res.errorcode == 0) {
          this.$message.success(res.msg);
        }else {
          // this.$message.warning(res.msg);
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      }).catch(()=>{
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      });
    },
    tapWechatCode() {
      // 获取微信二维码
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      getWechatQrCode().then((res) => {
        if(res.errorcode == 0) {
          this.wechatData.codeDetail = res.data;
          this.wechatData.img = res.data.qrcode_url;
          // 检测扫码状态
          this.checkSceneStatus();
          this.$forceUpdate();
        }
      }).catch();
    },
    checkSceneStatus() {
      // 检测扫码状态
      this.wechatData.timer = setInterval(() => {
        checkSceneStr(this.wechatData.codeDetail.scene_str).then(res => {
          if(res.errorcode == 0) {
            // 扫码成功
            if(res.data.status == 1) {
              this.$message.success("扫码登录成功！")
              this.wechatData.timer && clearInterval(this.wechatData.timer);
              this.SaveToken(res.data.token);
              //获取用户信息
              this.GetUserInfo();
              this.$router.push({name:'Home'});
              return;
            }
            // 随机字符串对应的值不存在或已过期
            if(res.data.status == -1) {
              this.wechatData.timer && clearInterval(this.wechatData.timer);
              this.tapWechatCode();
              return;
            }
          }else {
            this.wechatData.timer && clearInterval(this.wechatData.timer);
          }
        }).catch();
      }, this.wechatData.duration);
    },
  }
}
</script>

<style lang="less" scoped>
    .login-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 99;
    }
    .login-inner {
      box-sizing: border-box;
      padding: 0 30px 0 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 69vw;
      height: 42vw;
      min-width: 1000px;
      min-height: 601px;
      
      .login-inner-bg {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: drop-shadow(0px 2px 20px rgba(0,0,0,0.20));
      }
    }
    .login-left {
      // flex: 1;
      position: relative;
      z-index: 1;
      // width: 400px;
      width: 27.8vw;
      height: 100%;
      min-width: 400px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center center;
      background-image: url(~@/assets/other/login_left_img.png);
    }
    .logo {
      position: absolute;
      top: 1.8vw;
      left: 5.4vw;
      width: 5.8vw;
      height: auto;
      object-fit: contain;
    }
    .login-form-wrapper {
      margin-left: 3.8vw;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      // margin: 0 auto 0 20%;
      // border-radius: 4px;
      // border: 1px solid #ccc;
      padding: 0 40px 0 0;
      min-width: 378px;
      width: 27vw;
      // box-shadow: 0 2px 21px 0 rgba(0,0,0,0.15);
      // background: #FFFFFF;
    }
    .login-form {
        .el-form-item {
            margin-bottom: 24px;
        }
    }
    .other-mode-title {
      position: relative;
      padding: 24px 0;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        display: block;
        content: "";
        width: 138px;
        height: 1px;
        background-color: #D4D4D4;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    // 微信登陆
    .wechat-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
      padding: 22px 0 13px;
      width: 100%;
      height: 235px;
      background: #FFFFFF;
      .wechat-code-wrapper {
        box-sizing: border-box;
        // border: 4px solid #E74362;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(~@/assets/user/red_frame1@3x.png), url(~@/assets/user/red_frame2@3x.png),
          url(~@/assets/user/red_frame3@3x.png), url(~@/assets/user/red_frame4@3x.png);
        background-repeat: no-repeat;
        background-position: left top, right top, left bottom, right bottom;
        background-size: 25px 25px;
      }
      .wechat-code-img {
        width: 140px;
        height: 140px;
        object-fit: fill;
      }
    }
    .valid-code-btn {
      padding: 4px 15px;
      &.is-disabled {
        color: #A1A1A1;
      }
    }
</style>