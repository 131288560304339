import * as request from '@/libs/utils/request'
import Config from '@/libs/config'


export function getArticleList(course_id,contentobject_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/article/getList?course_id="+ course_id +"&_parentId="+contentobject_id
  })
}
  
export function getArticleOne(course_id, _id) { //_id : article的ID
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/article/getOne?course_id="+ course_id +"&_id="+_id
  })
}

export function createArticle({course_id, _parentId, title, below_article_id}) {
  let data = {
    course_id,
    _parentId,
    title
  };
  if(!title) {
    delete data.title;
  }
  if(below_article_id) {
    data.below_article_id = below_article_id;
  }
  
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/article/create",
    data: data
  })
}

export function delArticleOne({course_id, _id}) { //_id: article的ID
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/article/delete",
    data: {
      course_id,
      _id
    }
  })
}

export function copyArticle({course_id, article_id}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/article/copyArticle?course_id="+course_id +"&article_id="+article_id
  })
}

export function moveArticle({course_id, article_id, move_way}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/article/moveArticle?course_id="+course_id +"&article_id="+article_id +"&move_way="+move_way
  })
}