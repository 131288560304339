import { getArticleList, getArticleOne, createArticle, delArticleOne, moveArticle, copyArticle } from '@/libs/api/article'
  
const article = {
  state: {
    loading:false,
    articleList:[],
  },
  
  mutations: {
    SET_ARTICLE_LIST(state,data) {
      state.articleList = data;
    },
    del_article(state, data) {
      let tagInd = data.index;
      let articleList = state.articleList;
      articleList.splice(tagInd,1)
    },
    CLEAR_ARTICLE(state) {
      state.articleList = [];
    },
    SET_LOADING(state,data) {
      state.loading = data
    }
  },
  
  actions: {
    // 根据contentobjectID获取所有Articles
    GetArticleList({commit, state}, {course_id,contentobject_id}) {
      commit('SET_LOADING',true);
      getArticleList(course_id, contentobject_id).then((res)=>{
        if(res.errorcode == 0) {
          commit('SET_ARTICLE_LIST',res.data.article_list)
        }
        commit('SET_LOADING',false);
      }).catch(()=>{
        commit('SET_LOADING',false);
      });
    },
    // 获取单个article
    GetArticleOne({commit, state}, {course_id,article_id}) {
      getArticleOne(course_id, article_id).then((res)=>{
        if(res.errorcode == 0) {
           
        }
      }).catch(()=>{
  
      });
    },
    // 创建article
    CreateArticle({commit, state, dispatch}, {
      course_id,
      _parentId,
      title = "<p><span style=\"color: #222222;font-size:16px;\">章节标题</span></p>",
      below_article_id, 
      isUpdateList = true,
      addBlock = false
    }) { 
      //title 可不传; _parentId: 父节点ID,contentobject的ID，且contentobject的_type必须为page
      // below_article_id：下个article
      // addBlock 新增block
      return new Promise((resolve,reject)=>{
        createArticle({
          course_id: course_id,
          _parentId:_parentId,
          title:title,
          below_article_id:below_article_id
        }).then((res)=>{
          if(res.errorcode == 0) {
            // dispatch('CreateBlock',{course_id,_parentId: res.data.article['article-_id']});
            !!isUpdateList && dispatch('GetArticleList',{course_id, contentobject_id: _parentId})
            !!addBlock && dispatch('CreateBlock',{course_id, _layout:"full", _parentId:res.data.article["article-_id"], addComponent:true})
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 删除单个article
    DelArticleOne({commit, state, dispatch}, {course_id,_id,contentobject_id}) { //_id：article的ID
      return new Promise((resolve,reject)=>{
        delArticleOne({course_id,_id}).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            dispatch('GetArticleList',{course_id, contentobject_id: contentobject_id})
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 移动article
    MoveArticle({commit, state, dispatch}, {
      course_id,
      article_id,
      move_way,
      _parentId,
      isUpdateList = true,
    }) { 
      return new Promise((resolve,reject)=>{
        moveArticle({
          course_id,
          article_id,
          move_way,
        }).then((res)=>{
          if(res.errorcode == 0) {
            !!isUpdateList && dispatch('GetArticleList',{course_id, contentobject_id: _parentId})
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 复制article
    CopyArticle({commit, state, dispatch}, {
      course_id,
      article_id,
      _parentId,
      isUpdateList = true,
    }) { 
      return new Promise((resolve,reject)=>{
        copyArticle({
          course_id,
          article_id,
        }).then((res)=>{
          if(res.errorcode == 0) {
            !!isUpdateList && dispatch('GetArticleList',{course_id, contentobject_id: _parentId})
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
  }
}
  
export default article
  