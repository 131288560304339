import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

// 组件-组件列表(用于右侧弹框)
export function getComponentList({group_id, keyword}) {
  let url = "/api/"+ Config.apiversion +"/courses/component/getList";
  if(keyword) {
    url = url.indexOf('?') > -1  ? (url += "&keyword=" + keyword) : (url += "?keyword=" + keyword) ;
  }
  if(group_id) {
    url = url.indexOf('?') > -1  ? (url += "&group_id=" + group_id) : (url += "?group_id=" + group_id) ;
  }
  return request.get({
    url: url
  })
}

export function addComponent(course_id, _parentId, _layout) {
  return new Promise((resolve, reject) => {
    setTimeout(()=>{
      resolve({
        data:{},
        errorcode:0,
        msg:"创建成功"
      })
    },550)
  })
}

// 组件-组件列表(用于选择组件，按分组显示分割)
export function getComponentListByGroup() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/component/getListByGroup"
  })
}

// 组件-创建
export function createComponent({course_id, _parentId, _layout, _component, component_mode_id}) { //_parentId : block_id
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/component/create",
    data: {
      course_id, _parentId, _layout, _component, component_mode_id
    }
  })
}

// 获取单个组件数据
export function getComponentOne({course_id, component_id}) { //_id:组件id
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/component/getOne?course_id=" + course_id + "&_id=" + component_id
  })
}

// 根据block_id获取下属所有component
export function getComponentOfBlock({course_id, block_id}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/component/getComponentByBlockId?course_id=" + course_id + "&block_id=" + block_id
  })
}

// 删除component
export function delComponent({course_id, component_id}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/component/delete",
    data: {
      course_id,
      _id: component_id
    }
  })
}

// 获取图标列表
export function getIconList(type) {
  let url = "/api/"+ Config.apiversion +"/courses/icon/list";
  if(type) {
    url += '?type='+type
  }
  return request.get({
    url: url
  })
}

// 组件-左右移动
export function moveComponent({course_id, block_id, _layout, _id}) { //_id：component的ID
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/component/move?course_id=" + course_id + "&_layout=" + _layout + "&_id=" + _id + "&block_id=" + block_id,
  })
}

// 组件-复制
export function copyComponent({course_id, block_id, _layout, component_id}) { //component_id: 需要复制的组件ID, block_id: 需要移动到的组块ID
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/component/copyComponent?course_id=" + course_id + "&_layout=" + _layout + "&component_id=" + component_id + "&block_id=" + block_id,
  })
}
