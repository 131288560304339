import * as request from '@/libs/utils/request';
import Config from '@/libs/config'

export function getUpgrade() {
    return request.get({
        url: "/api/" + Config.apiversion + "/getUpgrade",
    })
}
export function createUpgrade({vid,sid}) {
    return request.post({
        url: "/api/" + Config.apiversion + "/order/createUpgrade",
        data:{
            vid,
            sid
        }
    })
}
export function cancelUpgrade({order_id}) {
    return request.post({
        url: "/api/" + Config.apiversion + "/order/cancel",
        data:{
            order_id,
        }
    })
}
export function getPayType({order_id,sid}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/getPayType?order_id="+order_id+'&sid='+sid,
    })
}
export function findPayResult({order_no}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/findPayResult?order_no="+order_no,
        showMsg: false,
    })
}
export function publicPayment({order_id,pay_type}) {
    return request.post({
        url: "/api/" + Config.apiversion + "/pay/examine",
        data:{
            order_id,
            pay_type,
        }
    })
}
export function getOrderList({type,page,limit}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/user/orderList?page="+page+'&limit='+limit+'&type='+type,
    })
}
export function getOrderDetail({order_id}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/user/orderDetail?order_id="+order_id,
    })
}
export function getInvoiceList({page,limit}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/user/invoiceList?page="+page+'&limit='+limit,
    })
}

export function invoiceApply({order_no,company_name,tax_number,reg_number,bank_name,bank_account,email,remark}) {
    return request.post({
        url: "/api/" + Config.apiversion + "/user/invoiceApply",
        data:{
            order_no,
            company_name,
            tax_number,
            reg_number,
            bank_name,
            bank_account,
            email,
            remark,
        }
    })
}

export function getInvoiceDetail  ({invoice_id}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/user/invoiceDetail?invoice_id="+invoice_id,
    })
}

export function getQrcode({order_id,order_type,token}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/getQrcode?order_id="+order_id+'&order_type='+order_type+'&token='+token,
    })
}

export function qrcodePay({order_key,state,code}) {
    return request.post({
        url: "/api/" + Config.apiversion + "/pay/qrcodePay",
        data:{
            order_key,
            state,
            code
        }
    })
}
export function wechatNotify() {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/wechat/notify",
    })
}
export function alipayNotify() {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/alipay/notify",
    })
}
export function alipayReturnUrl() {
    return request.get({
        url: "/api/" + Config.apiversion + "/pay/alipay/returnUrl",
    })
}