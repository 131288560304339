<template>
  <div class="richEditor-container"
    :style="{
      '--bg-color': 'red'
    }"
    :class="{
    'isHighLight':isHighLight && isInlineEdit,
    'inline':isInlineEdit
    }">
    <div :id=" 'editor-' + id"  class="editorWrapper"></div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapMutations, mapState } from 'vuex';

const SAMPLE_READ_ONLY_LOCK_ID = 'Integration Sample';

export default {
  name: 'ckeditor',
  props: {
    id: [String,Number],
    value: {
      type: String,
      default: ''
    },
    // simple, simpleInline 不输入情况下不显示编辑tools , rich, richInline
    type: {
      type: String,
      default: 'rich',
      validator: type => ['simpleInline','simple','richInline','rich'].includes( type )
    },
    readOnly: {
      type: Boolean,
      default: null, //使用null作为默认值，config.readOnly
    },
    throttle: { //input 节流控制
      type: Number,
      default: 80
    },
    canKeyEnter: { //是否允许换行
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      $_instance:null,
      $_lastEditorData: '',
      canInput:false, //控制首次不发送input
      editorConfig: {
        dataIndentationChars: '',
        disableNativeSpellChecker: false,
        enterMode: CKEDITOR['ENTER_P'],
        entities: false,
        extraAllowedContent: 'span(*)', //接受span里的所有
        removePlugins: 'easyimage,cloudservices,exportpdf,elementspath',
        toolbar: [
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'ShowBlocks' ] },
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll' ] },
          { name: 'listindent', groups: [ 'list', 'indent', ], items: [ 'NumberedList', 'BulletedList', 'Outdent', 'Indent'] },
          { name: 'paragraph', groups: [  'blocks', 'align', 'bidi' ], items: [ 'Blockquote', 'CreateDiv' ] },
          // { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv' ] },
          { name: 'direction', items: [ 'BidiLtr', 'BidiRtl' ] },
          // '/',
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'FontSize','Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
          { name: 'styles', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
          { name: 'links', items: [ 'Link', 'Unlink' ] },
          { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          { name: 'insert', items: [ 'SpecialChar', 'Table' ] },
          { name: 'tools', items: [] },
          { name: 'others', items: [ '-' ] }
        ],
        height: 170,
        resize_maxHeight: 370,
        language:'cn',
      },
      simpleConfig: {
        // // // Show toolbar on startup (optional).
        // // //   startupFocus: true
        dataIndentationChars: '',
        disableNativeSpellChecker: false,
        enterMode: CKEDITOR['ENTER_P'],
        entities: false,
        extraAllowedContent: 'span(*)',
        removePlugins: 'easyimage,cloudservices,exportpdf,elementspath,contextMenu',
        toolbar: [
          { name: 'basicstyles', groups: [ 'basicstyles'], items: ['FontSize', 'Bold', 'Italic', 'Underline'] },
          { name: 'colors', items: [ 'TextColor'] }, //这个依赖 others
          { name: 'tools', items: [] },
          { name: 'others', items: [ '-' ] }
        ],
        height: 60,
        resize_maxHeight: 200,
      },
    };
  },
  computed: {
    ...mapState({
      popInputId: state => state.popInputId
    }),
    isHighLight() { //是否高亮
      let arr1 = this.id.split("-");
      let arr2 = this.popInputId && this.popInputId.split("-") || [];
      return arr2.length >= 3 ? arr1[0] === arr2[0] && arr1[1] === arr2[1] && arr1[arr1.length-1] === arr2[arr2.length-1] : false;
    },
    isInlineEdit() { //是否行内框
      return this.type=='simpleInline' || this.type=='richInline';
    },
  },
  watch: {
    value( newValue,  oldValue ) {
      // if( this.$_instance && this.$_instance.getData() !== newValue ) {
      //   this.$_instance.setData( newValue );
      // }
      if ( newValue !== oldValue && newValue !== this.$_lastEditorData ) {
				this.$_instance.setData( newValue );
			}
    },
    readOnly( val ) {
      if( this.$_instance ) {
        this.$_instance.setReadOnly( val );
      }
    },
    id: {
      immediate: true,
      handler(newValue, oldValue) {
        // console.log('id变化', newValue, oldValue);
        // if(newValue && oldValue) {
        //   console.log('只读')
        //   this.$_instance.enableReadOnlyMode( SAMPLE_READ_ONLY_LOCK_ID );
        //   console.log(new Date().getTime());
        //   this.$nextTick(() => {
            
        //     setTimeout(() => {
        //       console.log('可写');
        //       console.log(new Date().getTime());
        //       this.$_instance.disableReadOnlyMode( SAMPLE_READ_ONLY_LOCK_ID );
        //     },0);

        //     // let time0 = new Date().getTime();
        //     // let isOdd = false;
        //     // setInterval(()=>{
        //     //   if(new Date().getTime() - time0 < 1000 * 30) {
        //     //     isOdd = !isOdd;
        //     //     isOdd && temp1.click();
        //     //     !isOdd && temp2.click();
        //     //   }
        //     // },50);
        //   });
        // }
      }
    },
  },
  created() {
    const { CKEDITOR_VERSION } = window;

		// Starting from v34.0.0, CKEditor 5 introduces a lock mechanism enabling/disabling the read-only mode.
		// As it is a breaking change between major releases of the integration, the component requires using
		// CKEditor 5 in version 34 or higher.
		if ( CKEDITOR_VERSION ) {
			const [ major ] = CKEDITOR_VERSION.split( '.' ).map( Number );
			if ( major < 34 ) {
				console.warn( 'The <CKEditor> component requires using CKEditor 5 in version 34 or higher.' );
			}
		} else {
			console.warn( 'Cannot find the "CKEDITOR_VERSION" in the "window" scope.' );
		}
  },
  mounted() {
    this.renderEditor(); //渲染编辑器
  },
  methods: {
    ...mapMutations(["inputHighLight"]),
    specialConfig(element,config) {
      // 统一处理某些元素的config
      if(element.getAttribute('class').includes('editorWrapper')) {
      }
    },
    renderEditor() {
      let type = this.type; //显示的编辑器类型
      let id = this.id; //编辑器id
      
      let methodMap = {
        simpleInline:"BalloonSimple",
        simple:"ClassicSimple",
        richInline:"BalloonEditor",
        rich:"ClassicEditor",
      };
      const colors = [
            {
              color: '#262626',
              label: '黑色'
            },
            {
              color: '#595959',
              label: '深灰'
            },
            {
              color: ' #8C8C8C',
              label: '灰色'
            },
            {
              color: '#D9D9D9',
              label: '浅灰'
            },
            {
              color: '#FFFFFF',
              label: '白色'
            },
            {
              color: '#FF0000',
              label: '红色'
            },
            {
              color: ' #FFBE00',
              label: '暗黄色'
            },
            {
              color: '#00B2F6',
              label: '蓝色'
            },
            {
              color: '#3572CA',
              label: '深蓝'
            },
            {
              color: '#7927A6',
              label: '紫色'
            },
            {
              color: '#FF7B00',
              label: '橘色'
            },
            {
              color: '#F15700',
              label: '橘红'
            },
            {
              color: '#3B744B',
              label: '深绿'
            },
            {
              color: '#5FB878',
              label: '绿色'
            },
            {
              color: '#51CFAE',
              label: '浅绿'
            },
            {
              color: '#FF00FF',
              label: '荧光紫'
            },
            {
              color: '#FFFF00',
              label: '荧光黄'
            },
            {
              color: '#00FF00',
              label: '荧光绿'
            },
            {
              color: '#00FFFF',
              label: '荧光蓝'
            },
            {
              color: '#0200FF',
              label: '宝蓝色'
            }
          ];
      const config = ['simpleInline','simple'].includes(type) ? this.simpleConfig : this.editorConfig;
      const method = methodMap[type];
      const editorConfig = {
        fontSize: {
          options: [ 10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50 ],
          supportAllValues: true
        },
        fontColor: {
          colors: colors
        },
        fontBackgroundColor: {
          colors: colors
        },
        placeholder: this.placeholder,
        removePlugins:['ImageToolbar'], //移除插件
        toolbar: {
          shouldNotGroupWhenFull: false, //工具栏是否换行
        }
      };
      
      // if( this.readOnly !== null ) {
      //   config.readOnly = this.readOnly;
      // }

      if ( this.value ) {
        // 初始化值
        editorConfig.initialData = this.value;
      }

      CKEDITOR[method]
        .create( document.querySelector( '#'+'editor-' + id ), editorConfig )
        .then( editor => {
          // Save the reference to the $_instance for further use.
				  this.$_instance = editor;

          // Set initial disabled state.
          if ( this.readOnly ) {
            editor.enableReadOnlyMode( SAMPLE_READ_ONLY_LOCK_ID );
          }

          this.$_setUpEditorEvents();

          // Let the world know the editor is ready.
				  this.$emit( 'ready', editor );
        } )
        .catch( err => {
            console.error( err.stack );
        } );

    },
    $_setUpEditorEvents() {
      const editor = this.$_instance;
      // Use the leading edge so the first event in the series is emitted immediately.
			// Failing to do so leads to race conditions, for instance, when the component value
			// is set twice in a time span shorter than the debounce time.
			// See https://github.com/ckeditor/ckeditor5-vue/issues/149.
			const emitDebouncedInputEvent = debounce( evt => {
        // Cache the last editor data. This kind of data is a result of typing,
				// editor command execution, collaborative changes to the document, etc.
				// This data is compared when the component value changes in a 2-way binding.
				// const data = this.$_lastEditorData = editor.getData();
				const data = editor.getData();

				// The compatibility with the v-model and general Vue.js concept of input–like components.
        if ( this.$_lastEditorData !== data ) {
          // The compatibility with the v-model and general Vue.js concept of input–like components.
          this.$_lastEditorData = data;
          !this.isInlineEdit && this.inputHighLight({id: this.id, isShow:true});
          this.$emit( 'input', data, evt, editor );
        }
			}, this.throttle, { leading: true } );

			// Debounce emitting the #input event. When data is huge, $_instance#getData()
			// takes a lot of time to execute on every single key press and ruins the UX.
			//
			// See: https://github.com/ckeditor/ckeditor5-vue/issues/42
			editor.model.document.on( 'change:data', emitDebouncedInputEvent );

			editor.editing.view.document.on( 'focus', evt => {
				this.$emit( 'focus', evt, editor );
			} );

			editor.editing.view.document.on( 'blur', evt => {
				// 外层需要blur状态
        this.inputHighLight({id: null, isShow:false});
        let resData =  editor.getData();
        if(this.value !== resData) {
          this.$emit( 'blur',resData, evt, editor );
        }
			} );

      editor.on( 'key', function( evt ) {
      
        if(evt.data.keyCode == 13) {
          // Cancel the event, so other listeners will not be executed and
          // the keydown's default behavior will be prevented.
          // 禁止回车 - 在标题类型的富文本框时
          if(!canKeyEnter) {
            evt.cancel();
          }
        }
        if ( evt.data.keyCode == CKEDITOR.SHIFT + 13 ) {
          // shift+enter
          if(!canKeyEnter) {
            evt.cancel();
          }
        }
      } );
    },
    additionalSet(ev) {
      // 去掉type=inline时，title提示
      if(document.querySelectorAll(".cke_textarea_inline")) {
        document.querySelectorAll(".cke_textarea_inline").forEach((v)=>{
          v.title = ""
        });
      }

      //解决CKEdit富文本编辑器按下回车键自动在段落之间增加一行<br>标签的办法 就算不输入
      //解决：失焦之后会多个 ↵ 符号
      var writer = ev.editor.dataProcessor.writer;        
      var dtd = CKEDITOR.dtd; 

      writer.indentationChars = ''; 
      writer.lineBreakChars = '';

      for (var e in CKEDITOR.tools.extend({}, dtd.$block, dtd.$listItem, dtd.$tableContent)) {
        ev.editor.dataProcessor.writer.setRules(e, {    
          indent: false,
          breakBeforeOpen: false,     
          breakAfterOpen: false,  
          breakBeforeClose: false,
          breakAfterClose: false
        });
      } 

      for (var e in CKEDITOR.tools.extend({}, dtd.$list, dtd.$listItem, dtd.$tableContent)) {
        ev.editor.dataProcessor.writer.setRules(e, {
          indent: true,
        });
      }

      ev.editor.dataProcessor.writer.setRules( 'h1', {} );

    },
  },
  beforeDestroy() {
    if ( this.$_instance ) {
      //延时是为了，配合过渡效果0.2s，防止销毁时，不是富文本编辑器
      setTimeout(()=>{
        this.$_instance.destroy();
        this.$_instance = null;
        this.$emit( 'destroy', this.$_instance );
      },210)
    }
    // this.$_destroyed = true;
  },
}
</script>

<style lang="less" scoped>
// [contenteditable]:focus {
//   outline: none;
// }
/deep/ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
// 文本选中失去焦点
.ck.ck-editor__editable_inline.ck-blurred ::selection {
  background-color: transparent!important;
}
.ck.ck-editor__editable_inline {
  padding: 2px 0px;
  border: 1px solid transparent;
  // line-height: 1.5;
  /deep/p {
    margin: 0.8em 0;
  }
}
/deep/.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color:#D1D1D1;
  box-shadow: none;
}
.richEditor-container:not(.inline ) /deep/.ck.ck-editor__editable.ck-editor__editable_inline {
  // background-color: #1E9C7B;
  // 弹窗富文本背景色
  // background-color: var(--bg-color, #1E9C7B);
}
.richEditor-container:not(.inline ) /deep/.ck.ck-toolbar-dropdown>.ck-dropdown__panel {
  //工具栏换行处理宽度
  max-width: calc(28vw - 32px) !important;
}
@media screen and (min-width: 1190px){
  .richEditor-container:not(.inline ) /deep/.ck.ck-toolbar-dropdown>.ck-dropdown__panel {
    //工具栏换行处理宽度
    max-width: calc(336px - 32px) !important;
  }
}
// 行内输入框
.richEditor-container.inline {
  /deep/.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border-color:transparent;
  }
}
.editorWrapper {
  border-color:transparent;
  resize:none;
}
.isHighLight {
  /deep/.ck.ck-editor__editable_inline { //行内框高亮
    border-color:#1E9C7B!important;
  }
}
.richEditor-container {
  display: inline-block;
  width: 100%;
  word-break: break-all;
  &.inline {
    /deep/.ck.ck-editor__editable_inline>:last-child {
      margin-bottom: 0;
    }
    /deep/.ck.ck-editor__editable_inline>:first-child {
      margin-top: 0;
    }
  }
  &:not(.inline) {
    /deep/.ck-editor__editable {
      min-height: 90px;
    }
  }
}
@media screen and (max-width: 1336px) {
  .richEditor-container:not(.inline) {
    /deep/.ck-editor__editable {
      min-height: 70px;
    }
  }
}

// 提示文本
/deep/.ck.ck-editor__editable > .ck-placeholder::before {
  font-size: 14px;
  color: #A1A1A1;
}

// 第二层下拉-媒体输入框
/deep/.ck-sticky-panel__content .ck-toolbar_grouping 
.ck-toolbar__grouped-dropdown .ck-dropdown__panel_sw .ck-toolbar
.ck-dropdown .ck-dropdown__panel_sw.ck-dropdown__panel-visible{
  right: auto;
  left: 0;
  transform: translateX(-18%);
}
// 下拉-媒体输入框
/deep/.ck-sticky-panel__content .ck-toolbar_grouping 
.ck-toolbar__grouped-dropdown .ck-dropdown__panel_sw .ck-toolbar
.ck-dropdown .ck-dropdown__panel_se.ck-dropdown__panel-visible,
/deep/.ck-sticky-panel__content .ck-toolbar_grouping 
.ck-toolbar__grouped-dropdown .ck-dropdown__panel_sw .ck-toolbar
.ck-dropdown .ck-dropdown__panel_ne.ck-dropdown__panel-visible {
  right: auto;
  left: 0;
  transform: translateX(-16px);
  .ck-media-form {
    padding: 10px 4px;
    .ck-labeled-field-view {
      width: 210px;
      .ck-input {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}

/deep/.ck-media-form {
  // border: 1px solid darkblue !important;
}
</style>