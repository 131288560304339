<template>
  <div>
    <el-dialog
      title=""
      custom-class="update-dialog"
      :visible.sync="hintVisible"
      width="480px"
      top="25vh"
    >
      <div class="">
        <div class="padding_top10 flex flex_acenter">
          <span class="fontsize_16 color_26 title">版本更新</span>
          （<span v-if="hint.version">{{ hint.version }}</span>）
        </div>
        <div class="p-content">
          <p>本次更新：</p>
          <div v-html="hint.content"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="fontsize_12 color_8C flex flex_acenter">
          <i v-if="!hint.downloaded && hint.process" class="el-icon-loading fontsize_16"></i>
          <i v-if="hint.downloaded" class="el-icon-success fontsize_16" style="color:#55c42a;"></i>
          <span v-if="hint.downloaded" class="padding_left8">下载完成</span>
          <span v-else-if="hint.process" class="padding_left8">下载进度：{{hint.process}}%</span>
          <span v-else-if="hint.canDownload" class="">检测到可用更新</span>
        </div>
        <el-button v-if="hint.downloaded" type="primary" @click="confrim('install')">立即安装</el-button>
        <el-button v-else-if="hint.process" @click="confrim('cancelDownload')">取消下载</el-button>
        <el-button v-else-if="hint.canDownload" type="primary" @click="confrim('downloadNew')">下载新版本</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: Object,
      default: () => {
        return {
          content: "",
          version: "",
          canDownload:true,
          process:"",
          downloaded:false,
        };
      },
    },
  },
  data() {
    return {
      appName: "飞稻",
      hintVisible: false,
    };
  },
  watch: {
    visible: {
      immediate:true,
      handler(nval) {
        if (nval) {
          this.hintVisible = true;
        } else {
          this.hintVisible = false;
        }
      }
    },
  },
  mounted() {},
  methods: {
    beforeClose() {
      this.$emit("update:visible", false);
    },
    close() {
      this.$emit("update:visible", false);
    },
    confrim(type) {
      switch(type) {
        case "install":
          this.$emit("action", "install");
          break;
        case "cancelDownload":
          this.$emit("action", "cancelDownload");
          break;
        case "downloadNew":
          this.$emit("action", "downloadNew");
        default:
          break;
      }
    },
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
};
</script>
<style lang="less" scoped>
/deep/.update-dialog {
  border-radius: 4px;
  background-image: url(~@/assets/other/updater_bg.png);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  .title {
    font-weight: bolder;
  }
  .el-dialog__header {
    // display: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__headerbtn {
      top: 12px;
      right: 20px;
    }
    .el-dialog__close {
      font-size: 18px;
      color: #fff;
    }
  }

  .el-dialog__body {
    padding: 148px 24px 0;
  }

  .el-dialog__footer {
    padding: 16px 24px 24px;
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .el-button {
      padding: 0;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      width: 102px;
      height: 34px;
      line-height: 34px;
      font-size: 14px;

      &--default {
        color: #8C8C8C;
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.15);
          background-color: #f5f5f5;
          color: #595959;
        }
        &:focus,&:active {
          border-color: #B7B7B7;
          background-color: #f5f5f5;
        }
      }

      &--primary {
        border: 1px solid #E74362;
        background-color: #E74362;
        color: #fff;

        &:hover {
          border-color: #E95672;
          background-color:  #E95672;
          color: #fff;
        }
        &:focus,&:active {
          border-color: #CF3C58;
          background-color:  #CF3C58;
        }
      }
    }
  }

  .p-content {
    font-size: 14px;
    color: #595959;
    line-height: 28px;
    margin-top: 16px;
  }
}
</style>
