import Cookies from 'js-cookie'
const IS_ELECTRON = process.env.IS_ELECTRON
const { session } = IS_ELECTRON ? window.require('electron').remote : {}

const TokenKey = 'FeiDaoCreation-Admin-Token'

function getCookie() {
  return new Promise((resolve, reject)=>{
    session.defaultSession.cookies.get({ url: process.env.VUE_APP_API, name: TokenKey }).then(res=>{
      if(res.length > 0) {
        resolve(res[0].value)
      }else {
        reject(null)
      }
    }).catch(err=>{
      reject(null)
    })
  })
}

export async function getToken() {
  if(IS_ELECTRON) {
    let res = await session.defaultSession.cookies.get({ url: process.env.VUE_APP_API, name: TokenKey })
    if(res && Array.isArray(res) && res.length > 0) {
      return res[0].value
    }else {
      return null
    }
  }else {
    return localStorage.getItem(TokenKey)
  }
}

export function setToken(token) {
  if(IS_ELECTRON) {
    session.defaultSession.cookies.set({ url: process.env.VUE_APP_API, name: TokenKey, value: token})
  }else {
    return localStorage.setItem(TokenKey, token)
  }
}

export function removeToken() {
  if(IS_ELECTRON) {
    session.defaultSession.cookies.remove(process.env.VUE_APP_API, TokenKey)
  }else {
    return localStorage.removeItem(TokenKey)
  }
}
