<template>
  <fd-custom-dialog :visible.sync="visible" :append-to-body="appendToBody" width="790px" :title="$t('lang.paytheorder')" :before-close="handleClose"
    custom-class="upgrade-dialog">
    <div class="upgrade-dialog__header header_bg_gradient" slot="title" v-if="!isSucceed">
      <div class="flex margin_top6" v-if="showBox === 1">
        <img class="upgrade-dialog__header-photo"
          :src="info.avatar"
          alt="">
        <div>
          <div class="color_26 fontsize_18 lineheight_25 fontweight_500">{{info.username}}</div>
          <div class="color_8C fontsize_14 lineheight_20 margin_top4 fontweight_500">{{$t('lang.currentuseis')}}{{version.name}}</div>
        </div>
      </div>
      <div v-else>
        <span class="upgrade-dialog__title">{{$t('lang.paytheorder')}}</span>
      </div>
    </div>

    <div class="upgrade-dialog__content" v-loading="loading" v-if="!isSucceed">
      <div class="upgrade-dialog__box" v-if="showBox === 1">
        <div class="upgrade-dialog__box-left">
          <p class="color_26 fontsize_16 lineheight_22">{{$t('lang.selectversion')}}</p>
          <div class="flex flexwrap margin_top13">
            <div class="version_item color_FE840F fontsize_14 lineheight_20"
              :class="{ 'is_check': index == versionCurrent }" v-for="(item, index) in upgrades" :key="item.id"
              @click="selectUpgradeVersion(item, index)">
              <img src="@/assets/svg/select.svg" class="select">
              <div class="fontsize_18 lineheight_25 margin_bot12 margin_top16 flex flex_center ">{{item.name}}</div>
              <p class="margin_top4">{{item.zone+item.zone_type}}{{$t('lang.totalspacecapacity')}}</p>
              <p class="margin_top4">{{$t('lang.cancreate')}}{{item.course_num}}{{$t('lang.courses')}}</p>
              <p class="margin_top4">作品分享无限制</p>
            </div>
          </div>
          <p class="color_26 fontsize_16 lineheight_22 margin_top32">{{$t('lang.selectduration')}}</p>
          <div class="flex flexwrap">
            <div class="time_item color_FE840F fontsize_14 lineheight_20 flex flex_jcbetween flex_acenter"
              :class="{ 'is_check': index == timeCurrent }" v-for="(item, index) in selects" :key="index"
              @click="selectUpgradeTime(item, index)">
              <img src="@/assets/svg/select.svg" class="select">
              <span>{{item.date}}</span>
              <span>￥<span class="fontweight_500">{{item.price}}</span></span>
            </div>
          </div>
        </div>
        <div class="upgrade-dialog__box-right flex flex_jcbetween flexcolumn">
          <div class="padding_24 lineheight_20 fontsize_14 color_8C">
            <p class="lineheight_22 margin_bot24 fontsize_16">{{$t('lang.orderinformation')}}</p>
            <p class="margin_top16">{{$t('lang.buyversion')}}：<span class="color_26">{{upgrades[versionCurrent]?upgrades[versionCurrent].name:''}}</span></p>
            <p class="margin_top16">{{$t('lang.purchasetime')}}：<span class="color_26">{{selectsItem.date}}</span></p>
            <p class="margin_top16">{{$t('lang.validityperiod')}}：<span class="color_26">{{selectsItem.buy_valid_date}}</span></p>
            <p class="margin_top16">{{$t('lang.orderamount')}}：<span class="color_26">{{selectsItem.price}} {{$t('lang.RMB')}}</span></p>
          </div>
          <div class="upgrade-dialog__box-right-bottom">
            <p class="flex flex_jcbetween lineheight_25"><span class="color_26 fontsize_12">应付金额</span><span
                class="color_FE840F fontsize_18 fontweight_600">￥{{selectsItem.price}}</span></p>
            <el-button type="primary" class="upgrade-dialog__button margin_top8" @click="submitUpgradeOrder">提交订单
            </el-button>
            <!-- <div class="margin_top13 fontsize_12">
              <el-checkbox v-model="checkClause" class="color_BFBFBF fontsize_12"><span
                  class="fontsize_12 color_BFBFBF">同意并接受</span></el-checkbox><a
                class="pointer color_E74362">《飞稻会员服务条款》</a>
            </div> -->
          </div>
        </div>
      </div>
      <div class="upgrade-dialog__box2" v-if="showBox === 2">
        <div class="upgrade-dialog__box2-top color_59 fontsize_14 lineheight_20">
          <el-row style="margin-bottom:-8px;">
            <el-col :span="8" class="margin_bot8"><p>订单编号：{{order_detail.order_no}}</p></el-col>
            <el-col :span="9" class="margin_bot8"><p>下单时间：{{order_detail.created_at}}</p></el-col>
            <el-col :span="7" class="margin_bot8"><p>购买版本：{{order_detail.good_name}}</p></el-col>
            <el-col :span="8" class="margin_bot8"><p>购买时长：{{order_detail.good_title}}</p></el-col>
            <el-col :span="9" class="margin_bot8"><p>购买后到期日：{{order_detail.endtime.split(' ')[0]}}</p></el-col>
          </el-row>
        </div>
        <div class="upgrade-dialog__box2-bottom">
          <div class="upgrade-dialog__box2-bottom-left flex flex_center flexcolumn ">
            <div class="flex flex_acenter ">
              <div class="prel">
                <img width="137" height="137"
                :src="qrcode.url"
                alt="">
                <div v-if="isTimeout" class="invalid_mask">
                  <span>二维码已过期</span>
                </div>
              </div>
              <div class="flex flex_center flexcolumn margin_left20">
                <p class="fontsize_14 color_26 lineheight_28">应付金额：<span class="color_FE840F fontsize_20 fontweight_500">{{qrcode.payment}}
                  </span>元</p>
                <p class="margin_top8 fontsize_14 color_26">请使用微信、支付宝扫码支付</p>
                <p class="flex margin_top8">
                  <i class="icon-upgrade-wx fontsize_14 " style="margin-top: 2px;"></i>
                  <img src="@/assets/zhifubao.svg" alt="" class="margin_left5" style="width:14px;height:14px;margin-top:2px;">
                  </p>
              </div>
            </div>
            <p class="color_26 fontsize_12 margin_top8">请您在<span class="color_FF4D4F">2个小时内</span>完成支付，否则订单将自动取消。
            </p>
          </div>
          <div class="upgrade-dialog__box2-bottom-right flex flex_center flexcolumn padding_left16">
            <p class="flex flex_acenter"><i class="icon-upgrade-pay"></i><span
                class="color_26 fontsize_16 margin_left5">对公支付</span></p>
            <p class="fontsize_14 color_8C margin_top24 lineheight_20">支持对公转账支付，支付完成后我们会尽快确认到账并开通服务。</p>
            <el-button type="primary" class="upgrade-dialog__button small margin_top36" @click="handleExamine">对公支付</el-button>
          </div>
        </div>
      </div>
      <div class="upgrade-dialog__box3" v-if="showBox === 3">
        <div class="upgrade-dialog__box3-left flex flex_jcbetween flexcolumn">
          <div class="lineheight_20 fontsize_14 color_8C">
            <p class="flex flex_acenter lineheight_22"><i class="icon-upgrade-pay fontsize_16"></i><span
                class="color_26 fontsize_16 margin_left8">对公付款</span></p>
            <p class="margin_top16">账户名称：<span class="color_26">{{bank.account_name}}</span></p>
            <p class="margin_top16">开户银行：<span class="color_26">{{bank.bank_name}}</span></p>
            <p class="margin_top16">银行账户：<span class="color_26">{{bank.account}}</span></p>
            <p class="margin_top16">汇款金额：<span class="color_26">￥{{bank.payment}}</span></p>
            <p class="margin_top16">汇款备注：<span class="color_26">{{bank.remark}}</span></p>
            <p class="margin_top16 flex flex_acenter"><i class="icon-upgrade-remind fontsize_12"></i><span
                class="color_FF4D4F fontsize_12 margin_left8">汇款时请务必要备注订单号</span></p>
          </div>
          <el-button type="primary" class="upgrade-dialog__button bgwhite" v-clipboard:copy="copyContent" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr">一键复制</el-button>
        </div>
        <div class="upgrade-dialog__box3-right flex flex_jcbetween flexcolumn">
          <p class="fontsize_14 color_59 lineheight_25">您可以复制左侧的信息进行对公转账，转账完成后点击<span
              class="color_26 emphasize">“已完成支付”</span>按钮，我们会在收到汇款<span class="color_26 emphasize">24小时内</span>为您升级服务（周末、法定节假日可能有延时）。
          </p>
          <el-button type="primary" class="upgrade-dialog__button  fontsize_14" @click="finishPay">已完成支付</el-button>
        </div>
      </div>
    </div>
    <div class="upgrade-dialog__succeed" v-if="isSucceed">
      <span class="icon"><i class="icon-upgrade-win"></i></span>
      <span class="text">支付成功！</span>
    </div>
  </fd-custom-dialog>
</template>

<script>
import { getUpgrade,createUpgrade ,getPayType,publicPayment,findPayResult} from '@/libs/api/upgrade'
import { mapActions } from "vuex";
export default {
  // name: 'UpgradeDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    currentBox:{
      type: Number,
      default: 1
    },
    close: Function,
    changePay: Function,
    beforeFinishPay: Function,
    appendToBody: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showBox: 1,
      isSucceed: false,
      checkClause: false,
      versionCurrent: 0,
      timeCurrent: 0,
      loading: false,
      visible: false,
      info:{},
      upgrades:[],
      selects:[],
      version:{},
      selectsItem:{},
      isTimeout:false,
      qrcode:{},
      bank:{},
      order_detail:{},
      time:'',
      querytime:'',
      is_invalid: false, //二维码失效
    };
  },
  async created(){
 
  },
  watch: {
    show(val) {
      if (val) {
        this.visible = true;
        if (typeof this.changePay === 'function' && this.currentBox==2) {
          this.showBox=2;
          this.changePay(this.getPayCode);
        }else{
          this.getInfo()
          this.getUpgradeInfo()
        }
      } else {
        this.visible = false;
        this.showBox=1;
        this.initData();
      }
    }
  },
  computed: {
    copyContent(){
      return `
      账户名称：${this.bank.account_name}
      开户银行：${this.bank.bank_name}
      银行账户：${this.bank.account}
      汇款金额：${this.bank.payment}
      汇款备注：${this.bank.remark}
      `
    }
  },
  methods: {
    ...mapActions(["GetUserDetail"]),
    initData() {
      this.isSucceed =  false;
      this.checkClause =  false;
      this.versionCurrent =  0;
      this.timeCurrent =  0;
      this.loading =  false;
      this.visible =  false;
      // this.info = {};
      // this.upgrades = [];
      // this.selects = [];
      // this.version = {};
      // this.selectsItem = {};
      this.isTimeout = false;
      // this.qrcode = {};
      // this.bank = {};
      // this.order_detail = {};
      this.time = '';
      this.querytime = '';
    },
    getInfo() {
      this.GetUserDetail()
        .then((res) => {
          if (res.errorcode == 0) {
            this.info=res.data;
            this.version=res.data.version;
          }
        })
        .catch();
    },
    getUpgradeInfo(){
      getUpgrade().then((res) => {
        if (res.errorcode == 0) {
          this.upgrades=res.data;
          this.selects = this.upgrades[0].selects;
          this.selectsItem = this.upgrades[0].selects[0];
        }
      })
    },
    handleClose(done) {
      this.$emit('update:show', false);
      this.$emit('close');
      this.showBox=1;
      this.isTimeout=false;
      clearTimeout(this.time);
      clearInterval(this.querytime)

    },
    selectUpgradeVersion(item, index) {
      this.versionCurrent = index;
      this.selects=item.selects;
    },
    selectUpgradeTime(item, index) {
      this.timeCurrent = index;
      this.selectsItem = item;
    },
    timing(call,t){
      this.time = setTimeout(call,t)
    },
    // 每一秒查询支付结果
    queryResult(){
      this.querytime =setInterval(()=>{
        findPayResult({order_no:this.order_no})
          .then(res=>{
            if (res.errorcode == 0) {
              clearInterval(this.querytime)
              this.isSucceed=true;
              //重新获取用户信息

              this.GetUserDetail();
              setTimeout(()=>{
                this.$emit('update:show', false);
                location.reload();
              },2000)
            }else if(res.errorcode == 2) {
              // 订单超时
              this.isTimeout = true;
            }else if(res.errorcode == 3) {
              // 订单金额改变
              if(this.showBox === 2) {
                this.qrcode.payment = res.data.payment;
              }else if(this.showBox === 3) {
                // 对公支付
                this.bank.payment = res.data.payment;
              }
              
              this.GetUserDetail();
            }
          })
      },1000)
    },
    getPayCode({sid,order_id}){
      getPayType({sid,order_id}).then((res) => {
        if (res.errorcode ==0) {
          this.qrcode=res.data.qrcode;
          this.bank=res.data.bank;
          this.order_detail=res.data.order_detail;
          this.order_no= res.data.order_detail.order_no;
          this.timing(()=>{
            this.isTimeout=true;
            console.info('订单已超时');
            clearTimeout(this.time)
          },1000*60*60*2)
          this.queryResult()
        }else{
          this.$emit('update:show',false)
        }
      })
    },
    // 提交
    submitUpgradeOrder() {
      // if (!this.checkClause) {
      //   this.$message('请先阅读协议并同意勾选');
      //   return false
      // }
      this.loading = true;
      let data ={
        vid:this.selectsItem.vid,
        sid:this.selectsItem.sid,
      }
      // 1创建订单
      createUpgrade(data).then(res=>{
        if (res.errorcode ==0) {
          this.order_id= res.data.order_id;
          this.order_no= res.data.order_no;
          this.showBox = 2;
          this.getPayCode({sid:this.selectsItem.sid,order_id:this.order_id});
          if (typeof this.beforeFinishPay === 'function') {
            this.beforeFinishPay();
          }
        }
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    },
    handleExamine(){
      if (this.isTimeout) {
        this.$message.error('订单失效，请重新提交订单');
        return false
      }
      this.showBox = 3;
     
      
    },

    onCopy( e )  { 
      this.$message.success('复制成功');
      //  this.$message.success(this.copyContent);
    } , 
    onCopyErr( e )  { 
      this.$message.error('复制失败');
      //  this.$message.success(this.copyContent);
    } , 
    finishPay() {
      publicPayment({order_id:this.order_id,pay_type:2}).then((res) => {
        this.$emit('update:show',false)
        this.showBox=1;
        this.isTimeout=false;
      }).catch((err) => {
        
      });
    },
    // handleClose() {
    //   this.upgradeDialogVisible = false;
    // },
    // ---升级---
  },
  mounted() {
    
  },
  destroyed() {
    this.$emit('update:show', false);
    clearTimeout(this.time);
    clearInterval(this.querytime)
  }
};
</script>
<style lang="less" scoped>
// 升级
/deep/.el-dialog {
  border-radius: 4px;
  box-shadow: none;

  &__body {
    padding: 0 !important;
  }

  &__header {
    padding: 0;
  }

  &__headerbtn {
    position: absolute;
    top: 10px;
    right: 12px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 26px;
  }

  &__close {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.upgrade-dialog {

  &__header {
    height: 72px;
    padding: 0 24px;
    display: flex;
    align-items: center;

    &.header_bg_gradient {
      // background-image: linear-gradient(90deg, #EFDEBE 0%, #DBBA8A 47%, #DBBA8A 100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('~@/assets/other/upgrade_dialog_head_bg.png');
      border-radius: 4px 4px 0 0;
    }

    &-photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid #fff;
      margin-right: 16px;
    }
  }

  &__title {
    font-size: 16px;
    color: #262626;
    font-weight: 500;
    line-height: 22px;
  }


  &__succeed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 510px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #52C41A;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }

    .text {
      font-size: 18px;
      color: #52C41A;
      line-height: 25px;
      padding-left: 13px;
    }

  }

  &__content {
    min-height: 438px;
    background-color: #fff;
    box-sizing: border-box;
  }

  &__box {
    min-height: 438px;
    display: flex;
    justify-content: space-between;

    &-left {
      padding: 24px;
      flex: auto;

      .version_item {
        width: 212px;
        height: 145px;
        color: #262626;
        background-color: #fff;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        margin-right: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: all 100ms ease;

        & .select {
          width: 35px;
          height: 34px;
          position: absolute;
          right: -2px;
          bottom: -2px;
          visibility: hidden;
        }

        &>p {
          margin-left: 59px;
          position: relative;

          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #262626;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        &.is_check {
          color: #FE840F;
          background-image: linear-gradient(134deg, #FFF4EA 42%, #FFFFFF 100%);
          border: 1px solid #FE840F;

          &>p::before {
            background-color: #FE840F;
          }

          & .select {
            visibility: visible;
          }
        }
      }

      .time_item {
        min-width: 132px;
        height: 40px;
        color: #262626;
        background-color: #fff;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        margin-right: 24px;
        margin-top: 12px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: all 100ms ease;
        padding: 10px 8px;
        box-sizing: border-box;

        & .select {
          width: 16px;
          height: 16px;
          position: absolute;
          right: -1px;
          bottom: -1px;
          visibility: hidden;
        }

        &.is_check {
          color: #FE840F;
          border: 1px solid #FE840F;

          & .select {
            visibility: visible;
          }
        }
      }
    }

    &-right {
      width: 272px;
      border-left: 1px solid #F0F0F0;
      box-sizing: border-box;
      flex-shrink: 0;

      &-bottom {
        min-height: 136px;
        border-top: 1px solid #F0F0F0;
        padding: 16px 24px;
        box-sizing: border-box;
      }
    }
  }

  &__box2 {
    min-height: 438px;
    padding: 24px 16px;
    box-sizing: border-box;

    &-top {
      padding: 16px;
      background: #F5F5F5;
      border-radius: 4px;
    }

    &-bottom {
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      margin-top: 24px;
      padding: 16px;
      box-sizing: border-box;
      min-height: 267px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &-left {}

      &-right {
        border-left: 1px solid #EDEDED;
      }
    }
  }

  &__box3 {
    min-height: 438px;
    padding: 24px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    &-left {
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      padding: 34px 16px;
    }

    &-right {
      padding: 96px 0 35px 0;
    }
  }

  &__button {
    width: 100%;
    height: 44px;
    background: #E74362;
    border: 1px solid #E74362;
    border-radius: 4px;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 500;

    &.small {
      height: 40px;
      font-size: 14px;
    }

    &:hover {
      background: #ce3b59;
      border: 1px solid #ce3b59;
    }

    &.bgwhite {
      background: #fff;
      border: 1px solid #E74362;
      color: #E74362;
      font-weight: normal;

      &:hover {
        border: 1px solid #B5102F;
        color: #B5102F;
      }
    }

  }
}

// 强调
.emphasize {
  padding: 0 5px;
  font-weight: bolder;
}
// 失效遮罩
.invalid_mask {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #fff;
}
</style>