import { getLanguageList, previewCourse } from '@/libs/api/others'

const user = {
  state: {
    feidaoLanguage:"zh-CN", //当前语言
    languageList:[],
    previewSet: {
      //[course_id]: url
    },
  },

  getters: {
    // 获取预览详情
    getPreviewDetailById: (state) => (course_id) => {
      return state.previewSet[course_id]
    },
  },

  mutations: {
    setCurrentLanguage(state, lang) {
      state.feidaoLanguage = lang;
    },
    setLanguageList(state, list) {
      state.languageList = list;
    },
  },

  actions: {
    // 语言列表
    GetLanguageList({ commit }) {
      return new Promise((resolve, reject) => {
        getLanguageList().then(response => {
          if(response.data && response.errorcode == 0) {
            commit("setLanguageList",response.data);
            let currentIndex = response.data.findIndex(v => !!v.is_local);
            if(currentIndex > -1) {
              commit("setCurrentLanguage", response.data[currentIndex].key);
            }
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 预览
    PreviewCourse({ commit }, course_id) {
      return new Promise((resolve, reject) => {
        previewCourse(course_id).then(response => {
          if(response.data && response.errorcode == 0) {
            console.log('预览成功')
          }else {
            console.log('预览失败1')
          }
          resolve(response)
        }).catch(error => {
          console.log('预览失败2', error)
          reject(error)
        })
      })
    },
  }
}

export default user
