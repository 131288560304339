export const lang = {
  home: '首页',
  course: '课程',
  media: '媒体',
  share: '分享',
  help: '帮助',
  adduser: '添加用户',
  mycourse: '我的课程',
  createcourse: '创建课程',
  importcourse: '导入课程',
  searchofcoursename: '根据课程搜索',
  searchoftag: '根据标签搜索',
  communityedition: '社区版',
  upgradenow: '立即升级',
  updatetime: '更新时间',
  edit: '编辑',
  changecover: '封面',
  rename: '重命名',
  previewcourseware: '预览',
  addlabel: '标签',
  copying: '复制',
  delete: '删除',
  backhome:'返回首页',
  globalsetting:'全局设置',
  publish:'导出',
  preview:'预览',
  page:'页面',
  theme:'主题',
  addcontentpage:'添加内容页',
  addthemepage:'添加子菜单',
  style:'样式',
  completeconditions:'完成条件',
  pickup:'收起',
  expand:'展开',
  medialibrary:'媒体库',
  mediaupload:'上传媒体',
  image:'图像',
  audio:'音频',
  video:'视频',
  others:'其他',
  searchofname: '根据名称搜索',
  onlinePhotoGallery: '线上图库',
  purchase: '购买',
  setstyle: '设置样式',
  editbookmarks: '编辑书签',
  setashomepage: '设为首页',
  contentpage: '内容页',
  themepage: '子菜单',
  display:"显示",
  hide:"隐藏",
  paymentandorder:"支付与订单",
  personaldata:"个人资料",
  accountsecurity:"账户安全",
  logout:"退出账户",
  all:"全部",
  remind:"提醒",
  notice:"公告",
  community:"社区",
  reset:"重置",
  previewing:"预览中",
  move:"移动",
  moveup:"上移",
  movedown:"下移",
  managementpage:"管理页",
  numberofcourse:"课程数",
  spacecapacity:"空间容量",
  upgrade:"升级",
  paytheorder:"支付订单",
  currentuseis:"当前使用是",
  selectversion:"选择版本",
  selectduration:"选择时长",
  totalspacecapacity:"空间总容量",
  cancreate:"可创建",
  courses:"个课程",
  orderinformation:"订购信息",
  buyversion:"购买版本",
  purchasetime:"购买时长",
  validityperiod:"购买后到期日",
  orderamount:"订单金额",
  RMB:"元",
  sharingcenter:"分享中心",
  sharelessons:"分享课程",
  expired:"已失效",
  templet:"模板",
  templatecenter:"模板中心",
  use:"使用"
}