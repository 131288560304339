<template>
  <div class="photo-cropper-container flex flexcolumn flex_jcbetween">
    <div class="photo-cropper-wrapper">
      <img  v-if="src" :src="src" fit="cover" class="photo-cropper-img" id="cropper-img" loading="lazy" />
    </div>
    <div  v-if="cropperData.cropperTools[0].currentStatus === 'white'" class="photo-cropper-done">
      <span  @click="doneCrop" class="btn done">完成</span>
      <span @click="resetCrop" class="btn cancel">取消</span>
    </div>
    <div class="photo-cropper-tools">
      <div v-for="(item,index) in cropperData.cropperTools" :key="item.type"  
        @click="tapCropperTools(item,index)" 
        :class="{'active': item.currentStatus === 'white', 'disabled': operateLogLen === 0 && ( item.type === 'backout') }"
        class="item pointer flex flex_acenter flex_center flexcolumn" >
        <i :class="[item.iconClass[item.currentStatus]]" class="fontsize_20 cropper-icon"></i>
        <span class="fontsize_12 color_222 margin_top6 cropper-name">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { dataURLtoFile } from '@/libs/utils/tools';
export default {
  name: "photoEditor",
  props: {
    src: {
      type: String,
      default: "",
      required:true
    }
  },
  data() {
    return {
      cropperData: {
        element:null,
        initDeatil: null, //首次进入时候的裁切数据
        detail:{},
        backUrl:"",
        operateLog: [], //操作记录
        cropperTools:[
          {
            name: "裁剪",
            type: "cutting",
            action: "",
            iconClass: {
              default: "icon-cropper-cutting-default",
              white:"icon-cropper-cutting-white",
              active:"icon-cropper-cutting-active"
            },
            currentStatus:'default'
          },
          {
            name: "向左转",
            type: "turnLeft",
            action: "",
            iconClass: {
              default: "icon-cropper-turn-left-default",
              white:"icon-cropper-turn-left-white",
              active:"icon-cropper-turn-left-active"
            },
            currentStatus:'default'
          },
          {
            name: "向右转",
            type: "turnRight",
            action: "",
            iconClass: {
              default: "icon-icropper-turn-right-default",
              white:"icon-cropper-turn-right-white",
              active:"icon-cropper-turn-right-active"
            },
            currentStatus:'default'
          },
          {
            name: "垂直翻转",
            type: "flipVertical",
            action: "",
            iconClass: {
              default: "icon-cropper-flip-vertical-default",
              white:"icon-cropper-flip-vertical-white",
              active:"icon-cropper-flip-vertical-active"
            },
            currentStatus:'default'
          },
          {
            name: "水平翻转",
            type: "flipHorizintal",
            action: "",
            iconClass: {
              default: "icon-cropper-flip-horizintal-default",
              white:"icon-cropper-flip-horizintal-white",
              active:"icon-cropper-flip-horizintal-active"
            },
            currentStatus:'default'
          },
          {
            name: "撤销",
            type: "backout",
            action: "",
            iconClass: {
              default: "icon-cropper-backout-default",
              white:"icon-cropper-backout-white",
              active:"icon-cropper-backout-active"
            },
            currentStatus:'default'
          },
          {
            name: "重做",
            type: "redo",
            action: "",
            iconClass: {
              default: "icon-cropper-redo-default",
              white:"icon-cropper-redo-white",
              active:"icon-cropper-redo-active"
            },
            currentStatus:'default'
          },
        ]
      },
    };
  },
  computed: {
    operateLogLen() { //操作记录
      return this.cropperData.operateLog && this.cropperData.operateLog.length || 0
    },
  },
  watch: {
    src(n) {
      // console.log('n',n)
      if(n) {
        this.tapCropperTools({type:'replace',url:n}, -1);
      }
    }
  },
  created() {
    this.$nextTick(()=>{
      // 启用图片裁切
      const image = document.getElementById('cropper-img');
      const cropper = new Cropper(image, {
        width: 500,
        height: 300,
        minWidth: 563,
        minHeight: 360,
        maxWidth: 563,
        maxHeight: 360,
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
        viewMode:1,
        autoCrop:false, //不默认裁剪
        // aspectRatio: 16 / 16,
        // checkCrossOrigin: false, //不检查是否跨域
        crop: (event)=>{
          // console.log(event.detail);
          // console.log(event.detail.y);
          // console.log(event.detail.width);
          // console.log(event.detail.height);
          // console.log(event.detail.rotate);
          // console.log(event.detail.scaleX);
          // console.log(event.detail.scaleY);
          if(this.cropperData.cropperTools[0].currentStatus === "default" && event.detail.y !== 0 && event.detail.width !== 0 ) {
            // 裁切回调
            // event.detail.y !== 0 && event.detail.width !== 0 排除 crop.clear()所触发的回调
            // // 记录裁切状态
            let operate = {
              action: "crop",
              args: ""
            };
            this.cropperData.operateLog.push(operate);
            this.cropperData.cropperTools[0].currentStatus = "white";
          }
          this.cropperData.detail = event.detail;
          if(this.cropperData.initDeatil == null) {
            this.cropperData.initDeatil = event.detail;
          }
        },
        cropend: ()=>{
          let detail = this.cropperData.detail;

          this.$emit('cropChange',{detail:detail});
        }
      });
      this.cropperData.element = cropper;
    })
  },
  beforeDestroy() {
    this.cropperData.element && this.cropperData.element.destroy();
  },
  methods: {
    tapCropperTools(item,index) {
      //裁切类型
      // console.log('tapCropperTools',item,index)
      let { type, url } = item;
      let {height, width, x, y, rotate, scaleX, scaleY, } = this.cropperData.detail;
      let status = index >= 0 ? this.cropperData.cropperTools[index].currentStatus : null;
      let cropper = this.cropperData.element;
      //记录操作详情
      let operate = {
        action:"",
        args:""
      };
      if(type === "cutting") { 
        this.cropperData.cropperTools[index].currentStatus = status != "white" ? "white" : "default";
      }
      switch(type) {
        case "cutting":
          // 裁剪
          if(status !== 'white') {
            cropper.crop();
            operate.action = "crop";
          }else {
            cropper.clear();
            operate.action = "clear";
          }
          break;
        case "turnLeft":
          // 向左转
          cropper.rotate(-90);
          operate.action = "rotate";
          operate.args = "-90";
          break;
        case "turnRight":
          cropper.rotate(90);
          operate.action = "rotate";
          operate.args = "90";
          // 向右转
          break;
        case "flipVertical":
          // 垂直翻转
          cropper.scaleY( scaleY == -1 ? 1 : -1);
          operate.action = "scaleY";
          operate.args = scaleY == -1 ? 1 : -1;
          break;
        case "flipHorizintal":
          // 水平翻转
          cropper.scaleX( scaleX == -1 ? 1 : -1);
          operate.action = "scaleX";
          operate.args = scaleX == -1 ? 1 : -1;
          break;
        case "replace":
          // console.log('替换', url)
          cropper.replace(url);
          operate.action = "replace";
          operate.args = url;
          operate.lastUrl = this.cropperData.backUrl || this.url;
          this.cropperData.backUrl = url;
          break;
        case "backout":
          // 撤销
          let logs = this.cropperData.operateLog;
          let last = logs[logs.length-1]; //最后一个操作
          if(logs.length >= 1) {
            // 最后一个操作
            this.toBackOut(last, logs.length-1);
            this.cropperData.operateLog.pop();
          }
          break;
        case "redo":
          // 重做 - 撤销所有操作 ，绑定数据值恢复
          // cropper.reset();
          this.cropperData.operateLog = [];
          operate.action = "reset";
          operate.args = "";
          cropper.clear();
          this.cropperData.cropperTools[0].currentStatus = "default";
          this.cropperData.initDeatil && cropper.setData(this.cropperData.initDeatil);
          if(this.cropperData.backUrl) { //恢复原图
            cropper.replace(this.url);
          }
          break;
      }
      if(type !== "backout" && type !== "redo"){
        this.cropperData.operateLog.push(operate)
      }
    },
    toBackOut(item ,index) {
      //撤销操作
      let { action, args, lastUrl } = item;
      // console.log('',this.cropperData.cropperTools,index)
      let status = this.cropperData.cropperTools[0].currentStatus; //第一个状态
      let cropper = this.cropperData.element;
      let backOperate = {
        action: "",
        args: ""
      };
      switch( action ) {
        case "rotate":
          // 左右转
          backOperate.action = "rotate";
          backOperate.args = args == "90" ? -90 : 90;
          break;
        case "scaleY":
          // 垂直翻转
          backOperate.action = "scaleY";
          backOperate.args = args == 1 ? -1 : 1;
          break;
        case "scaleX":
          // 水平翻转
          backOperate.action = "scaleX";
          backOperate.args = args == 1 ? -1 : 1;
          break;
        case "clear":
          // 去掉裁切
          backOperate.action = "crop";
          break;
        case "crop":
          // 开始裁切
          backOperate.action = "clear";
          break;
        case "replace":
          backOperate.action = "replace";
          break;
      }
      // 重置无法撤销
      if(action === "reset") return;
      // 撤销操作
      if(action === 'replace') {
        cropper[backOperate.action]( lastUrl );
        this.cropperData.backUrl = lastUrl === this.url ? "" : lastUrl;
        setTimeout(() => {
            // cropper.crop();
            // cropper.restore();
          },10)
      }else {
        cropper[backOperate.action]( backOperate.args );
      }
      // 恢复状态
      if(backOperate.action === "crop" || backOperate.action === "clear") { 
        this.cropperData.cropperTools[0].currentStatus = status != "white" ? "white" : "default";
      }
    },
    resetCrop() {
      // 取消裁剪
      this.tapCropperTools(this.cropperData.cropperTools[0], 0);
    },
    doneCrop() {
      // 完成裁剪
      let cropper = this.cropperData.element;
      let url = cropper.getCroppedCanvas().toDataURL();
      // console.log('url',url)
      this.tapCropperTools({
        url: url,
        type: "replace",
      },-1);
      setTimeout(() => {
        // this.resetCrop();
        cropper.clear();
      }, 10);
    },
    checkedImg() {
      // 图片改变，获取图片数据，初始化一些值
    },
    refresh() {
      // 重置函数， 恢复组件置初始状态
    },
    getPhotoFile() {
      // 获取当裁切后的图片
      let cropper = this.cropperData.element;
      let logLen = this.cropperData.operateLog.length;
      if(logLen === 0) {
        return {
          file:null,
          isEdit:false
        }
      }
      let file = dataURLtoFile(cropper.getCroppedCanvas().toDataURL());
      return {
        file,
        isEdit:true
      }
    },
    destroyPhoto() {
      // 销毁图片 裁切框部分
      this.cropperData.element && this.cropperData.element.destroy();
    },
    changePhotoWH({width,height,ratioW,ratioH}) {
      // 修改图片选区、宽高比例
      let cropper = this.cropperData.element;
      if(ratioW && ratioH) {
        // 修改宽高比
        let ratio = ratioW/ratioH;
        cropper.setAspectRatio(ratio)
      }else {
        //恢复自由比例
        cropper.setAspectRatio(NaN);
      }
      if(width && height) {
        // 修改选取宽高
        let data = cropper.getData();
        data.width = Number(width);
        data.height = Number(height);
        cropper.setData(data);
      }
    },
  }
}
</script>

<style lang="less" scoped>
  // 图片
  .photo-cropper-container {
    padding-top: 24px;
    flex-shrink: 0;
    width: 100%;
    height: 468px;
    border: 1px solid #d4d4d4;
    background: #FFF;
    // 裁切范围
    .photo-cropper-wrapper {
      width: 100%;
      height: 360px;
    }
    .photo-cropper-img {
      width: 100%;
      height: 360px;
      object-fit: contain;
    }
    // 裁切工具
    .photo-cropper-tools {
      display: flex;
      align-items: center;
      border-top: 1px solid #D4D4D4;
      width: 100%;
      height: 78px;
      .item {
        flex:1;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .cropper-icon,
          .cropper-name {
            color: #E74362;
          }
        }
        &.active {
          background-color: #E74362;
          .cropper-name {
            color: #FFFFFF;
          }
        }
        &.disabled {
          .cropper-icon,
          .cropper-name {
            color: #a1a1a1;
          }
        }
      }
    }
    // 取消完成
    .photo-cropper-done {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-right: 3px;
      width: 100%;
      height: 32px;
      background-color: #FFF;
      .btn {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
      .cancel {
        color: #A1A1A1;
      }
      .done {
        color: #E74362;
      }
    }
  }
</style>