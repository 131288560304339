import { getSchema,GetSchemaCourse, saveCourse, getCourseList, getCourseDetail, 
  createCourse, getTree, moveNode, getHomeSetting,
  delCourse, renameCourse, getCourseTagList,
  copyCourse,getShareCourseList } from '@/libs/api/course';
import { getSchemaDefaul, appendStyle, getTreeLayer } from '@/libs/utils/tools';

// 对课程数据集合，限制数量
function removeOldHistoryData(stateNameList, retainCourseIds, state) {
  stateNameList.forEach( name => {
    Object.keys( state[name] ).forEach( course_id => {
      if( retainCourseIds.indexOf( course_id ) === -1 ) delete state[name][course_id]
    } );
  } );
}

const course = {
  state: {
    courseMenuName:'',
    courseThemeName:'',

    token: null,
    schemaJson: {},
    couseListTotal:0,
    courseList: [],
    defaultVal: {},
    courseDefault:{
      "course-displayTitle":"未命名",
      "course-subtitle":"",
      "course-body": "",
      "course-_boxMenu-properties-_menuHeader-properties-_minimumHeights-properties-_large": 0,
      "course-_boxMenu-properties-_menuHeader-properties-_backgroundImage-properties-_large":"none",
      "course-_boxMenu-properties-_menuHeader-properties-_backgroundStyles-properties-_backgroundRepeat": "no-repeat",
      "course-_boxMenu-properties-_menuHeader-properties-_backgroundStyles-properties-_backgroundSize":"",
      "course-_boxMenu-properties-_menuHeader-properties-_backgroundStyles-properties-_backgroundPosition":"",
      "course-_boxMenu-properties-_backgroundStyles-properties-_backgroundSize":"",
      "course-_boxMenu-properties-_backgroundStyles-properties-_backgroundPosition":"",
      "course-_boxMenu-properties-_backgroundStyles-properties-_backgroundRepeat":"",
      "course-_boxMenu-properties-_backgroundImage-properties-_large":"",
      "course-_lockType":"",
      "course-_isOptional":"",
      "course-_isAvailable":"",
      "course-_isHidden":"",
      "course-_isVisible":"",
      "course-_requireCompletionOf":"",
    },
    // 课程集合-课程详情
    CourseCollection: {

    },
    // 集合：course-头部页面绑定 - 所见即所得区域
    HeadFormCollection: {

    },
    CourseFormCollection: {

    },
    // 课程首页设置
    FirstPageDetailCollection: {

    },
    // 课程-目录树
    CourseTreesCollection: {

    },
    lazyImage: true, //是否懒加载图片
    // 课程-自定义css
    CustomStyleCollection: {},
    // 课程-主题色
    ThemeVariableCollection: {},
    // 课程-目录层级
    CourseLayerCollection: {},
    // 课程详情存储 最新查看记录
    CourseHistory:[],

    //所有的内容页
    AllContentObj:[],
  },

  getters: {
    // 获取课程所属主题
    getCourseTheme:(state)  =>(course_id) =>{
        let theme =  state.courseList.filter(item=>item.course_id == course_id);
        return theme[0].theme_key;
    },

    // 获取课程详情
    getCourseDetailById: (state) => (course_id) => {
      return state.CourseCollection[course_id]
    },
    // 获取courseForm
    getCourseFormById: (state) => (course_id) => {
      return state.CourseFormCollection[course_id]
    },
    // 获取首页设置
    getFirstPageById: (state) => (course_id) => {
      return state.FirstPageDetailCollection[course_id]
    },
    // 获取课程-目录
    getTreesById: (state) => (course_id) => {
      return state.CourseTreesCollection[course_id]
    },
    // 获取课程-自定义css
    getCustomStyleById: (state) => (course_id) => {
      return state.CustomStyleCollection[course_id]
    },
    // 获取课程-主题色
    getThemeById: (state) => (course_id) => {
      return state.ThemeVariableCollection[course_id]
    },
    // 获取课程-目录层级
    getLayerById: (state) => (course_id,content_id) => {
      return state.CourseLayerCollection[course_id]
    },
  },

  mutations: {
    UPDATE_DATA:(state,data)=>{ //更新课程数据
      state.courseList = data;
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SCHEMA(state, data) {
      state.schemaJson = data;
    },
    SET_LIST(state, data) { //课程列表
      state.couseListTotal = data.length;
      state.courseList = data;
    },
    INIT_COURSE_RELATIVE(state,{course_id,content_id}) { //初始化课程-相关数据 空对象
      // 以下集合名，是否有对应课程id数据
      let relativeArr = ['CourseCollection','CourseFormCollection','HeadFormCollection','FirstPageDetailCollection','CourseTreesCollection','CourseLayerCollection'];
      relativeArr.forEach( v => {
        if( state[v][course_id] == undefined ) {
          let temp = JSON.parse(JSON.stringify(state[v]));
          temp[course_id] =  v==='CourseTreesCollection' ? [] : {};
          state[v] = temp;
        }
      } );
      // 最新查看课程记录
      state.CourseHistory.unshift(course_id);
      // 去重
      state.CourseHistory = Array.from( new Set( state.CourseHistory ) );
      state.CourseHistory.length = 5;
    },
    INIT_COLLECTION(state, data) { //初始化集合数据 - 以课程id为索引的集合
     
      let copyCourses = JSON.parse(JSON.stringify(state.CourseCollection));
      let copyHeadForm = JSON.parse(JSON.stringify(state.HeadFormCollection));
      let copyCourseForm = JSON.parse(JSON.stringify(state.CourseFormCollection));
      let copyFirstPageDetail = JSON.parse(JSON.stringify(state.FirstPageDetailCollection));
      let copyCourseTrees = JSON.parse(JSON.stringify(state.CourseTreesCollection));
      let copyCourseLayer = JSON.parse(JSON.stringify(state.CourseLayerCollection));
      data.forEach( v => {
        if(copyCourses[v.course_id] == undefined) copyCourses[v.course_id] = {};
        if(copyHeadForm[v.course_id] == undefined) copyHeadForm[v.course_id] = {};
        if(copyCourseForm[v.course_id] == undefined) copyCourseForm[v.course_id] = {};
        if(copyFirstPageDetail[v.course_id] == undefined) copyFirstPageDetail[v.course_id] = {};
        if(copyCourseTrees[v.course_id] == undefined) copyCourseTrees[v.course_id] = [];
        if(copyCourseLayer[v.course_id] == undefined) copyCourseLayer[v.course_id] = [];
      } );
      state.CourseCollection = copyCourses;
      state.HeadFormCollection = copyHeadForm;
      state.CourseFormCollection = copyCourseForm;
      state.FirstPageDetailCollection = copyFirstPageDetail;
      state.CourseTreesCollection = copyCourseTrees;
      state.CourseLayerCollection = copyCourseLayer;
    },
    SET_TOTAL(state, data) { //课程列表 总数
      state.couseListTotal = data;
    },
    SET_DETAIL(state, detail) { //课程详情
      let { course_id } = detail;
      state.AllContentObj = detail.contentobject_list;
      state.CourseCollection[course_id] = detail;
      state.CourseFormCollection[course_id] = detail.course;
      state.CustomStyleCollection[course_id] = detail.course['course-customStyle'] || "";
      let styleObj = state.ThemeVariableCollection[course_id] || {};
      Object.keys(detail.course).forEach( v => {
        if(v.indexOf("course__themeVariables__") > -1) {
          styleObj[v] = detail.course[v]
        }
      });
      state.ThemeVariableCollection[course_id] = styleObj;

      // 限制保存数量处理
      removeOldHistoryData( 
        ['CourseCollection','CourseFormCollection','CustomStyleCollection','ThemeVariableCollection'],
        state.CourseHistory,
        state
      );
    },
    SET_THEME_VARIABLES(state, detail) {
      // 最外层样式 - 当前获取了主题的颜色表
      let styleSet = {};
      let propSet = Object.keys(detail).filter(v => v.indexOf("course__themeVariables___")>-1);
      propSet.forEach(v => {
        let prop = this.getShortPropOfTheme(v);
        styleSet[prop] = detail[v];
      })
    },
    SET_DEFAULT(state, data) {
      state.defaultVal = data;
    },
    SET_COURSE_FORM(state, data) {
      //课程头部表单
      let { course_id } = data;
      state.HeadFormCollection[course_id] = data;

      // 限制保存数量处理
      removeOldHistoryData( 
        ['HeadFormCollection'],
        state.CourseHistory,
        state
      );
    },
    SET_COURSE_TREES(state, { data, course_id }) {
      state.CourseTreesCollection[course_id] = data;

      // 限制保存数量处理
      removeOldHistoryData( 
        ['CourseTreesCollection'],
        state.CourseHistory,
        state
      );
    },
    SET_COURSE_TREE_LAYER(state, { data, course_id }) {
      // 课程目录-所有页面/主题 在第几层
      state.CourseLayerCollection[course_id] = data;

      // 限制保存数量处理
      removeOldHistoryData( 
        ['CourseLayerCollection'],
        state.CourseHistory,
        state
      );
    },
    SET_FIRSTPAGE(state, {data,course_id}) {
      //课程首页设置
      state.FirstPageDetailCollection[course_id] = data;

      // 限制保存数量处理
      removeOldHistoryData( 
        ['FirstPageDetailCollection'],
        state.CourseHistory,
        state
      );
    },
    CLEAR_COURSE_DATA(state) {
      // 清空课程详情等数据
    },
    SET_LAZY_IMAGE: (state, value) => {
      state.lazyImage = value
    },
    SET_CUSTOM_CSS: (state, { data,course_id }) => {
      // 设置课程通用css
      state.CustomStyleCollection[course_id] = data;

      removeOldHistoryData( 
        ['CustomStyleCollection'],
        state.CourseHistory,
        state
      );
    },
    SET_THEME: (state, { data,course_id }) => {
      // 设置课程主题色
      state.ThemeVariableCollection[course_id] = data;

      removeOldHistoryData( 
        ['ThemeVariableCollection'],
        state.CourseHistory,
        state
      );
    },
  },

  actions: {
    //合并新增课程
    ConcatCourses({commit,state},data){
      commit('UPDATE_DATA',data);
    },
    // 获取基本数据
    GetSchema({commit,state}) {
      return new Promise((resolve, reject)=>{
        getSchema().then((res)=>{
          if(res.errorcode == 0) {
            commit('SET_SCHEMA',res.data);
            // 默认值
            // let defaultObj = getSchemaDefaul(state.schemeaJson);
            // commit('SET_DEFAULT', defaultObj);
            
          }
          resolve(res)
        }).catch((err)=>{
          reject(err)
        });
      })
    },
    //获取不同课程主题的基本数据
    GetSchemaCourse({commit,state},{course_id}){
      let name = state.courseList.filter(item=>item.course_id == course_id)[0].theme_key;
      return new Promise((resolve, reject)=>{
        GetSchemaCourse(name).then((res)=>{
          if(res.errorcode == 0) {
            commit('SET_SCHEMA',res.data);
            // 默认值
            // let defaultObj = getSchemaDefaul(state.schemeaJson);
            // commit('SET_DEFAULT', defaultObj);
            
          }
          resolve(res)
        }).catch((err)=>{
          reject(err)
        });
      })
    },
    // 保存课程数据
    SaveCourse({ commit, state, dispatch },data) {
      return new Promise((resolve,reject)=>{
        let params = {
          "page_key":data["page_key"],
          "course_id":data["course_id"],
          "properties_data":data["properties_data"],
          "theme_type":data["theme_type"]?data["theme_type"]:'feidao-vanilla-theme',
        };
        let pageIds ={
          "contentobject-title": "contentobject_id",
          "contentobject-content": "contentobject_id",
          "contentobject-style-right": "contentobject_id",
          "contentobject-style": "contentobject_id",
          "contentobject-completion-conditions": "contentobject_id",
          "contentobject-bookmarking": "contentobject_id",
          "article": "article_id",
          "block": "block_id",
          "contentobject-navigation-bar": "contentobject_id",
        };
        if(pageIds[data["page_key"]]) {
          params[ pageIds[data["page_key"]] ] = data[ pageIds[data["page_key"]] ]
        }
        if(data['component_id']) {
          params['component_id'] = data['component_id']
        }
        if(!!data["updateContentObject"] && data["contentobject_id"]) {
          params["contentobject_id"] = data["contentobject_id"];
        }
        // 只修改了主题颜色相关
        let onlyThemeChange = Object.keys(data["properties_data"]).findIndex(v => v.indexOf('themeVariables') === -1);
        let changeTheme = Object.keys(data["properties_data"]).findIndex(v => v.indexOf('themeVariables') !== -1);
        //是否已经更新课程详情
        let hasUpdateCourseDetail = false;
        // 是否改变了自定义css
        let changeCustomStyle = data["properties_data"].hasOwnProperty("course-customStyle");
        // 是否改变了 html Class 
        let changeHtmlClass = data["properties_data"].hasOwnProperty("course-_htmlClasses");

        saveCourse(params).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            
            if( data.refreshOther !== false ) {
              // 刷新
              switch(data["page_key"]) {
                case "index-index":
                case "course-style-right":
                case "course-style":
                  //首页 - 课程数据
                  hasUpdateCourseDetail = true;
                  dispatch('GetCourseDetail',{course_id:data["course_id"],refreshOther: onlyThemeChange !== -1});
                  break;
                case "contentobject-title":
                  // 主题页
                  dispatch('GetContentObjectOne',{course_id: data["course_id"], contentobject_id: data["contentobject_id"],isMenu: true});
                  break;
                case "contentobject-content":
                case "contentobject-style-right":
                  // 内容页
                  dispatch('GetContentObjectOne',{course_id: data["course_id"], contentobject_id: data["contentobject_id"],isMenu: false});
                  // 修改了主题设置
                  if(changeTheme !== -1) {
                    hasUpdateCourseDetail = true;
                    dispatch('GetCourseDetail',{course_id:data["course_id"],refreshOther:false});
                  }
                  break;
              }
            }
            // 更新了页面标题
            let hasProps = function(obj, propName) {
              return Object.prototype.hasOwnProperty.call(obj, propName)
            }
            if(!!data['isLeftUpdate'] && data["page_key"] != "course-style" 
              || hasProps(data["properties_data"], "contentobject-title") 
              || hasProps(data["properties_data"], "course-title")) 
            {
              dispatch('GetTree',data["course_id"]);
            }
            // 更新了主题颜色， 并且未更新课程详情
            // let updateProgressColor = Object.keys(data["properties_data"]).findIndex( v =>v.indexOf("course__themeVariables___progress") > -1 );
            if(changeTheme !== -1 && !hasUpdateCourseDetail) {
              dispatch('GetCourseDetail',{course_id:data["course_id"],refreshOther:false});
            }
            // 附加style到head
            if(changeCustomStyle) {
              appendStyle({style:res.data['course-customStyle'], document});
            }
          }
          reject(res);
        }).catch((err=>{
          reject(err);
        }));
      })
    },
    // 重命名课程名称
    RenameCourse({ dispatch },{course_id,title}) {
      return renameCourse(course_id,title);
    },
    // 获取课程列表
    GetCourseList({commit}, {keyword, sort_mode, page, limit, ...args}) {
      
      return new Promise((resolve, reject)=>{
        getCourseList({keyword,sort_mode, page, limit, ...args}).then(res=>{
          resolve(res);
          if(res.errorcode == 0) {
            commit("SET_LIST", res.data.data);
            // commit("INIT_COLLECTION", res.data.data); //暂不用
          }else {
            commit("SET_TOTAL", -1);
          }
        }).catch(err=>{
          commit("SET_TOTAL", -1);
          reject(err)
        });
      });
    },
    // 获取课程详情
    GetCourseDetail({commit, state, dispatch}, {course_id, refreshOther = true}) { //refreshOther:是否刷新其他请求
      return new Promise((resolve,reject)=>{
        getCourseDetail(course_id).then((res)=>{
          if(res.errorcode == 0) {
            commit('SET_DETAIL', res.data)
            if(res.data.course) {
              let courseForm = {};
              for(let key in state.courseDefault) {
                courseForm[key] = res.data.course[key]
              }
              courseForm['course_id'] = res.data['course_id'];
              commit('SET_COURSE_FORM',courseForm)
            }
          }
          resolve(res);
        }).catch((err)=>{
          reject(err);
        });
        if(refreshOther) {
          dispatch('GetTree',course_id)
          dispatch('GetContentObjectSonList',{course_id})
        }
      })
    },
    // 获取目录树
    GetTree({commit}, course_id) {
      return new Promise((resolve,reject)=>{
        getTree(course_id).then(res=>{
          resolve(res);
          if(res.errorcode == 0) {
            commit("SET_COURSE_TREES", {data:res.data.contentobject_tree,course_id });

            let treeLayer =  getTreeLayer( { data:res.data.contentobject_tree } );
            commit("SET_COURSE_TREE_LAYER", {data:treeLayer,course_id });
          }
        }).catch(err => {
          reject(err);
        });
      })
    },
    // 获取课程 - 首页设置
    GetHomeSetting({commit}, course_id) {
      getHomeSetting(course_id).then(res => {
        if(res.errorcode == 0) {
          commit("SET_FIRSTPAGE",{data:res.data.course && res.data.course._start || {}, course_id});
        }
      })
    },
    // 移动目录树-节点
    MoveNode({dispatch}, {course_id, _parentId, contentobject_id, move_type, below_contentobject_id}) {
      return new Promise((resolve, reject)=>{
        moveNode({course_id, _parentId, contentobject_id, move_type, below_contentobject_id}).then(res => {
          if(res.errorcode == 0) {
            dispatch("GetTree", course_id);
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },
    // 创建课程
    CreateCourse({commit, state, dispatch},{title,theme_id}) {
      return new Promise((resolve,reject)=>{
        createCourse({title,theme_id}).then((res)=>{
          if(res.errorcode == 0) {
            dispatch('CreateContentObject',{course_id: res.data.course_id, _parentId: res.data.course_id, _type: 'page' });
            resolve(res);
          }
          resolve(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 删除课程
    DelCourse({commit, state, dispatch}, course_id) {
      return new Promise((resolve,reject)=>{
        delCourse(course_id).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 复制课程copyCourse
    CopyCourse({commit, state, dispatch}, course_id) {
      return new Promise((resolve,reject)=>{
        copyCourse(course_id).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 获取课程 标签列表
    GetCourseTagList({commit},tag_name) {
      return new Promise((resolve, reject) => {
        getCourseTagList(tag_name).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      });
    },
  }
}

export default course
