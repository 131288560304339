import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function getContentObjectList(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/getList?course_id="+id
  })
}

export function getContentObjectSonList(course_id,contentobject_id) { //contentobject_id 当获取非首页之外的，需要传参
  let url = "/api/"+ Config.apiversion +"/courses/contentobject/getSonList?course_id="+course_id;
  if(contentobject_id) {
    url = url + "&contentobject_id=" + contentobject_id;
  }
  return request.get({
    url: url
  })
}
  
export function getContentObjectOne(course_id, contentobject_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/getOne?course_id="+course_id +"&contentobject_id=" + contentobject_id
  })
}

// 复制
export function copyContentObjectOne(course_id, contentobject_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/copy?course_id="+course_id +"&contentobject_id=" + contentobject_id
  })
}

export function createContentObject({course_id,_parentId,_type}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/create",
    data: {
      course_id,
      _parentId,
      _type
    }
  })
}

export function sortContent(args) {
  let url = "/api/"+ Config.apiversion +"/courses/contentobject/sort/vertical";
  let allowField = ["course_id","_parentId","contentobject_id","sort_type"];
  allowField.forEach(v => {
    if(args[v] != undefined) {
      if(url.indexOf('?') > -1) {
        url = url + `&${v}=${args[v]}`
      }else {
        url = url + `?${v}=${args[v]}`
      }
    }
  })
  return request.get({
    url: url,
  })
}

export function updateIndexSet({course_id, contentobject_id, status, _skipIfComplete, _className}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/setIndex",
    data: {
      course_id, 
      contentobject_id, 
      status, 
      _skipIfComplete
    }
  })
}

export function delContentObjectOne({course_id, contentobject_id}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/delete",
    data: {
      course_id,
      contentobject_id
    }
  })
}

// 获取contentobject内章节组块组件的数量
export function getContentTotal(course_id, contentobject_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/getContentTotal?course_id="+course_id +"&contentobject_id=" + contentobject_id
  })
}