import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes';
import { getToken } from '@/libs/utils/auth'

Vue.use(VueRouter);

const router = new VueRouter({
  // scrollBehavior: () => ({ y: 0 }), 
  routes,
});
// 不拦截路由名
const permissionPathName = ["Login", "Register", "Forget","ThirdLogin","ShareEntry","Webview","ShareCourseOfPreview",'EnView','Invalid'];

let isAuthenticated = false;

// 全局的路由守卫
router.beforeEach(async (to, from, next) => {
  isAuthenticated = await getToken();
  if (!permissionPathName.includes(to.name) && !isAuthenticated) next({ name: 'Login' })
  else if(to.name === 'Login' && isAuthenticated) next({ name: 'Home' })
  else next()
});

/* Execute after jump
导航守卫，在router每次跳转完成后执行 */
router.afterEach(() => {});

export default router;
