import ACE from './scaffold/scaffoldCodeEditor.vue'
import Asset from './scaffold/scaffoldAsset.vue'
import Ckeditor from './scaffold/scaffoldEditHtml.vue'
// 从el-dialog复制出来只改了一行
import Dialog from './common/dialog.vue'
import Drawer from './common/drawer.vue'
// 从el-color-picker 复制
import ColorPicker from './packages/color-picker/main.vue'
import Tree from './packages/tree/tree.vue'
// 从dialog复制出来做了些修改
import CustomDialog from './common/customDialog.vue'
import UpgradeDialog from './common/upgradeDialog.vue'
import FdLoading from './common/fdLoading.vue'
import tempDialog from './common/tempDialog.vue'
import hintDialog from './common/hintDialog.vue'

export default {
    install: function(Vue, options) {
        Vue.component('ace', ACE);
        Vue.component('assetupload', Asset);
        Vue.component('ckeditor', Ckeditor);
        Vue.component('fd-dialog', Dialog);
        Vue.component('fd-drawer', Drawer);
        Vue.component('fd-color-picker', ColorPicker);
        Vue.component('fd-tree', Tree);
        Vue.component('fd-custom-dialog', CustomDialog);
        Vue.component('fd-upgrade-dialog', UpgradeDialog);
        Vue.component('fd-loading', FdLoading);
        Vue.component('temp-dialog', tempDialog);
        Vue.component('hint-dialog', hintDialog);
    }
}