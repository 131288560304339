<template>
  <div class="scaffold-asset-container" :style="assetContainerStyle">
    <div
        ref="imgUpload"
        name="file"
        class="bgimg-uploader pointer"
        @click.stop="selectAsset"
        >
        <div v-if="imageUrl" class="prel width_100p height_100p" >
          <!-- +'?x-oss-process=style/s' -->
            <img  :src="small_imageUrl"  class="bgimg" loading="lazy"  />
            <div class="scaffold-asset-actions flex flex_acenter flex_center" @click.stop="selectAsset">
                <span
                    class="scaffold-asset-preview"
                    @click.stop="handlePictureCardPreview"
                >
                    <i class="icon-preview-photo fontsize_18"></i>
                </span>
                <span
                    class="scaffold-asset-delete"
                    @click.stop="handleRemove"
                    v-if="!required"
                >
                    <i class="el-icon-delete fontsize_18"></i>
                </span>
                <span
                    class="scaffold-asset-change"
                    @click.stop="selectAsset"
                    v-else
                >
                    <i class="icon-change-photo fontsize_18"></i>
                </span>
            </div>
        </div>
        <div v-if="!imageUrl" class="flex flex_acenter ">
            <i class="el-icon-plus bgimg-uploader-icon fontsize_14 color_999" ></i>
            <span class="fontsize_14 padding_left8 color_A1A1A1">{{uploadText || '添加背景图'}}</span>
        </div>
    </div>
    <fd-dialog :visible.sync="dialogVisible" :modal-append-to-body="true" :append-to-body="true">
        <el-image :src="dialogImageUrl" fit="contain" style="object-fit:contain;height:450px;width:100%;" lazy ></el-image>
    </fd-dialog>
    <mediaSelectDialog 
      :visible.sync="coverDialogSet.visible" 
      :title="coverDialogSet.title" 
      :fromDetail="coverDialogSet.item" 
      :mediaType="coverDialogSet.mediaType"
      :appendToBody="true"
      @select="selectCover"  @close="closeMediaDialog"></mediaSelectDialog>
  </div>
</template>

<script>
// 通过媒体列表选择
import mediaSelectDialog from '@/renderer/components/common/mediaSelectDialog';
export default {
  props: {
    src: {
      type: String
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: 117
    },
    bgColor: {
      type: String,
      default: "#FFF"
    },
    required: {
      type: [Boolean, Number],
      default: false
    },
    uploadText: {
      type: String,
      default: "添加背景图"
    }
  },
  components: {
    mediaSelectDialog
  },
  data() {
    return {
      token:null,
      imageUrl: this.src,
      dialogVisible: false,
      dialogImageUrl: "",
      coverDialogSet: { //修改封面弹窗
        title:"媒体库",
        visible: false,
        item:{},
        mediaType:'picture'
      },
    };
  },
  computed: {
    assetContainerStyle() {
      return {
        width: this.px(this.width),
        height: this.px(this.height),
        backgroundColor: this.bgColor
      }
    },
    small_imageUrl() {
      if(this.imageUrl && this.imageUrl.indexOf("x-oss-process=style/") == -1 ) {
        if(this.imageUrl.indexOf("?") >= 0) return this.imageUrl + "&x-oss-process=style/m";
        return this.imageUrl + "?x-oss-process=style/m";
      }
      return this.imageUrl;
    },
  },
  watch: {
    src: {
      handler(n,o) {
        if(n) {
          this.imageUrl = n;
        }else{
          //同步更新
          this.$nextTick(() => {
            this.imageUrl = "";
            this.dialogImageUrl = "";
          });
        }
      },
      immediate: true
    }
  },
  async created() {
  },
  methods: {
    selectAsset() {
      // 显示媒体列表选择
      this.coverDialogSet.visible = true;
    },
    selectCover({item, fromDetail}) {
      //选择图片作为背景图
      this.imageUrl = item.url;
      this.$emit('change', item.url)
      this.closeMediaDialog();
    },
    closeMediaDialog() {
      // 关闭弹窗/后
      this.coverDialogSet.visible = false;
      this.coverDialogSet.item = {};
    },
    handlePictureCardPreview(file) {
      // 预览图片
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    },
    handleRemove() {
      // 移除图片
      this.$emit('change', "")
      this.imageUrl = "";
      this.dialogImageUrl = "";
    },
    px:function (n) {
      if( /^\d*$/.test(n) ){
        return n+"px";
      }
      return n;
    }
  },
}
</script>

<style lang="less" scoped>
.bgimg-uploader {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    /deep/.el-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .bgimg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}
.scaffold-asset-actions {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
.scaffold-asset-preview,
.scaffold-asset-delete,
.scaffold-asset-change {
    padding: 5px;
}
.scaffold-asset-delete {
    padding: 5px;
}
.el-icon-zoom-in,
.el-icon-delete {
    color: #ffffff;
}
</style>