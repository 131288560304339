import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function getLanguageList() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/lang/list"
  })
}
// 预览
export function previewCourse(course_id) {
  return request.get({
    url: "/api/"+ "V2" +"/preview/course?course_id="+course_id,
    showMsg: false
  })
}
// 获取下载key值
export function getCourseKey(course_id) {
  return request.get({
    url: "/api/"+ "V2" +"/preview/downloadCourse?course_id="+course_id,
  })
}
//不用，仅作跳转
export function downloadCourse(download_key) {
  return request.get({
    url: "/api/"+ "V2" +"/preview/downloadByKey?download_key="+download_key,
  })
}

export function appUpdate({type, version}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/appupdate?type="+type + "&vision="+version,
    showMsg: false
  })
}