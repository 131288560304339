import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import infiniteScroll from "vue-infinite-scroll";
import componentsInstall from './components/install'
import { navAndOpenTagPage, isElectron } from '@/libs/utils/tools'
import VueClipboard from 'vue-clipboard2'
// 代码编辑器
import ace from 'ace-builds'
import 'ace-builds/webpack-resolver' 
import 'ace-builds/src-noconflict/theme-monokai' // 默认设置的主题
import 'ace-builds/src-noconflict/mode-javascript' // 默认设置的语言模式

// 引入组件和样式
import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";
// web不引入 以下在打包时不生效
// if (!!process.env.IS_ELECTRON) require("./styles/electron.less");
if ( isElectron() ) require("./styles/electron.less");


import {
  Button,
  Drawer,
  Message,
  MessageBox,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
  Tabs,
  TabPane,
  Tree,
  Upload,
  Dialog,
  Breadcrumb,
  BreadcrumbItem,
  Loading,
  Tag,
  Image,
  Progress,
  ColorPicker,
  CheckboxGroup,
  Checkbox,
  Card,
  Switch,
  Container,
  Header,
  Aside,
  Main,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Slider,
  DatePicker,
  Scrollbar,
  Table,
  TableColumn
} from "element-ui";

Vue.config.productionTip = false;
if (!!process.env.IS_ELECTRON) Vue.use(require("./vue_electron.js"));
Vue.use(VueI18n);
Vue.use(RouterTab);
Vue.use(ace);
Vue.use(componentsInstall)

Vue.use(Button);
Vue.use(Col);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Submenu);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tree);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tag);
Vue.use(Image);
Vue.use(Progress);
Vue.use(ColorPicker);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Card);
Vue.use(Switch);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Slider);
Vue.use(DatePicker);
Vue.use(Scrollbar);
Vue.use(Table);
Vue.use(TableColumn);

Vue.use(infiniteScroll);

Vue.use(Loading.directive);
Vue.use(VueClipboard)
// Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.$navBlank = navAndOpenTagPage;
if (!!process.env.IS_ELECTRON) {
  Vue.prototype.$VERSION = VERSION; //用于electron 设备唯一id
}

const i18n = new VueI18n({
  locale: "zh",
  messages: {
    zh: require("@/libs/lang/zh.js"),
    en: require("@/libs/lang/en.js"),
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
