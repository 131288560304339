import { getContentObjectList, createContentObject, getContentObjectOne, delContentObjectOne, 
  getContentObjectSonList, sortContent,
  copyContentObjectOne  } from '@/libs/api/content'
import { moveNode } from '@/libs/api/course'
import {translateDataToTree } from '@/libs/utils/tools'
  
const contentObject = {
  state: {
    schemeaJson: {},
    list: [],
    defaultVal: {},
    courseDefault:{
      "contentobject-title":"未命名",
      "contentobject-subtitle":"",
      "contentobject-body": "",
      "contentobject-instruction":"",
    },
    contentObjectList: [],
    currentContentList:[],
    catalogTrees:[], // contentObjectList
    contentDetail: {}, //内容页详情
    contentForm:{}, //content
  },
  
  mutations: {
    SET_DEFAULT(state, data) {
      state.defaultVal = data;
    },
    SET_CONTENT_DETAIL(state, detail) { //内容页详情
      state.contentDetail = detail;
    },
    SET_CONTENT_FORM(state, data) { //内容页表单
      state.contentForm = data;
    },
    SET_CONTENT_OBJECT_LIST(state, data) {
      state.contentObjectList = data;
    },
    SET_CATALOG_TREES(state, data) {
      state.catalogTrees = data;
    },
    SET_CURRENT_CONTENT(state, data) {
      // 设置当前主题页的,contentObjectList
      state.currentContentList = JSON.parse( JSON.stringify( data ) );
    },
    CLEAR_CONTENT(state) {
      state.contentObjectList = [];
      state.currentContentList = JSON.parse( JSON.stringify( [] ) );
      state.contentDetail = {};
      state.contentForm = {};
    },
    CLEAR_TREE(state) {
      state.catalogTrees = [];
    }
  },
  
  actions: {
    // 获取所有contentList
    GetContentList({commit, state}, course_id) {
      getContentObjectList(course_id).then((res)=>{
        if(res.errorcode == 0) {
          // console.log('trees',res)
          res.data.course.isTop = 1; //course为顶层
          res.data.course.isFolder = "folder";
          res.data.course._type = "menu";
          res.data.course._courseId = res.data.course_id;
          let contentList = JSON.parse(JSON.stringify(res.data.contentobject_list));
          contentList.unshift(res.data.course);
          let treesList = translateDataToTree(contentList,'_parentId','id');
  
          commit('SET_CATALOG_TREES',treesList);
        }
      }).catch(()=>{
  
      });
    },
  
    // 获取当前page的第一级contentList
    GetContentObjectSonList({commit, state}, {course_id, contentObjectId}) {
      return new Promise((resolve,reject)=>{
        getContentObjectSonList(course_id,contentObjectId).then((res)=>{
          resolve(res);
          if(res.errorcode == 0) { 
            let temp = ""
            for(let i in res.data.contentobject_list) {
              temp = res.data.contentobject_list[i].properties_list;
              delete res.data.contentobject_list[i].properties_list;
              res.data.contentobject_list[i] = Object.assign(res.data.contentobject_list[i], temp);
            }
            commit('SET_CURRENT_CONTENT',res.data.contentobject_list);
          }
        }).catch((err)=>{
          reject(err);
        });
      });
    },
    // 获取单个contentObject信息
    GetContentObjectOne({commit, state, dispatch}, {course_id,contentobject_id,isMenu, isCurrent = true}) { //isCurrent:是否刷新当前页面内容
      return new Promise((resolve, reject) => {
        getContentObjectOne(course_id, contentobject_id).then((res)=>{
          resolve(res);
          if(res.errorcode == 0) {
            if(isCurrent) {
              commit('SET_CONTENT_DETAIL', res.data)
              if(res.data.contentobject && res.data.contentobject.properties_list) {
                let contentForm = {};
                for(let key in res.data.contentobject.properties_list) {
                  contentForm[key] = res.data.contentobject.properties_list[key]
                }
                contentForm['contentobject_id'] = contentobject_id;
                commit('SET_CONTENT_FORM',contentForm)
              }
              dispatch('GetArticleList', {course_id, contentobject_id});
            }
          }
        }).catch((err)=>{
          reject(err);
        });
        if(!!isMenu) { //只有主题页才需要获取
          dispatch('GetContentObjectSonList',{course_id, contentObjectId: contentobject_id});
        }
      });
    },
    // 创建contentObject
    CreateContentObject({commit, state, dispatch}, {course_id,_parentId,_type}) { //_type : page为内容页，menu为主题页  ; _parentId: 父节点ID,第一个节点为course_id,其他的为contetnobject-_id
      return new Promise((resolve,reject)=>{
        createContentObject({
          course_id: course_id,
          _parentId:_parentId,
          _type:_type
        }).then((res)=>{
          if(res.errorcode == 0) {
            //重新刷新 contentList
            dispatch('GetTree',course_id);
            if(_type === 'page') {
              dispatch('CreateArticle',{ course_id: course_id, _parentId: res.data.contentobject_id, isUpdateList: false, addBlock: true })
            }
            // 添加主题页时默认有一个内容页
            if(_type === 'menu') {
              dispatch('CreateContentObject',{course_id,_parentId:res.data.contentobject_id,_type:'page'})
            }
            resolve(res);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
    // 复制contentObject
    // 获取单个contentObject信息
    CopyContentObjectOne({commit, state, dispatch}, {course_id,contentobject_id}) {
      return new Promise((resolve, reject) => {
        copyContentObjectOne(course_id, contentobject_id).then((res)=>{
          resolve(res);
          if(res.errorcode == 0) {
            
          }
        }).catch((err)=>{
          reject(err);
        });
      });
    },

    // content 上下排序
    SortContent({ commit, dispatch },{course_id,_parentId,contentobject_id,sort_type}) {
      return new Promise((resolve,reject)=>{
        sortContent({course_id,_parentId,contentobject_id,sort_type}).then(res => {
          resolve(res);
          if(res.errorcode == 0) {
            // 刷新当前 contentList 
            let nextParams = {
              course_id
            };
            if(_parentId != course_id) {
              nextParams.contentObjectId = _parentId;
            }
            dispatch("GetContentObjectSonList", nextParams);
            dispatch("GetTree",course_id);
          }
        }).catch(err => {
          reject(err);
        });
      });
    },

    // content 拖动排序
     MoveSortContent({ commit, dispatch },{course_id, _parentId, contentobject_id, below_contentobject_id, move_type}) {
      return new Promise((resolve,reject)=>{
        moveNode({course_id, _parentId, contentobject_id, below_contentobject_id, move_type}).then(res => {
          resolve(res);
          if(res.errorcode == 0) {
            // 刷新当前 contentList 
            let nextParams = {
              course_id
            };
            if(_parentId != course_id) {
              nextParams.contentObjectId = _parentId;
            }
            dispatch("GetContentObjectSonList", nextParams);
            dispatch("GetTree",course_id);
          }
        }).catch(err => {
          reject(err);
        });
      });
    },

    // 删除单个contentobject信息
    DelContentObjectOne({commit, state, dispatch}, {course_id,contentobject_id, updateDetail:{tree = true} = {}}) { //tree: 刷新树结构
      return new Promise((resolve,reject)=>{
        delContentObjectOne({course_id,contentobject_id}).then((res)=>{
          if(res.errorcode == 0) {
            resolve(res);
            //刷新树结构
            tree && dispatch("GetTree",course_id);
          }
          reject(res);
        }).catch((err)=>{
          reject(err);
        });
      })
    },
  }
}
  
export default contentObject
  