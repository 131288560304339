
import Login from "../views/account/Login.vue";
import Layout from '../views/layout/Layout';
// RouterTab 内置路由
import { RouterTabRoutes } from 'vue-router-tab'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/


export default [
  { path: '/404', component: () => import('../views/404.vue'), hidden: true },
  // {
  //     path: "/",
  //     name: "Home",
  //     component: Layout,
  //     redirect: '/home',
  //     meta: { title: 'Home', icon: 'example' },
  //     hidden: false,
  //     children: [
  //          // 引入 RouterTab 内置路由以支持 Iframe 页签
  //          ...RouterTabRoutes,
  //          {
  //             path: 'home', // 默认页和父级路由一致
  //             name: 'Home',
  //             component: () => import('../views/Home.vue'),
  //             meta: {
  //               title: 'Home' // 页签标题
  //             }
  //         },{
  //         path: '/others',
  //         name: "others",
  //         meta: { title: 'others', icon: 'example' },
  //         component: () => import('../views/Home.vue')
  //     }]
  // },
  {
    path: "/",
    name: "Home",
    component: () => import('../components/Dashboard/Layout.vue'),
    redirect: '/home',
    meta: { title: 'Home', icon: 'example' },
    hidden: false,
    children: [
      // 引入 RouterTab 内置路由以支持 Iframe 页签
      // ...RouterTabRoutes,
      {
        path: 'home',
        name: 'CourseList',
        component: () => import('../views/course/index.vue'),
        meta: {
          title: 'Home' // 页签标题
        }
      },{
        path: 'media',
        name: 'MediaList',
        component: () => import('../views/media/index.vue'),
        meta: {
          title: 'Home' // 页签标题
        }
      },{
        path: 'share',
        name: 'Share',
        component: () => import('../views/share/index.vue'),
        meta: {
          title: 'Home' // 页签标题
        }
      },{
        path: 'templet',
        name: 'Templet',
        component: () => import('../views/templet/index.vue'),
        meta: {
          title: 'Home' // 页签标题
        }
      }]
  },
  {
    path: "/login",
    name: "Loginc",
    redirect: '/account/login',
    hidden: false,
    meta: { title: 'Login', icon: 'example' },
  },
  {
    path: "/thirdlogin",
    name: "ThirdLogin",
    hidden: false,
    component: () => import('../views/account/thirdparty.vue'),
    meta: { title: 'Login', icon: 'example' },
  },
  {
    path: "/register",
    name: "Registerc",
    redirect: '/account/register',
    hidden: false,
    meta: { title: 'Register', icon: 'example' },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import('../components/Account/Layout.vue'),
    meta: { title: 'Account', icon: 'example' },
    hidden: false,
    children: [{
      path: "login",
      name: "Login",
      component: Login,
      hidden: false,
      meta: { title: 'Login', icon: 'example' },
      // beforeEnter: (to, from, next) => { //路由独享的守卫
      //   next();
      // }
    },{
      path: "register",
      name: "Register",
      component: () => import('../views/account/Register.vue'),
      hidden: false,
      meta: { title: 'Register', icon: 'example' },
    },{
      path: "forget",
      name: "Forget",
      component: () => import('../views/account/Forget.vue'),
      hidden: false,
      meta: { title: 'Forget', icon: 'example' },
    }]
  },
  {
    path: "/course",
    name: "Course",
    // component: Layout, //标签页
    component: () => import('../components/Course/Layout.vue'),
    redirect: '/course/create',
    meta: { title: 'Course', icon: 'example' },
    hidden: false,
    children: [{
      path: ':type/:id/:contentId',
      name: "EditContent",
      meta: { title: '编辑', icon: 'example' },
      component: () => import('../views/course/create.vue')
    },{
      path: ':type/:id',
      name: "EditMenu",
      meta: { title: '编辑', icon: 'example' },
      component: () => import('../views/course/create.vue')
    },{
      path: ':type',
      name: "Create",
      meta: { title: '创建', icon: 'example' },
      component: () => import('../views/course/create.vue')
    }]
  },
  {
    path: "/media",
    name: "Media",
    component: () => import('../components/Dashboard/Layout.vue'),
    meta: { title: 'Media', icon: 'example' },
    hidden: false,
    children: [{
      path: 'photoGallery',
      name: "photoGallery",
      meta: { title: '线上图库', icon: 'example' },
      component: () => import('../views/media/photoGallery.vue')
    }]
  },
  {
    path: "/news",
    name: "News",
    component: () => import('../components/News/Layout.vue'),
    redirect: '/news',
    meta: { title: 'News', icon: 'example' },
    hidden: false,
    children: [
      {
        path: '/',
        name: 'NewsList',
        component: () => import('../views/news/index.vue'),
        meta: {
          title: 'NewsList' // 页签标题
        }
      },{
        path: 'remind',
        name: 'RemindList',
        component: () => import('../views/news/remind.vue'),
        meta: {
          title: 'RemindList' // 页签标题
        }
      },{
        path: 'notice',
        name: 'NoticeList',
        component: () => import('../views/news/notice.vue'),
        meta: {
          title: 'NoticeList' // 页签标题
        }
      }]
  },
  {
    path: "/personal",
    name: "Personal",
    component: () => import('../components/Personal/Layout.vue'),
    redirect: '/personal',
    meta: { title: 'Personal', icon: 'example' },
    hidden: false,
    children: [
      {
        path: '/',
        name: 'PersonalProfile',
        component: () => import('../views/personal/index.vue'),
        meta: {
          title: '个人资料' // 页签标题
        }
      },{
        path: 'order',
        name: 'PaymentOrder',
        component: () => import('../views/personal/paymentorder.vue'),
        meta: {
          title: '支付与订单' // 页签标题
        }
      },{
        path: 'account',
        name: 'AccountSecurity',
        component: () => import('../views/personal/accountsecurity.vue'),
        meta: {
          title: '账户安全' // 页签标题
        }
      }
    ]
  },
  {
    path: "/history",
    name: "History",
    component: () => import('../components/History/Layout.vue'),
    redirect: '/history',
    meta: { title: 'History', icon: 'example' },
    hidden: false,
    children: [
      {
        path: '/',
        name: 'InvoiceHistory',
        component: () => import('../views/history/index.vue'),
        meta: {
          title: '发票历史' // 页签标题
        }
      }
    ]
  },
  {
    path: "/preview/:id",
    name: "preview",
    hidden: false,
    component: () => import('../views/preview/index.vue'),
    meta: { title: 'preview', icon: 'example' },
  },
  {
    path: "/sharecourseofpreview",
    name:'ShareCourseOfPreview',
    component: () => import('../views/share/course_preview.vue'),
    hidden: false,
    meta: { title: 'ShareCourseOfPreview', icon: 'example' },
  },
  {
    path: "/webview",
    name: "Webview",
    hidden: false,
    component: () => import('../views/webview/index.vue'),
    meta: { title: 'webview', icon: 'example' },
  },
  {
    path: "/enview",
    name: "EnView",
    hidden: false,
    component: () => import('../views/webview/encode.vue'),
    meta: { title: 'webview', icon: 'example' },
  },
  {
    path: "/invalid/:type",
    name: "Invalid",
    hidden: false,
    component: () => import('../views/share/invalid.vue'),
    meta: { title: 'Invalid', icon: 'example' },
  },
  { path: '*', redirect: '/404', hidden: true }
]