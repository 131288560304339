<template>
  <transition name="el-fade-in-linear">
    <div
      class="fd-loading"
      :style="`background-color: ${bgColor};`"
      v-show="loading"
    >
      <div class="fd-loading-content">
        <img src="@/assets/fd-loading.png" :style="`width:${width}px`"/>
        <span>{{ text }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    // 是否朴素按钮 -朴素按钮背景色为白色
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "创建中...",
    },
    bgColor: {
      type: String,
      default: "#fff",
      // default: "rgba(0,0,0,0.5)",
    },
    width: {
      type: [Number, String],
      default: "60",
    },
    height: [Number, String],
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.fd-loading {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.fd-loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  img {
    width: 60px;
  }
  span {
    color: #262626;
    margin-top: 6px;
    font-size: 14px;
    margin-left: 8px;
  }
}
</style>