import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showPop:false, //是否显示编辑弹窗
    popDetail:{}, //编辑需要显示的tabList内容
    popForm:{}, //编辑内容 详情
    popShowId: null,
    popInputId: null, //弹出窗口输入id
    miniHeightId: null, //最小高度输入id
    miniHeightValue: null,  //最小高度值
  },
  mutations: {
    openPop(state, {type, ids, form, id}) { //type: schemeaJson下的属性
      // console.log('openPop',ids,type);
      let data = JSON.parse(JSON.stringify(state.course.schemaJson[type]));
      // state.popForm = form;
      state.showPop = true;
      state.popDetail = data;
      
      state.popShowId = ids; //弹窗唯一id,数组，区分内容是否高亮
      // console.log(state.popShowId)
    },
    closePop(state) {
      state.showPop = false;
      state.popShowId = null;
      state.popDetail = {};
    },
    inputHighLight(state, { id, isShow }) { //输入框高亮
      state.popInputId = isShow ? id : null;
    },
    inputMiniHeight(state, { id, isShow, value }) { //最小高度
      state.miniHeightId = isShow ? id : null;
      state.miniHeightValue = isShow ? value : null;
    }
  },
  actions: {},
  modules,
  plugins: [createPersistedState({
    key:"feidao_Creation"
  })]
});
