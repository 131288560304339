import * as request from '@/libs/utils/request';
import Config from '@/libs/config'

export function getTempList({category_id,page,limit,keyword}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/template/list?page="+page+'&limit='+limit+'&keyword='+keyword+'&category_id='+category_id,
    })
}
export function getTempCategory() {
    return request.get({
        url: "/api/" + Config.apiversion + "/template/category",
    })
}
export function useTemplate({id}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/template/use?id="+id,
    })
}
export function previewTemplate({id}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/template/preview?id="+id,
    })
}
export function getThemeList({limit}) {
    return request.get({
        url: "/api/" + Config.apiversion + "/theme/list?limit="+limit,
    })
}