import { login, logout, getInfo, getUserInfo, loginWithPwd, loginWithShortMsg, updateUserInfo, getSecurityInformation } from '@/libs/api/login'
import { setToken, removeToken } from '@/libs/utils/auth'

const user = {
  state: {
    token: null,
    userInfo: {
      id:null,
      email:'',
      username: '',
      mobile:'',
      avatar: '',
      roles: []
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.userInfo = info
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  getters: {
    // 获取
    getVersionName: (state) => () => {
      return state.userInfo;
    },
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password).then(response => {
          if(response.data && response.errorcode == 0) {
            const data = response.data
            try {
              setToken(data.token)
            } catch (error) {
              console.log('setTokenerror',error)
            }
            commit('SET_TOKEN', data.token)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 第三方登录
    LoginWithToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        try {
          setToken(token)
          resolve({errorcode:1});
        } catch (error) {
          console.log('setTokenerror',error)
          reject(error)
        }
        commit('SET_TOKEN', token)
      })
    },
    // 登陆-密码方式
    LoginWithPwd({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        loginWithPwd({account:username, password:userInfo.password}).then(response => {
          if(response.data && response.errorcode == 0) {
            const data = response.data
            try {
              setToken(data.token)
            } catch (error) {
              console.log('setTokenerror',error)
            }
            commit('SET_TOKEN', data.token)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登陆-短信方式
    LoginWithShortMsg({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        loginWithShortMsg({mobile:username, verification_code:userInfo.password}).then(response => {
          if(response.data && response.errorcode == 0) {
            const data = response.data
            try {
              setToken(data.token)
            } catch (error) {
              console.log('setTokenerror',error)
            }
            commit('SET_TOKEN', data.token)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const data = response.data
          if(response.data && response.errorcode == 0) {
            commit('SET_INFO', data)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户详情
    GetUserDetail({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          const data = response.data
          if(response.data && response.errorcode == 0) {
            commit('SET_INFO', data)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户账号安全信息
    GetSecurityInformation({ commit, state }) {
      return new Promise((resolve, reject) => {
        getSecurityInformation().then(response => {
          const data = response.data
          if(response.data && response.errorcode == 0) {
            // commit('SET_INFO', data)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    UpdateUserInfo({ commit, state },data) {
      return new Promise((resolve, reject) => {
        updateUserInfo(data).then(response => {
          const data = response.data
          if(response.data && response.errorcode == 0) {
            commit('SET_INFO', data)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出登录
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        removeToken()
        commit('SET_TOKEN', '')
        resolve()
      })
    },

    // 存储token
    SaveToken({ commit }, token) {
      try {
        setToken(token)
      } catch (error) {
        console.log('setTokenerror',error)
      }
      commit('SET_TOKEN', token)
    },
  }
}

export default user
